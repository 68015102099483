<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">[{{ listPage.getCompanyName() }} {{ listPage.getSugangName() }}]</p>
      <p class="mb-2 text-lg font-semibold">
        {{ listPage.getSubType() }} {{ listPage.getGroupType() }} {{ listPage.getLessonTime() }} {{ listPage.getLanguage() }} {{ listPage.getLevel() }}
      </p>
      <p class="mb-2 text-lg font-semibold">
        {{ listPage.getClassName() }} {{ listPage.getClassTimeInfo() }} {{ listPage.getClassWeekTime() }}
      </p>
    </div>
    <div>
      <BagicTable
        :header="listPage.tableHeader"
        :list="listPage.enrolmentGroupList"
        :pageSize="listPage.searchParams.pageSize"
        :totalRows="listPage.totalCnt"
        :enableCheckBox="false"
        :checkParamName="[]"
        @paging-click="listPage.movePage"
        :detailRowName="listPage.detailRowName"
        :detailParamName="listPage.detailParamName"
        @check-click="listPage.checkBoxClick"
      >
      </BagicTable>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import BagicTable from "@/lifeeduAdmin/components/BagicTable.vue";
export default {
  components: {
    BagicTable,
  },
  setup() {
    const route = useRoute();

    const { idxLeSugang, idxLeSugangGroupClass, idxLeSugangGroupLevel, className, startTime, subType, groupType, lessonTime, language, level, timeInfo, weekTime } = route.query

    const listPage = reactive({
      // 회사 정보
      siteMainInfo: {
        kname: "", // 회사명
      },

      // 수강 정보
      sugangInfo: {
        sugangName: "", // 과정명
      },

      idxLeSugang: idxLeSugang,
      idxLeSugangGroupLevel: idxLeSugangGroupLevel,
      idxLeSugangGroupClass: idxLeSugangGroupClass,

      startTime: startTime,

      subType: subType,
      groupType: groupType,
      lessonTime: lessonTime,
      language: language,
      level: level,

      className: className,
      classTimeInfo: timeInfo,
      classWeekTime: weekTime,
      
      classWeekTimeNum: weekTime ? weekTime.replace(/[^0-9]/g, "") : "",
      
      // 회사명
      getCompanyName () {
        const { kname } = this.siteMainInfo
        return kname
      },
      
      // 과정명
      getSugangName() {
        const { sugangName } = this.sugangInfo
        return sugangName
      },

      // 일반/지원 유형
      getSubType() {
        if (!this.subType) return ""
        return `[${this.subType}]`
      },

      // 그룹 유형
      getGroupType() {
        if (!this.groupType) return ""
        return `[${this.groupType}]`
      },

      // 시간 유형
      getLessonTime() {
        if (!this.lessonTime) return ""
        return `[${this.lessonTime}]`
      },

      // 언어 유형
      getLanguage() {
        if (!this.language) return ""
        return `[${this.language}]`
      },

      // 레벨 유형
      getLevel() {
        if (!this.level) return ""
        return `[${this.level}]`
      },

      // 과정명
      getClassName() {
        if (!this.className) return ""
        return `[${this.className}]`
      },

      // 과정 시간대
      getClassTimeInfo() {
        if (!this.classTimeInfo) return ""
        return `[${this.classTimeInfo}]`
      },

      // 과정 시간대 > 주차 정보
      getClassWeekTime() {
        if (!this.classWeekTime) return ""
        return `[${this.classWeekTime}]`
      },

      searchParams: {
        pageNum: 1,
        pageSize: 1000,
      },
      tableHeader: {
        rowNum: "No",
        groupName: "그룹명",
        courseName: "B2B 과정명",
        tutorName: "Tutor",
        companyName: "Company",
        bookName: "교재명",
        timeInfo: "수업시간",
        lessonTime: "분수",
        lecDay: "요일",
        cntLectureUser: "학습자수",
        sdate: "Start Date",
        edate: "End Date",
        hqName: "HQ매니저",
        btnToggleEnrollmentClosed: "마감유무"
      },

      detailParamName: "idxColumnName",
      totalCnt: 0,
      enrolmentGroupList: [],

      // 수강 메타 정보 조회
      getSugangData() {
        if (idxLeSugang && idxLeSugang !== "0") {
          const params = {
            idxLeSugang: idxLeSugang,
          };
          axios.get("/admin/sugang", { params }).then(function (res) {
            if (res.data.success) {
              listPage.siteMainInfo = res.data.data.siteMainInfo
              listPage.sugangInfo = res.data.data.sugangInfo
            } else {
              // 처리 실패 로직
            }
          });
        }
      },

      // 리스트 조회 및 검색
      getEnrolementGroupList() {
        // 요청 파라미터 없는 경우 잘못된 접근으로 판단
        if (!this.idxLeSugangGroupLevel) return
        if (!this.idxLeSugangGroupClass) return
        if (!this.startTime) return
        if (!this.classWeekTimeNum) return

        axios
          .get("/admin/sugangGroupDashBoard", {
            params: {
              idxLeSugangGroupLevel: this.idxLeSugangGroupLevel,
              idxLeSugangGroupClass: this.idxLeSugangGroupClass,
              startTime: encodeURIComponent(this.startTime),
              weekNum: this.classWeekTimeNum
            }
          })
          .then(function (res) {
            const { success, data } = res.data
            if (!success) {
              // 요청 실패
              return
            }

            // 요청 성공 이 후 목록 화면 구성 진행
            const enrolmentGroupList = listPage.makeEnrolmentGroupList(data)
            
            // 데이터셋 매핑
            listPage.enrolmentGroupList = enrolmentGroupList
          });    
      },

      // 테이블 구성하기 위한 데이터셋 반환
      makeEnrolmentGroupList(list) {
        const enrolmentGroupList = []
        if (!list || !list.length) return []

        list.forEach((data, index) => {
          const { idxLeSugangGroupClassRoom, groupName, courseName, tutorName, companyName, bookName, stime, etime, weekNum, lecMin, lecDay, cntLectureUser, sdate, edate, hqName, isEnrollmentClosedManual, isEnrollmentClosedAuto } = data

          const enrolmentGroupInfo = {
            rowNum: index + 1, // No
            idxColumnName: idxLeSugangGroupClassRoom,
            idxLeSugangGroupClassRoom: idxLeSugangGroupClassRoom,
            groupName: `<span class="text-sky-600">${groupName}</span>`,
            courseName: courseName,
            tutorName: tutorName,
            companyName: companyName,
            bookName: bookName,
            timeInfo: `${stime} ~ ${etime}`,
            lessonTime: `주${weekNum}회/${lecMin}분`,
            lecDay: lecDay,
            cntLectureUser: cntLectureUser,
            sdate: sdate,
            edate: edate,
            hqName: hqName,
            btnToggleEnrollmentClosed: listPage.makeBtnToggleClosed(idxLeSugangGroupClassRoom, isEnrollmentClosedManual, isEnrollmentClosedAuto)
          }

          enrolmentGroupList.push(enrolmentGroupInfo)
        })

        return enrolmentGroupList
      },

      // 수동마감여부, 자동마감여부 값에 따라 마감유무 버튼 구성
      makeBtnToggleClosed(idxLeSugangGroupClassRoom, isEnrollmentClosedManual, isEnrollmentClosedAuto) {
        // 마감유무 토글버튼 설정
        const btnToggleClosed = {
          // 체크 여부는 수동 마감 상태에 따라 설정
          checked: listPage.isChecked(isEnrollmentClosedManual),
          // 비활성화여부는 자동 마감 값을 기준으로 설정
          disabled: listPage.isChecked(isEnrollmentClosedAuto),
          clickEvent: (btnToggle) => {
            // 수강 신청 마감 수동 처리 프로세스
            let confirmMessage = "해당 그룹의 수강신청을 마감하시겠습니까?"
            if (!btnToggle.checked) {
              confirmMessage = "해당 그룹의 수강신청 마감을 해제하시겠습니까?"
            }

            if (!confirm(confirmMessage)) {
              btnToggle.checked = !btnToggle.checked
              return
            }

            // API 상태 변경 API 호출
            listPage.updateEnrolmentGroupStatus(idxLeSugangGroupClassRoom, btnToggle)
          }
        }
        return btnToggleClosed
      },

      // YN 필드 값을 기준으로 Boolean 응답 반환
      isChecked(dataYn) {
        return dataYn === 'Y'
      },

      // 그룹 수강신청 마감 활성화/비활성화 처리
      updateEnrolmentGroupStatus(idxLeSugangGroupClassRoom, btnToggle) {
        const params = {
          idxLeSugangGroupClassRoom: idxLeSugangGroupClassRoom
        }
        axios
          .post("/admin/sugangGroupEnrollmentStatus", params)
          .then(function (res) {
            if (!res) {
              // 서버 요청 실패
              btnToggle.checked = !btnToggle.checked
              return
            }

            const { success, message, data } = res.data
            if (!success) {
              // API 요청 실패 메시지 얼럿
              alert(message);
              // 마감 유무 버튼 이전 상태로 원복
              btnToggle.checked = !btnToggle.checked
              return
            }

            // 마감유무 데이터 갱신
            const { isEnrollmentClosedManual, isEnrollmentClosedAuto } = data
            btnToggle.checked = listPage.isChecked(isEnrollmentClosedManual)
            btnToggle.disabled = listPage.isChecked(isEnrollmentClosedAuto)
          });
      }
    });

    onMounted(() => {
      listPage.getSugangData();
      listPage.getEnrolementGroupList();
    });

    return {
      listPage
    };
  },
};
</script>
<style></style>
