import { createApp } from "vue";
//도메인에 따라 App, store, router 를 나누어서 보여지도록 처리한다.

import defaultWebRouter from "@lifeeduWeb/router";
import defaultWebApp from "@lifeeduWeb/App.vue";
import defaultWebStore from "@lifeeduWeb/store";

import defaultAdminRouter from "@lifeeduAdmin/router";
import defaultAdminApp from "@lifeeduAdmin/App.vue";
import defaultAdminStore from "@lifeeduAdmin/store";

import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import VueTailwindDatepicker from "vue-tailwind-datepicker";

let App, store, router;
if (
  location.origin.includes("local") ||
  location.origin.includes("life-admin.carrotapply.com") ||
  location.origin.includes("stage-lifeedu-admin.carrotenglish.net")
) {
  //평생교육 플랫폼 어드민 서비스
  import("@lifeeduAdmin/assets/css/reset.css");
  import("@lifeeduAdmin/assets/css/tailwind.css");
  router = defaultAdminRouter;
  App = defaultAdminApp;
  store = defaultAdminStore;
  const app = createApp(App).use(router).use(store).use(VueTailwindDatepicker);
  app.mount("#app");
} else {
  //평생교육 플랫폼 웹 서비스
  App = defaultWebApp;
  store = defaultWebStore;
  router = defaultWebRouter;

  const urlParams = new URL(location.href).searchParams;
  const idxLeSiteMain = urlParams.get("idxLeSiteMain");

  console.log("urlParams", urlParams, idxLeSiteMain);
  if (idxLeSiteMain) {
    store.commit("setIdxLeSiteMain", idxLeSiteMain);
  }

  // 진주
  if (location.origin.includes("jinjuenglish.com")) {
    store.commit("setIdxLeSiteMain", 63);
  }

  // 안양
  if (location.origin.includes("anyangenglish.com")) {
    store.commit("setIdxLeSiteMain", 64);
  }

  // 아산
  if (location.origin.includes("asyouthenglish.com")) {
    store.commit("setIdxLeSiteMain", 62);
  }

  // 화성
  if (location.origin.includes("hscarrot.co.kr")) {
    store.commit("setIdxLeSiteMain", 6);
  }

  // 울진
  if (location.origin.includes("uljinenglish.com")) {
    store.commit("setIdxLeSiteMain", 77);
  }

  //부산
  if (location.origin.includes("naldapen.com")) {
    store.commit("setIdxLeSiteMain", 52);
  }

  // 나주
  if (location.origin.includes("najuenglish.com")) {
    store.commit("setIdxLeSiteMain", 66);
  }

  // 오산
  if (location.origin.includes("osanenglish.com")) {
    store.commit("setIdxLeSiteMain", 83);
  }

  const app = createApp(App).use(router).use(store).use(VueTailwindDatepicker);
  app.mount("#app");
}
