<template slot="OneClass">
  <div class="p-4">
    <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">[{{ registSugang.getCompanyKoreanName() }} {{ registSugang.getSugangName() }}]</p>
      <p class="mb-2 text-lg">
        수강신청기간 : {{ registSugang.getDisplayStartDate() }} ~ {{ registSugang.getDisplayEndDate() }} | 진행상태 : {{ registSugang.getStatus() }}
      </p>
    </div>

    <div class="p-2 mb-5">
      <!-- 수정일 땐 1:1 수강신청 수정 으로 변경  -->
      <p class="text-xl font-semibold">1:1 수강신청 등록</p>
    </div>

    <!-- 일반/지원 선택 -->
    <div class="p-2 flex gap-2">
      <div
        :class="{
          'bg-slate-800 text-white': registSugang.registSugangData.sugangSupType == '일반',
          'text-slate-800 ': registSugang.registSugangData.sugangSupType == '지원',
        }"
        class="p-2 rounded border border-solid border-slate-800"
      >
        일반
      </div>
      <div
        v-if="registSugang.registSugangInfo.isOneSup == 'Y'"
        :class="{
          'bg-slate-800 text-white': registSugang.registSugangData.sugangSupType == '지원',
          'text-slate-800 ': registSugang.registSugangData.sugangSupType == '일반',
        }"
        class="p-2 rounded border border-solid border-slate-800"
      >
        지원
      </div>
    </div>

    <!-- 주니어 수강 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <OneClass
        :sugangOneLangList="registSugang.registSugangData.sugangOneLangList"
        :adult="'junior'"
        :adultText="'주니어'"
        @set-regist-sugang-junior-data="registSugang.setRegistSugangJuniorData"
      ></OneClass>
      <div class="flex justify-end">
        <p
          class="p-2 border border-solid rounded cursor-pointer border-slate-800"
          @click="registSugang.registSugangOneClass('junior')"
        >
          주니어 임시저장
        </p>
      </div>
    </div>

    <!-- 성인 수강 -->
    <div v-if="registSugang.registMainSugangInfo.mainContent === 'adult'" class="p-2 mb-5 rounded shadow-md">
      <OneClassAult
        :sugangOneLangList="registSugang.registSugangData.sugangOneLangList"
        :adult="'adult'"
        :adultText="'성인'"
        @set-regist-sugang-adult-data="registSugang.setRegistSugangAdultData"
      ></OneClassAult>
      <div class="flex justify-end">
        <p
          class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
          @click="registSugang.registSugangOneClass('adult')"
        >
          성인 임시저장
        </p>
      </div>
    </div>

    <!-- <div class="p-2 mb-5 rounded shadow-md">
            <AdultClass></AdultClass>
            <div class="flex justify-end">
                <p
                    class="p-2 text-red-500 border border-red-500 border-solid rounded cursor-pointer"
                >
                    저장
                </p>
            </div>
        </div> -->

    <!-- 수업 횟수 / 시간 / 교육비 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <OneClassEduPay
        :sugangOneTimeInfo="registSugang.registSugangData.sugangOneTimeInfoList"
        @set-edupay-data="registSugang.setEduPayData"
      ></OneClassEduPay>
    </div>
    <!-- 수업 유형 / 개월 수 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수업 유형 / 개월 수</p>
      <ul class="flex flex-col gap-4">
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 수업유형</p>
          <ul class="flex items-baseline gap-4">
            <li class="flex items-center gap-2">
              <input
                type="checkbox"
                name="chk5"
                id="chk5_0"
                true-value="Y"
                false-value="N"
                v-model="registSugang.registSugangData.isClassTypeMobile"
              /><label class="cursor-pointer" for="chk5_0">모바일 화상</label>
            </li>
            <li class="flex items-center gap-2">
              <input
                type="checkbox"
                name="chk5"
                id="chk5_1"
                true-value="Y"
                false-value="N"
                v-model="registSugang.registSugangData.isClassTypePc"
              /><label class="cursor-pointer" for="chk5_1">PC 화상</label>
            </li>
          </ul>
        </li>
        <li class="flex items-center gap-2">
          <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 개월 수</p>
          <ul class="flex items-baseline gap-4">
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_0" value="0" v-model="registSugang.registSugangData.classMonth" /><label
                class="cursor-pointer"
                for="rdo1_0"
                >사용 안함</label
              >
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_1" value="1" v-model="registSugang.registSugangData.classMonth" /><label
                class="cursor-pointer"
                for="rdo1_1"
                >1개월</label
              >
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_2" value="2" v-model="registSugang.registSugangData.classMonth" />
              <label class="cursor-pointer" for="rdo1_2">2개월</label>
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_3" value="3" v-model="registSugang.registSugangData.classMonth" />
              <label class="cursor-pointer" for="rdo1_3">3개월</label>
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_4" value="4" v-model="registSugang.registSugangData.classMonth" />
              <label class="cursor-pointer" for="rdo1_4">4개월</label>
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_5" value="5" v-model="registSugang.registSugangData.classMonth" /><label
                class="cursor-pointer"
                for="rdo1_5"
                >5개월</label
              >
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_6" value="6" v-model="registSugang.registSugangData.classMonth" /><label
                class="cursor-pointer"
                for="rdo1_6"
                >6개월</label
              >
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo1" id="rdo1_7" value="12" v-model="registSugang.registSugangData.classMonth" />
              <label class="cursor-pointer" for="rdo1_7">12개월</label>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- 레벨테스트 신청 여부 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">레벨테스트 신청 여부</p>
      <div class="flex items-center gap-2">
        <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 레벨테스트 신청</p>
        <ul class="flex items-baseline gap-4">
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo2" id="rdo2_0" value="Y" v-model="registSugang.registSugangData.isLevelTest" /><label
              class="cursor-pointer"
              for="rdo2_0"
              >사용</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo2" id="rdo2_1" value="N" v-model="registSugang.registSugangData.isLevelTest" />
            <label class="cursor-pointer" for="rdo2_1">사용 안함</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 안내문구 입력 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">안내문구 입력</p>
      <ul class="flex flex-col gap-4">
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <div class="flex items-center flex-none gap-2 font-semibold w-44">
            <input
              type="checkbox"
              id="numberOfClassText"
              :checked="registSugang.registSugangData.isNumberOfClassText"
              v-model="registSugang.registSugangData.isNumberOfClassText"
              true-value="Y"
              false-value="N"
            />
            <label class="cursor-pointer" for="numberOfClassText">
              <p>수업횟수 / 시간 안내</p>
            </label>
          </div>
          <textarea
            class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
            v-model="registSugang.registSugangData.numberOfClassText"
          ></textarea>
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <div class="flex items-center flex-none gap-2 font-semibold w-44">
            <input
              type="checkbox"
              id="hopeTimeText"
              :checked="registSugang.registSugangData.isHopeTimeText"
              v-model="registSugang.registSugangData.isHopeTimeText"
              true-value="Y"
              false-value="N"
            />
            <label class="cursor-pointer" for="hopeTimeText">
              <p>희망 수업시간 안내</p>
            </label>
          </div>
          <textarea
            class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
            v-model="registSugang.registSugangData.hopeTimeText"
          ></textarea>
        </li>
        <li class="flex items-center gap-2 pb-4 border-b border-solid">
          <div class="flex items-center flex-none gap-2 font-semibold w-44">
            <input
              type="checkbox"
              id="levelChoiceText"
              :checked="registSugang.registSugangData.isLevelChoiceText"
              v-model="registSugang.registSugangData.isLevelChoiceText"
              true-value="Y"
              false-value="N"
            />
            <label class="cursor-pointer" for="levelChoiceText">
              <p>레벨 선택 안내</p>
            </label>
          </div>
          <textarea
            name=""
            id=""
            class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
            v-model="registSugang.registSugangData.levelChoiceText"
          ></textarea>
        </li>
        <li class="flex items-center gap-2">
          <div class="flex items-center flex-none gap-2 font-semibold w-44">
            <div class="w-4"></div>
            <p>교재 구입 여부</p>
          </div>
          <ul class="flex items-baseline gap-4">
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo3" id="rdo3_0" value="Y" v-model="registSugang.registSugangData.isBookBuy" /><label
                class="cursor-pointer"
                for="rdo3_0"
                >사용</label
              >
            </li>
            <li class="flex items-center gap-2">
              <input type="radio" name="rdo3" id="rdo3_1" value="N" v-model="registSugang.registSugangData.isBookBuy" /><label
                class="cursor-pointer"
                for="rdo3_1"
                >사용 안함</label
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="flex justify-between">
      <p class="p-2 border border-solid rounded cursor-pointer border-slate-800" @click="registSugang.goBack()">이전 페이지</p>

      <!-- 지원수강이 존재하는 경우 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangOneAll"
        v-if="registSugang.registSugangData.sugangSupType == '일반' && registSugang.registSugangInfo.isOneSup == 'Y'"
      >
        저장하고 1:1지원수강 작성하기
      </p>

      <!-- 지원수강이 없고 그룹수강이 존재하는 경우 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangOneAll"
        v-else-if="
          (registSugang.registSugangData.sugangSupType == `일반` && registSugang.registSugangInfo.isOneSup == `N` && registSugang.registSugangInfo.isGroup == `Y`) ||
          (registSugang.registSugangData.sugangSupType == `지원` && registSugang.registSugangInfo.isGroup == `Y`)
        "
      >
        저장하고 그룹수강 작성하기
      </p>

      <!-- 그 외 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangOneAll"
        v-else
      >
        완료하기
      </p>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import OneClass from "@/lifeeduAdmin/components/RegistOneClass.vue";
import OneClassAult from "@/lifeeduAdmin/components/RegistOneClassAdult.vue";
import OneClassEduPay from "@/lifeeduAdmin/components/RegistOneClassEduPay.vue";
import axios from "@lifeeduAdmin/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {
    OneClass,
    OneClassAult,
    OneClassEduPay,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    // const isGroupValue = ref(false);
    // const isOneValue = ref(false);

    const registSugang = reactive({
      // 홈페이지 정보
      registMainSugangInfo: {
        kname: "", // 한글이름
        mainContent: "" // 메인컨텐츠 유형 - junior: 주니어, adult: 주니어 + 성인 (20세 이상)
      },

      // 수강신청 메타 정보
      registSugangInfo: {
        isGroup: "N", //그룹 일반수강 사용여부값
        isGroupSup: "N", //그룹 지원수강 사용여부값
        isOne: "N", //1:1 일반수강 사용여부 값
        isOneSup: "N", //1:1 지원수강 사용여부 값

        startDate: "", // 시작일시
        endDate: "", // 종료일시
        status: "" // 진행 상태 - 예정, 진행중, 종료
      },
      
      registSugangData: {
        idxLeSugangOne: 0,
        idxLeSugang: 0,
        isClassTypeMobile: "N",
        isClassTypePc: "N",
        classMonth: 0,
        isLevelTest: "",
        isNumberOfClassText: "N",
        isLevelChoiceText: "N",
        isHopeTimeText: "N",
        numberOfClassText: "",
        hopeTimeText: "",
        levelChoiceText: "",
        isBookBuy: "",
        sugangOneTimeInfo: [],
        sugangOneTimeInfoList: [],
        staffIdx: null,
        sugangOneLangList: [],
        sugangSupType: "일반", //일반, 지원 구분하는 값. 기본은 일반
        buttonName: "",
      },
    
      // registParams: {
      //     isOne: computed(() => (isOneValue.value ? "Y" : "N")),
      //     isGroup: computed(() => (isGroupValue.value ? "Y" : "N")),
      // },

      clearRegistSugangData() {
        registSugang.registSugangData = {
          ...registSugang.registSugangData,
          ...{
            isClassTypeMobile: "N",
            isClassTypePc: "N",
            classMonth: 0,
            isLevelTest: "",
            isNumberOfClassText: "N",
            isLevelChoiceText: "N",
            isHopeTimeText: "N",
            numberOfClassText: "",
            hopeTimeText: "",
            levelChoiceText: "",
            isBookBuy: "",
            sugangOneTimeInfo: [],
            sugangOneTimeInfoList: [],
            staffIdx: null,
            sugangOneLangList: [],
            buttonName: "",
          },
        };
      },
      setSugangSupType(val) {
        console.log("setSugangSupType :", val);
        router.push({
          name: "enrolmentStep3_1",
          query: {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
            sugangSupType: val,
          },
        });
        // registSugang.registSugangData.sugangSupType = val;
        // registSugang.clearRegistSugangData();
        // registSugang.getSugang();
        // registSugang.getOneClass();
      },

      setEduPayData(val) {
        registSugang.registSugangData.sugangOneTimeInfoList = val;
        // registSugang.sugangOneTimeInfo = val;
      },

      setRegistSugangJuniorData: (val) => {
        registSugang.registSugangData.sugangOneLangList = val;
        console.log("Updated sugangOneLangList:", val);
      },

      setRegistSugangAdultData: (val) => {
        registSugang.registSugangData.sugangOneLangList = val;
        console.log("remove sugangOneLangList:", val);
      },

      //   setRegistSugangData(val) {
      //     registSugang.registSugangData.sugangOneLangList = val;
      //   },

      getSugang() {
        //route.query.idxSugang 값이 존재하면 값을 가져오고, 아니라면 값을 비운다.
        console.log("getSugang");
        if (registSugang.registSugangData.idxLeSugang > 0) {
          const params = {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
          };

          console.log(params);
          axios
            .get("/admin/sugang", {
              params: params,
            })
            .then(function (res) {
              if (res.data.success) {
                registSugang.registSugangInfo = { ...registSugang.registSugangInfo, ...res.data.data.sugangInfo };
                registSugang.registMainSugangInfo = res.data.data.siteMainInfo;
                console.log("sugang", registSugang.registSugangInfo);
              } else {
                // alert(res.data.message);
                // router.push({ path: "/home" });
              }
            });
        }
      },

      // 1:1 수강 언어 선택 및 저장
      getOneClass() {
        //route.query.idxSugang 값이 존재하면 값을 가져오고, 아니라면 값을 비운다.
        console.log("getOneClass");
        if (registSugang.registSugangData.idxLeSugang > 0) {
          const params = {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
            sugangSupType: registSugang.registSugangData.sugangSupType,
          };

          console.log(params);
          axios.get("/admin/sugangOne", { params: params }).then(function (res) {
            if (res.data.success) {
              registSugang.registSugangData = { ...registSugang.registSugangData, ...res.data.data };
              console.log("sugangOne2", registSugang.registSugangData);
            } else {
              // alert(res.data.message);
              // router.push({ path: "/home" });
            }
          });
        }
      },

      // 수강신청
      registSugangOneAll() {
        const params = registSugang.registSugangData;
        axios.post("/admin/sugangOneAll", params, {}).then(function (res) {
          if (res.data.success) {
            //일반과 그룹이 함께 존재하는 경우, 일반을 우선 저장하도록 유도해야 오류가 적을 수 있다.
            //일반을 저장하는 경우, 지원이 존재하면 지원으로 이동된다.
            if (registSugang.registSugangData.sugangSupType == "일반" && registSugang.registSugangInfo.isOneSup == "Y") {
              registSugang.setSugangSupType("지원");
            } else if (
              (registSugang.registSugangData.sugangSupType == "일반" && registSugang.registSugangInfo.isOneSup == "N" && registSugang.registSugangInfo.isGroup == "Y") ||
              (registSugang.registSugangData.sugangSupType == "지원" && registSugang.registSugangInfo.isGroup == "Y")
            ) {
              //지원을 저장하는 경우, 그룹이 존재하면 그룹으로 이동한다.
              alert("1:1 수강신청을 등록 완료하였습니다. 그룹 수강신청으로 이동합니다.");
              router.push({
                name: "enrolmentStep3_2",
                query: {
                  idxLeSugang: registSugang.registSugangData.idxLeSugang,
                },
              });
            } else {
              alert("1:1 수강신청을 등록 완료하였습니다.");
              router.push({ path: "/home" });
            }
          } else {
            alert(res.data.message);
          }
          // listPage.is_loader = false;
        });
      },

      // 수강신청
      registSugangOneClass(adultType) {
        let sugangOneLangListParam = registSugang.registSugangData.sugangOneLangList.filter((item) => item.adult == adultType);

        console.log("registSugangOneClass", registSugang.registSugangData.sugangOneLangList);
        console.log("registSugangOneClass", sugangOneLangListParam);
        const params = {
          adult: adultType,
          idxLeSugang: registSugang.registSugangData.idxLeSugang,
          idxLeSugangOne: registSugang.registSugangData.idxLeSugangOne,
          sugangSupType: registSugang.registSugangData.sugangSupType,
          buttonName: "",
          staffIdx: store.state.staffInfo.idx,
          sugangOneLangList: sugangOneLangListParam,
        };
        axios.post("/admin/sugangOne", params, {}).then(function (res) {
          if (res.data.success) {
            alert("수강신청 과정 저장 완료");
            //저장하게되면 되돌려받는 le_sugang_one 값을 갱신해주어야 한다.
            // registSugang.getOneClass();
            registSugang.registSugangData.idxLeSugangOne = res.data.data.idxLeSugangOne;
          } else {
            alert(res.data.message);
          }
          // listPage.is_loader = false;
        });
      },

      goBack() {
        //이전페이지의 동작은 무조건 histroy.back 로 한다.
        router.go(-1);
      },
 
      // 수강신청 홈페이지 정보
      getSugangHomepageInfo() {
        return this.registMainSugangInfo
      },

      // 회사명
      getCompanyKoreanName() {
        const { kname } = this.getSugangHomepageInfo()
        return kname
      },
      
      // 수강신청 정보
      getSugangInfo() {
        return this.registSugangInfo
      },

      // 과정명
      getSugangName() {
        const { sugangName } = this.getSugangInfo()
        return sugangName
      },

      // 수강신청기간 시작일시 표시 메서드
      getDisplayStartDate() {
        const { startDate } = this.getSugangInfo()
        
        if (!startDate) return

        const [date, time] = startDate.split(" ")
        const [hour, minute] = time.split(":")
        return this.formatDate(date, hour, minute)
      },

      // 수강신청기간 종료일시 표시 메서드
      getDisplayEndDate() {
        const { endDate } = this.getSugangInfo()

        if (!endDate) return 

        const [date, time] = endDate.split(" ")
        const [hour, minute] = time.split(":")
        return this.formatDate(date, hour, minute)
      },

      formatDate(date, hour, minute) {
        return `${date} ${hour}:${minute}`
      },

      // 수강신청 진행상태
      getStatus() {
        const { status } = this.getSugangInfo()

        if (!status) return ""
        return status
      }

    });

    onMounted(() => {
      if (route.query.idxLeSugang) {
        registSugang.registSugangData.idxLeSugang = Number(route.query.idxLeSugang);
        registSugang.registSugangData.sugangSupType = "일반";
        if (route.query.sugangSupType && route.query.sugangSupType == "지원") {
          registSugang.registSugangData.sugangSupType = "지원";
        }

        registSugang.getSugang();
        registSugang.getOneClass();
      } else {
        alert("잘못된 접근입니다.");
        router.push({ path: "/home" });
      }
    });

    return {
      registSugang,
    };
  },
};
</script>
<style></style>
