<script>
import { onMounted, reactive, watch } from "vue";
import axios from "@lifeeduAdmin/plugins/axios.js";
import { groupTypeList, languageList, levelList, lecDayList } from "@lifeeduAdmin/components/js/groupSugangMeta.js";
import BaseClassRoomListObject from "@lifeeduAdmin/components/js/BaseClassRoomListObject.js";
import BaseClassListObject from "@lifeeduAdmin/components/js/BaseClassListObject.js";
import BaseLevelListObject from "@lifeeduAdmin/components/js/BaseLevelListObject.js";
import BaseLangListObject from "@lifeeduAdmin/components/js/BaseLangListObject.js";
import BaseTimeInfoListObject from "@lifeeduAdmin/components/js/BaseTimeInfoListObject.js";
import BaseTypeListObject from "@lifeeduAdmin/components/js/BaseTypeListObject.js";
import _ from "lodash";

export default {
  name: "GroupClass",
  components: {},
  props: {
    sugangGroupTypeList: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const registSugang = reactive({
      groupTypeList: structuredClone(groupTypeList),
      groupTimeInfoList: [],
      languageList: structuredClone(languageList),
      levelList: structuredClone(levelList),
      BaseClassRoomTimeList: [],
      checkedLanguageList: [],

      currentGroupType: -1,
      currentGroupTimeInfo: -1,
      currentLanguage: -1,
      currentLevel: -1,
      currentClassListIdx: -1,

      sugangGroupTypeList: props.sugangGroupTypeList,

      checkDuplicateSelection: (selectedOption, classIndex) => {
        // Get the array of selected options from your data
        const selectedOptions = registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
          registSugang.currentGroupTimeInfo
        ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
          registSugang.currentLevel
        ].sugangGroupClassList.map((item) => item.className);

        // Count the occurrences of the selected option
        const selectedCount = selectedOptions.filter((option) => option === selectedOption).length;

        // Check if the option is selected more than once
        if (selectedCount > 1) {
          alert("해당 옵션은 이미 선택되었습니다.");
          // Reset the selected option to an empty string
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
            registSugang.currentLevel
          ].sugangGroupClassList[classIndex].className = "";
        }
      },

      setTimeInterval: () => {
        let timeList = Array();
        let date1 = new Date(2023, 1, 1, 5, 30, 0); //기준 날짜를 지정.
        let date2 = new Date(2023, 1, 1, 6, 0, 0); //기준 날짜를 지정.
        const interval = 30;

        //6시부터 23시까지 반복한다. 36회
        for (let i = 0; i < 36; i++) {
          date1.setMinutes(date1.getMinutes() + interval); //현재
          let sTime = ("0" + date1.getHours()).slice(-2) + ":" + ("0" + date1.getMinutes()).slice(-2);
          date2.setMinutes(date2.getMinutes() + interval); //30분 뒤
          let eTime = ("0" + date2.getHours()).slice(-2) + ":" + ("0" + date2.getMinutes()).slice(-2);
          let TimeObj = new BaseClassRoomListObject();
          TimeObj.time = sTime;
          TimeObj.startTime = sTime;
          TimeObj.endTime = eTime;
          timeList.push(TimeObj);
        }
        return timeList;
      },

      //선택된 그룹(타입), 언어, 레벨 값을 가져오고
      //이를 토대로 리스트를 재구성한다.

      groupTypeChecked: (checkedTF, groupType) => {
        console.log("groupTypeChecked", checkedTF, groupType);
        if (checkedTF) {
          //그룹유형이 추가된 경우에는 sugangGroupTypeList 에 오브젝트를 추가해주고,
          registSugang.addBaseGroupTypeList(groupType);
        } else {
          //그룹유형이 제거된 경우에는 sugangGroupTypeList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 그룹유형의 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeGroupTypeList(groupType);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.groupTypeList.forEach((item) => {
              if (item.groupType == groupType) item.checked = true;
            });
            // console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
          }
        }
        console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      studentLimitTypeChecked: (groupTypeItem) => {
        // console.log('groupTypeItem',groupTypeItem)
        //입력한 값을 sugangGroupTypeList 와 맞춰준다.
        // console.log("studentLimitTypeChecked", groupTypeItem.studentLimitType, groupTypeItem.groupType);
        if (groupTypeItem.studentLimitType == "Not Limited") {
          //제한없음인 경우, 선착순 대기자 수를 0명으로 변경한다.
          groupTypeItem.studentLimit = 0;
          groupTypeItem.studentLimitWait = 0;
        }
        registSugang.sugangGroupTypeList.forEach((item) => {
          if (item.groupType == groupTypeItem.groupType) {
            item.studentLimitType = groupTypeItem.studentLimitType;
            item.studentLimit = groupTypeItem.studentLimit;
            item.studentLimitWait = groupTypeItem.studentLimitWait;
          }
        });
        // console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      studentLimitInput: (studentLimit, groupType) => {
        //입력한 값을 sugangGroupTypeList 와 맞춰준다.
        // console.log("studentLimitInput", studentLimit, groupType);
        registSugang.sugangGroupTypeList.forEach((item) => {
          if (item.groupType == groupType) item.studentLimit = studentLimit;
        });
        // console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      studentLimitWaitInput: (studentLimitWait, groupType) => {
        //입력한 값을 sugangGroupTypeList 와 맞춰준다.
        // console.log("studentLimitWaitInput", studentLimitWait, groupType);
        registSugang.sugangGroupTypeList.forEach((item) => {
          if (item.groupType == groupType) item.studentLimitWait = studentLimitWait;
        });
        // console.log("sugangGroupTypeList", registSugang.sugangGroupTypeList);
      },

      checkCtmGroupCode(isGroupCode, groupType) {
        if (!isGroupCode) {
          alert("그룹 과정코드를 입력하세요.");
          return;
        }
        axios.get(`/admin/ctmCode?idxCtmB2b=${isGroupCode}`).then((res) => {
          if (res.data.success) {
            alert("코드가 존재합니다.");
            registSugang.sugangGroupTypeList.forEach((item) => {
              if (item.groupType == groupType) {
                item.isGroupCode = isGroupCode;
              }
            });
          } else {
            alert("코드가 존재하지 않습니다.");
          }
        });
      },

      addBaseGroupTypeList: (groupType) => {
        let bo = new BaseTypeListObject();
        bo.groupType = groupType;

        // 현재 입력되어있는 수업 횟수를 추가
        registSugang.groupTimeInfoList.forEach((item) => {
          let TimeInfoBo = new BaseTimeInfoListObject();
          TimeInfoBo.idxLeSugangGroupType = bo.idxLeSugangGroupType;
          TimeInfoBo.lessonTime = item.lessonTime; // 기존의 lessonTime 값을 복사
          TimeInfoBo.numberOfClass = item.numberOfClass; // 기존의 numberOfClass 값을 복사
          TimeInfoBo.totalNumberOfClass = item.totalNumberOfClass; // 기존의 totalNumberOfClass 값을 복사
          TimeInfoBo.lecDayList = item.lecDayList; // 기존의 totalNumberOfClass 값을 복사

          registSugang.languageList.forEach((langItem) => {
            if (langItem.checked == true) {
              let langBo = new BaseLangListObject();
              langBo.idxLeSugangGroupType = bo.idxLeSugangGroupType;
              langBo.language = langItem.lang;
              registSugang.levelList.forEach((level) => {
                if (level.checked) {
                  let LevelBo = new BaseLevelListObject();
                  LevelBo.idxLeSugangGroupLang = 0;
                  LevelBo.idxLeSugangGroupLevel = 0;
                  LevelBo.levelName = level.levelName;
                  langBo.sugangGroupLevelList.push(LevelBo);
                }
              });
              TimeInfoBo.sugangGroupLangList.push(langBo);
            }
          });
          bo.sugangGroupTimeInfoList.push(TimeInfoBo);
        });

        registSugang.sugangGroupTypeList.push(bo);
        // console.log("addBaseGroupTypeList", registSugang.sugangGroupTypeList);
      },

      removeGroupTypeList: (groupType) => {
        let filterIdx = -1;
        let list = registSugang.sugangGroupTypeList.filter((o, index) => {
          if (o.groupType !== groupType) {
            return o;
          } else {
            filterIdx = index;
          }
        });
        registSugang.sugangGroupTypeList = list;
        //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
        if (registSugang.currentGroupType >= filterIdx) registSugang.currentGroupType = registSugang.currentGroupType - 1;
        if (registSugang.sugangGroupTypeList.length == 0) registSugang.currentGroupType = -1;

        // console.log("remove sugangGroupTypeList groupType", groupType);
        // console.log("remove sugangGroupTypeList list", registSugang.sugangGroupTypeList);
      },

      changeLessonTime: (lessonTime, index) => {
        //수업횟수/시간에서 lessonTime 값을 수정하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 값을 수정해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.forEach((item, idx) => {
            if (idx == index) item.lessonTime = lessonTime;
          });
        });
      },
      changeTotalNumberOfClass: (totalNumberOfClass, index) => {
        //수업횟수/시간에서 totalNumberOfClass 값을 수정하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 값을 수정해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.forEach((item, idx) => {
            if (idx == index) item.totalNumberOfClass = totalNumberOfClass;
          });
        });
      },
      changeNumberOfClass: (numberOfClass, index) => {
        console.log("changeNumberOfClass", numberOfClass, index);
        //수업횟수/시간에서 numberOfClass 값을 수정하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 값을 수정해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.forEach((item, idx) => {
            if (idx == index) {
              item.numberOfClass = numberOfClass;
              // 요일 정보 기본값
              registSugang.changeDefaultLecDayList(item);
            }
          });
        });
        console.log("changeNumberOfClass", registSugang.sugangGroupTypeList);
      },

      addGroupTimeInfo: () => {
        //화면에 보여지는 부분을 위해 추가해준다.
        let bo = new BaseTimeInfoListObject();
        registSugang.groupTimeInfoList.push(bo);
        //존재하는 트리구조에 맞추어 추가해준다.
        //registSugang.sugangGroupTypeList
        // console.log("addGroupTimeInfo");
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          // console.log("groupType ", groupType);
          bo.idxLeSugangGroupType = groupType.idxLeSugangGroupType;

          //현재 선택되어있는 언어를 추가한다.
          // console.log("registSugang ", registSugang);
          registSugang.languageList.forEach((item) => {
            // console.log("languageList ", item);
            if (item.checked == true) {
              let langBo = new BaseLangListObject();
              langBo.idxLeSugangGroupType = bo.idxLeSugangGroupType;
              langBo.language = item.lang;
              //현재 선택되어있는 레벨을 추가한다.
              registSugang.levelList.forEach((level) => {
                // console.log("levelList ", level);
                if (level.checked) {
                  let LevelBo = new BaseLevelListObject();
                  LevelBo.idxLeSugangGroupLang = 0;
                  LevelBo.idxLeSugangGroupLevel = 0;
                  LevelBo.levelName = level.levelName;
                  langBo.sugangGroupLevelList.push(LevelBo);
                }
              });
              bo.sugangGroupLangList.push(langBo);
            }
          });
          //클래스는 초기 빈 배열이다.
          groupType.sugangGroupTimeInfoList.push(bo);
        });
        console.log("addGroupTimeInfo", registSugang.sugangGroupTypeList);

        // 수업 횟수 / 시간 생성 데이터 반환
        return bo;
      },
      copyGroupTimeInfo: () => {
        const { selectedGroupTypeIdx, selectedGroupTimeInfoIdx, targetGroupTimeInfo } = registSugang.popupCopyGroupTime;
        console.log("copyGroupTimeInfo Before", selectedGroupTypeIdx, selectedGroupTimeInfoIdx, targetGroupTimeInfo);

        if (selectedGroupTypeIdx === "") {
          alert("그룹 유형을 선택해주세요.");
          return;
        }
        if (selectedGroupTimeInfoIdx === "") {
          alert("수업 횟수 / 시간을 선택해주세요.");
          return;
        }

        const { numberOfClass, lecDayList, totalNumberOfClass, lessonTime } = targetGroupTimeInfo;
        if (numberOfClass === "" || numberOfClass === 0) {
          alert("붙여넣을 수업 횟수를 선택해주세요.");
          return;
        }
        if (lecDayList.filter((lecDay) => lecDay.checked).length !== Number(numberOfClass)) {
          alert("붙여넣을 요일 정보를 선택해주세요.");
          return;
        }
        if (totalNumberOfClass === "" || totalNumberOfClass === 0) {
          alert("붙여넣을 총 수업 횟수를 선택해주세요.");
          return;
        }
        if (lessonTime === "" || lessonTime === 0) {
          alert("붙여넣을 수업 시간(분)을 선택해주세요.");
          return;
        }

        //화면에 보여지는 부분을 위해 추가해준다.
        let bo = new BaseTimeInfoListObject();
        //깊은 복사로 참조되지 않도록 처리한다.
        bo = structuredClone(targetGroupTimeInfo);
        bo.idxLeSugangGroupTimeInfo = 0;
        registSugang.groupTimeInfoList.push(bo);
        //존재하는 트리구조에 맞추어 추가해준다.
        //registSugang.sugangGroupTypeList
        // console.log("addGroupTimeInfo");
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          // console.log("groupType ", groupType);
          bo.idxLeSugangGroupType = groupType.idxLeSugangGroupType;

          //sugangGroupClassList 값이 비어있지만, 선택한 그룹타입 인덱스, 선택한 그룹타임인포 인덱스가 가지고 있는 언어 리스트를 복사해서 설정해준다.
          let langList = new Array();
          langList = structuredClone(
            registSugang.sugangGroupTypeList[selectedGroupTypeIdx].sugangGroupTimeInfoList[selectedGroupTimeInfoIdx]
              .sugangGroupLangList
          );
          //langBo 하위의 키 값들은 모두 0으로 초기화시켜준다.
          langList.forEach((langBo) => {
            langBo.idxLeSugangGroupLang = 0;
            langBo.idxLeSugangGroupTimeInfo = 0;
            langBo.sugangGroupLevelList.forEach((levelBo) => {
              levelBo.idxLeSugangGroupLang = 0;
              levelBo.idxLeSugangGroupLevel = 0;
              levelBo.sugangGroupClassList.forEach((classBo) => {
                classBo.idxLeSugangGroupLevel = 0;
                classBo.idxLeSugangGroupClass = 0;
                classBo.sugangGroupClassRoomList.forEach((roomBo) => {
                  roomBo.idxLeSugangGroupClass = 0;
                  roomBo.idxLeSugangGroupClassRoom = 0;
                });
              });
            });
          });
          bo.sugangGroupLangList = langList;

          groupType.sugangGroupTimeInfoList.push(bo);
        });
        console.log("copyGroupTimeInfo Result ", registSugang.sugangGroupTypeList);

        alert("적용 되었습니다");

        // 팝업 닫기
        registSugang.closePopupForCopyGroupTimeInfo();

        return bo;
      },

      removeGroupTimeInfo: (index, item) => {
        //수업횟수/시간에서 삭제하는 경우, registSugang.sugangGroupTypeList 를 순회하며 해당 인덱스의 오브젝트를 제거해주어야 한다.
        registSugang.sugangGroupTypeList.forEach((groupType) => {
          groupType.sugangGroupTimeInfoList.splice(index, 1);
        });
        registSugang.groupTimeInfoList.splice(index, 1);

        //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
        if (registSugang.currentGroupTimeInfo >= index) registSugang.currentGroupType = registSugang.currentGroupType - 1;
        if (registSugang.groupTimeInfoList.length == 0) registSugang.currentGroupType = -1;
      },

      languageChecked: (checkedTF, lang) => {
        // console.log("languageChecked", checkedTF, lang);

        if (checkedTF) {
          //언어가 추가된 경우에는 sugangOneLangList 에 오브젝트를 추가해주고,
          registSugang.addBaseLangList(lang);
        } else {
          //언어가 제거된 경우에는 sugangOneLangList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeLangList(lang);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.languageList.forEach((item) => {
              if (item.lang == lang) item.checked = true;
            });
          }
        }
      },

      addBaseLangList: (lang) => {
        //모든 선택된 타입마다 언어를 추가해준다.
        // console.log("registSugang.sugangGroupTypeList", registSugang.sugangGroupTypeList);
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            ////////////////
            let bo = new BaseLangListObject();
            bo.idxLeSugangGroupType = timeInfoList.idxLeSugangGroupType;
            bo.language = lang;

            //다른 선택된 레벨이 있으면 언어도 추가해준다.
            registSugang.levelList.forEach((level) => {
              if (level.checked) {
                let LevelBo = new BaseLevelListObject();
                LevelBo.idxLeSugangGroupLang = 0;
                LevelBo.idxLeSugangGroupLevel = 0;
                LevelBo.levelName = level.levelName;
                bo.sugangGroupLevelList.push(LevelBo);
              }
            });

            timeInfoList.sugangGroupLangList.push(bo);
            ////////////////
          });
        });
        console.log("addBaseLangList", registSugang.sugangGroupTypeList);
      },

      removeLangList: (lang) => {
        //모든 선택된 타입마다 언어를 제거해준다.
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            let filterIdx = -1;
            let list = timeInfoList.sugangGroupLangList.filter((o, index) => {
              if (o.language !== lang) {
                filterIdx = index;
                return o;
              }
            });
            timeInfoList.sugangGroupLangList = list;
            //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
            if (registSugang.currentLanguage >= filterIdx) registSugang.currentLanguage = registSugang.currentLanguage - 1;
            if (timeInfoList.sugangGroupLangList.length == 0) registSugang.currentLanguage = -1;
          });
        });
        // console.log("removeLangList lang", lang);
        // console.log("removeLangList list", registSugang);
      },

      levelChecked: (checkedTF, levelName) => {
        // console.log("levelChecked", checkedTF, levelName);

        if (checkedTF) {
          //언어가 추가된 경우에는 sugangOneLangList 에 오브젝트를 추가해주고,
          registSugang.addBaselevelList(levelName);
        } else {
          //언어가 제거된 경우에는 sugangOneLangList 에 오브젝트를 제거한다.
          if (confirm("선택을 해제하면 설정된 수업이 제거됩니다. 선택을 해제하시겠습니까?")) {
            //해제한다.
            registSugang.removeLevelList(levelName);
          } else {
            //해제하지 않는다.
            checkedTF = true;
            registSugang.levelList.forEach((item) => {
              if (item.levelName == levelName) item.checked = true;
            });
          }
        }
      },

      addBaselevelList: (levelName) => {
        //모든 선택된 타입의 언어마다 레벨를 추가해준다.
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            timeInfoList.sugangGroupLangList.forEach((langList) => {
              let bo = new BaseLevelListObject();
              bo.idxLeSugangGroupLang = langList.idxLeSugangGroupLang;
              bo.idxLeSugangGroupLevel = 0;
              bo.levelName = levelName;
              langList.sugangGroupLevelList.push(bo);
            });
          });
        });
        // console.log("addBaselevelList", registSugang.sugangGroupTypeList);
      },

      removeLevelList: (levelName) => {
        let filterIdx = -1;
        let list = registSugang.sugangGroupTypeList.filter((o, index) => {
          if (o.levelName !== levelName) {
            return o;
          } else {
            filterIdx = index;
          }
        });
        registSugang.sugangGroupTypeList = list;
        //삭제시에 인덱스를 받고, 현재 인덱스 값이 이보다 같거나 크다면 -1을 해주고, 작으면 그대로 둔다.
        if (registSugang.currentLevel >= filterIdx) registSugang.currentLevel = registSugang.currentLevel - 1;
        if (registSugang.sugangGroupTypeList.length == 0) registSugang.currentLevel = -1;

        //모든 선택된 타입의 언어마다 레벨를 제거해준다.
        registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
          groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
            timeInfoList.sugangGroupLangList.forEach((langList) => {
              let list = langList.sugangGroupLevelList.filter((o) => o.levelName !== levelName);
              langList.sugangGroupLevelList = list;
              if (langList.sugangGroupLevelList.length == 0) registSugang.currentLevel = -1;
            });
          });
        });
        // console.log("removeLangList lang", levelName);
        // console.log("removeLangList list", registSugang.sugangGroupTypeList);
      },

      setCurrentGroupType: (val) => {
        registSugang.currentGroupType = val;
      },
      setCurrentGroupTimeInfo: (val) => {
        registSugang.currentGroupTimeInfo = val;
      },

      setCurrentLanguage: (val) => {
        registSugang.currentLanguage = val;
      },

      setCurrentLevel: (val) => {
        registSugang.currentLevel = val;
      },

      getSugangOneLangList: (lang) => {
        let resultList = registSugang.sugangOneLangList.find(function whatClass(item) {
          if (item.language == lang) return true;
        });
        return resultList.sugangOneClassList;
      },

      addRow: () => {
        let bo = new BaseClassListObject();
        //클래스를 추가할 때에는 수업을 함께 추가해준다.
        let timeList = registSugang.setTimeInterval();
        bo.sugangGroupClassRoomList = timeList;

        bo.idxLeSugangGroupLevel =
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
            registSugang.currentLevel
          ].idxLeSugangGroupLevel;

        registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
          registSugang.currentGroupTimeInfo
        ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
          registSugang.currentLevel
        ].sugangGroupClassList.push(bo);

        console.log(
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList
        );
      },

      deleteRows: () => {
        if (registSugang.currentClassListIdx > -1) {
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[
            registSugang.currentLevel
          ].sugangGroupClassList.splice(registSugang.currentClassListIdx, 1);
        } else {
          alert("삭제할 수업을 선택해주세요");
        }
        console.log(registSugang.currentClassListIdx);
      },

      //요일 설정 정보 기본값 설정 메서드
      changeDefaultLecDayList(oneTimeInfo) {
        const { numberOfClass, lecDayList } = oneTimeInfo;

        // 체크된 요일 정보 초기화
        lecDayList.forEach((lecDay) => (lecDay.checked = false));

        switch (String(numberOfClass)) {
          // 선택하지 않음
          case "0":
            break;
          // 주 2회는 화, 목
          case "2":
            registSugang.checkedLecDay(lecDayList, 1);
            registSugang.checkedLecDay(lecDayList, 3);
            break;
          // 주 3회는 월, 수, 금
          case "3":
            registSugang.checkedLecDay(lecDayList, 0);
            registSugang.checkedLecDay(lecDayList, 2);
            registSugang.checkedLecDay(lecDayList, 4);
            break;
          // 주 3회는 월, 화, 수, 목, 금
          case "5":
            registSugang.checkedLecDay(lecDayList, 0);
            registSugang.checkedLecDay(lecDayList, 1);
            registSugang.checkedLecDay(lecDayList, 2);
            registSugang.checkedLecDay(lecDayList, 3);
            registSugang.checkedLecDay(lecDayList, 4);
            break;
        }
      },

      // 요일 선택
      checkedLecDay(lecDayList, weekOfDay) {
        lecDayList[weekOfDay].checked = true;
      },

      // 요일 설정 정보의 유효성 검증 메서드
      validationLecDay(oneTimeInfo, lecDay) {
        const { numberOfClass, lecDayList } = oneTimeInfo;

        // 현재 선택된 요일 수
        const currentCheckedLecDayCount = lecDayList.filter((lecDay) => lecDay.checked).length;

        if (currentCheckedLecDayCount > numberOfClass) {
          // 요일 선택은 수업 횟수 주 N회를 초과할 수 없음
          lecDay.checked = false;
        }
      },
      containLanguage() {
        let lang =
          registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
            registSugang.currentGroupTimeInfo
          ].sugangGroupLangList[registSugang.currentLanguage].language;
        return lang.includes("중국어") || lang.includes("일본어");
      },

      // 수업 횟수 / 시간 복사 팝업
      popupCopyGroupTime: {
        isShow: false, // 수업 횟수 / 시간 복사 팝업 활성화 여부

        groupTypeList: [], // 그룹 유형 목록

        selectedGroupTypeIdx: "", // 선택된 그룹 유형
        selectedGroupTimeInfoIdx: "", // 선택된 수업 횟수 / 시간 유형

        // 붙여넣기 할 템플릿 정보
        targetGroupTimeInfo: {}, // BaseTimeInfoListObject
      },

      // 수업 횟수 / 시간 복사 팝업 열기
      showPopupForCopyGroupTime() {
        // 기본 설정
        registSugang.popupCopyGroupTime.groupTypeList = registSugang.sugangGroupTypeList; // 선택된 그룹 유형 반환

        // 복사 템플릿 그룹 유형 초기화
        const { groupTypeList } = registSugang.popupCopyGroupTime;
        let defaultSelectedGroupTypeIdx = "";
        if (groupTypeList.length === 1) {
          // 한개인 경우 첫번째 항목 선택 처리
          defaultSelectedGroupTypeIdx = 0;
        }
        registSugang.popupCopyGroupTime.selectedGroupTypeIdx = defaultSelectedGroupTypeIdx;

        // 선택된 수업 횟수 / 시간 유형 초기화
        let defaultSelectedGroupTimeInfoIdx = "";
        if (groupTypeList.length > 0) {
          // 그룹 유형 별 하위 트리 구조 형태로 동일하게 설정되므로 첫번째 항목으로 판단
          const { sugangGroupTimeInfoList } = groupTypeList[0];
          if (sugangGroupTimeInfoList.length === 1) {
            // 한개인 경우 첫번째 항목 선택 처리
            defaultSelectedGroupTimeInfoIdx = 0;
          }
        }
        registSugang.popupCopyGroupTime.selectedGroupTimeInfoIdx = defaultSelectedGroupTimeInfoIdx;

        // 붙여넣기 할 템플릿 정보 초기화
        registSugang.popupCopyGroupTime.targetGroupTimeInfo = new BaseTimeInfoListObject();

        registSugang.popupCopyGroupTime.isShow = true; // 팝업 활성화
      },

      // 수업 횟수 / 시간 복사 팝업 닫기
      closePopupForCopyGroupTimeInfo() {
        registSugang.popupCopyGroupTime.isShow = false;
      },

      // 레벨 복사 팝업
      popupCopyLevel: {
        isShow: false, // 레벨 복사 팝업 활성화 여부

        groupTypeList: [], // 그룹 유형 목록

        selectedGroupTypeIdx: "", // 선택된 그룹 유형
        selectedGroupTimeInfoIdx: "", // 선택된 수업 횟수 / 시간 유형
        selectedGroupTimeInfoLangIdx: "", // 선택된 수강 언어 유형

        templateLevelList: [], // 복사 템플릿으로 제공할 레벨 목록
        selectedLevelTemplate: "", // 선택한 복사 템플릿 레벨

        targetLevelList: [], // 붙여넣기 템플릿 목록
      },

      // 레벨 복사 팝업 열기
      showPopupForCopyLevel() {
        // 기본 설정
        registSugang.popupCopyLevel.groupTypeList = registSugang.sugangGroupTypeList; // 선택된 그룹 유형 반환

        // 복사 템플릿 그룹 유형 초기화
        const { groupTypeList } = registSugang.popupCopyLevel;
        let defaultSelectedGroupTypeIdx = "";
        if (groupTypeList.length === 1) {
          // 한개인 경우 선택된 상태로 처리
          defaultSelectedGroupTypeIdx = 0;
        }
        registSugang.popupCopyLevel.selectedGroupTypeIdx = defaultSelectedGroupTypeIdx;

        // 선택된 수업 횟수 / 시간 유형 초기화
        let sugangGroupTimeInfoList = []; // 수업 횟수 / 시간 초기화를 위한 참조 변수
        let defaultSelectedGroupTimeInfoIdx = "";
        if (groupTypeList.length > 0) {
          // 그룹 유형 별 하위 트리 구조 형태로 동일하게 설정되므로 첫번째 항목으로 판단
          sugangGroupTimeInfoList = groupTypeList[0].sugangGroupTimeInfoList;
          if (sugangGroupTimeInfoList.length === 1) {
            // 한개인 경우 선택된 상태로 처리
            defaultSelectedGroupTimeInfoIdx = 0;
          }
        }
        registSugang.popupCopyLevel.selectedGroupTimeInfoIdx = defaultSelectedGroupTimeInfoIdx;

        // 선택된 수강 언어 유형 초기화
        let defaultSelectedGroupTimeInfoLangIdx = "";
        if (sugangGroupTimeInfoList.length > 0) {
          // 하위 트리 구조 형태가 동일하게 설정되므로 첫번째 항목으로 판단
          const { sugangGroupLangList } = sugangGroupTimeInfoList[0];
          if (sugangGroupLangList.length === 1) {
            // 한개인 경우 선택된 상태로 처리
            defaultSelectedGroupTimeInfoLangIdx = 0;
          }
        }
        registSugang.popupCopyLevel.selectedGroupTimeInfoLangIdx = defaultSelectedGroupTimeInfoLangIdx;

        const templateLevelList = registSugang.levelList.filter((level) => level.checked).map(registSugang.makeDefaultLevel); // 복사 템플릿으로 선택된 목록을 제공한다
        registSugang.popupCopyLevel.templateLevelList = JSON.parse(JSON.stringify(templateLevelList)); // 복사템플릿 레벨 목록

        // 복사 템플릿 레벨 선택값 초기화
        let defaultSelectedLevelTemplate = "";
        if (templateLevelList.length === 1) {
          // 한개인 경우 선택된 상태로 처리
          const [levelInfo] = templateLevelList;
          defaultSelectedLevelTemplate = levelInfo.levelName;
        }
        registSugang.popupCopyLevel.selectedLevelTemplate = defaultSelectedLevelTemplate;

        const targetLevelList = registSugang.levelList.map(registSugang.makeDefaultLevel); // 붙여넣기 템플릿 체크 해제 상태로 제공
        registSugang.popupCopyLevel.targetLevelList = JSON.parse(JSON.stringify(targetLevelList)); // 붙여넣기 템플릿 목록

        registSugang.popupCopyLevel.isShow = true; // 팝업 활성화
      },

      // 붙여넣기 템플릿의 레벨 항목 표시를 위한 데이터셋 반환
      makeDefaultLevel(level) {
        return {
          levelName: level.levelName,
          checked: false,
        };
      },

      // 레벨 복사 팝업 닫기
      closePopupForCopyLevel() {
        registSugang.popupCopyLevel.isShow = false;
      },

      // 선택된 레벨 템플릿을 복사
      copyLevel() {
        const {
          selectedGroupTypeIdx,
          selectedGroupTimeInfoIdx,
          selectedGroupTimeInfoLangIdx,
          selectedLevelTemplate,
          targetLevelList,
        } = registSugang.popupCopyLevel;
        console.log("selectedGroupTypeIdx", selectedGroupTypeIdx);
        console.log("selectedGroupTimeInfoIdx", selectedGroupTimeInfoIdx);
        console.log("selectedGroupTimeInfoLangIdx", selectedGroupTimeInfoLangIdx);
        console.log("selectedLevelTemplate", selectedLevelTemplate);
        console.log("targetLevelList", targetLevelList);
        const selectedTargetLevelList = targetLevelList.filter(
          (level) => level.levelName !== selectedLevelTemplate && level.checked
        );

        if (selectedGroupTypeIdx === "") {
          alert("그룹 유형을 선택해주세요.");
          return;
        }
        if (selectedGroupTimeInfoIdx === "") {
          alert("수업 횟수 / 시간을 선택해주세요.");
          return;
        }
        if (selectedGroupTimeInfoLangIdx === "") {
          alert("수강 언어를 선택해주세요.");
          return;
        }
        if (selectedLevelTemplate === "") {
          alert("레벨을 선택해주세요.");
          return;
        }

        if (!selectedTargetLevelList.length) {
          alert("붙여넣을 레벨을 선택해주세요");
          return;
        }
        let levelBo = registSugang.sugangGroupTypeList[selectedGroupTypeIdx].sugangGroupTimeInfoList[
          selectedGroupTimeInfoIdx
        ].sugangGroupLangList[selectedGroupTimeInfoLangIdx].sugangGroupLevelList.find(
          (level) => level.levelName === selectedLevelTemplate
        );
        console.log("levelBo", levelBo);
        selectedTargetLevelList.forEach((level) => {
          let bo = new BaseLevelListObject();
          //깊은 복사로 참조되지 않도록 처리한다.
          bo = structuredClone(levelBo);
          bo.levelName = level.levelName;
          bo.idxLeSugangGroupLevel = 0;

          // bo 하위의 키 값들을 모두 초기화한다.

          bo.sugangGroupClassList.forEach((classBo) => {
            classBo.idxLeSugangGroupLevel = 0;
            classBo.idxLeSugangGroupClass = 0;
            classBo.sugangGroupClassRoomList.forEach((roomBo) => {
              roomBo.idxLeSugangGroupClass = 0;
              roomBo.idxLeSugangGroupClassRoom = 0;
            });
          });

          //bo 를 각각의 트리구조에 맞추어 추가해준다.
          registSugang.sugangGroupTypeList.forEach((groupTypeList) => {
            groupTypeList.sugangGroupTimeInfoList.forEach((timeInfoList) => {
              timeInfoList.sugangGroupLangList.forEach((langList) => {
                langList.sugangGroupLevelList.push(bo);
              });
            });
          });
        });

        //화면에 보여지는 부분을 위해 추가해준다.
        // 레벨 선택 체크 처리
        registSugang.levelList.forEach((level) => {
          if (selectedTargetLevelList.some((target) => target.levelName === level.levelName)) {
            level.checked = true;
          }
        });

        alert("적용 되었습니다.");

        // 팝업 닫기
        registSugang.closePopupForCopyLevel();
      },

      init: () => {
        registSugang.BaseClassRoomTimeList = registSugang.setTimeInterval();
        // console.log("BaseClassRoomTimeList", registSugang.BaseClassRoomTimeList);
        //그룹유형, 수강언어, 레벨 리스트들의 선택값을 체크해준다.
        // console.log("registSugang.sugangGroupTypeList", registSugang.sugangGroupTypeList);
        if (props.sugangGroupTypeList) {
          registSugang.sugangGroupTypeList = props.sugangGroupTypeList;
          registSugang.sugangGroupTypeList.forEach((groupListItem, index) => {
            //groupListItem.groupType 값이 groupTypeList 에 존재한다면 true로 변경한다.
            registSugang.groupTypeList.forEach((item) => {
              if (item.groupType == groupListItem.groupType) {
                item.checked = true;
                item.isGroupCode = groupListItem.isGroupCode;
                item.studentLimitType = groupListItem.studentLimitType;
                item.studentLimit = groupListItem.studentLimit;
                item.studentLimitWait = groupListItem.studentLimitWait;
              }
            });
            //sugangGroupTimeInfo 를 별도로 내려주지 않으므로 함께 생성해준다.

            groupListItem.sugangGroupTimeInfoList.forEach((groupTimeInfoItem, index) => {
              //퍼스트 차일드를 순회할 때 수업횟수.시간 정보를 가져와서 중복은 제외하고 화면에 보여지는 부분을 설정한다.
              if (
                registSugang.groupTimeInfoList.findIndex(
                  (item) =>
                    item.lessonTime === groupTimeInfoItem.lessonTime &&
                    item.numberOfClass === groupTimeInfoItem.numberOfClass &&
                    item.totalNumberOfClass === groupTimeInfoItem.totalNumberOfClass
                ) < 0
              ) {
                registSugang.groupTimeInfoList = [...registSugang.groupTimeInfoList, ...[groupTimeInfoItem]];
              }

              const bo = new BaseTimeInfoListObject();
              if (!groupTimeInfoItem.lecDayList) {
                // 요일 설정 정보가 없는 경우 기본값 구성
                groupTimeInfoItem.lecDayList = bo.lecDayList;

                // 요일 설정 기본값 설정
                registSugang.changeDefaultLecDayList(groupTimeInfoItem);
              } else {
                // 요일 설정 정보 목록이 있는 경우
                groupTimeInfoItem.lecDayList.forEach((lecDay, index) => {
                  // 요일 이름 설정
                  lecDay.label = bo.lecDayList[index].label;
                });
              }

              groupTimeInfoItem.sugangGroupLangList.forEach((langListItem, index) => {
                //langListItem.language 값이 language 에 존재한다면 true로 변경한다.
                registSugang.languageList.forEach((item) => {
                  if (item.lang == langListItem.language) {
                    item.checked = true;
                  }
                });

                langListItem.sugangGroupLevelList.forEach((levelListItem, index) => {
                  //levelListItem.levelName 값이 levelList 에 존재한다면 true로 변경한다.
                  registSugang.levelList.forEach((item) => {
                    if (item.levelName == levelListItem.levelName) {
                      item.checked = true;
                    }
                  });
                  //levelListItem 이 존재한다면 sugangGroupClassList[n].sugangGroupClassRoomList[n] 의 모든 시간대별 값이 존재해야 한다.
                  //timeList = registSugang.setTimeInterval();
                  levelListItem.sugangGroupClassList.forEach((classListItem, index) => {
                    let timeList = registSugang.setTimeInterval();
                    let map = new Map();
                    timeList.forEach((item) => map.set(item.time, item));
                    classListItem.sugangGroupClassRoomList.forEach((item) =>
                      map.set(item.time, {
                        ...map.get(item.time),
                        ...item,
                      })
                    );
                    classListItem.sugangGroupClassRoomList = Array.from(map.values());
                  });
                });
              });
            });
          });
        }
      },

      getGroupClassInputBorderClass(sugangGroupClassRoom) {
        const { roomSize } = sugangGroupClassRoom;

        // 미입력된 경우
        if (roomSize === "") return "border-red-400 border-4";

        // 입력된 경우
        return "border-slate-800";
      },
    });

    onMounted(() => {
      //키 목록을 가져온다.
      registSugang.init();
    });

    watch(
      () => props.sugangGroupTypeList,
      (cur, prev) => {
        registSugang.sugangGroupTypeList = cur;
        registSugang.init();
      }
    );

    watch(
      () => registSugang.sugangGroupTypeList,
      (cur, prev) => {
        // 이벤트를 통해 부모로 데이터셋을 전달.
        emit("set-regist-sugang-data", cur); // 일단 주석처리.
      }
    );

    return {
      registSugang,
    };
  },
};
</script>
<template>
  <div>
    <div>
      <!-- 그룹 유형 선택 -->
      <div class="mb-5">
        <p class="pb-2 mb-5 text-lg font-semibold border-b-2 border-solid border-slate-800">그룹 유형 선택</p>
        <ul class="flex flex-wrap gap-10">
          <li v-for="(item, index) in registSugang.groupTypeList" :key="index" class="space-y-4">
            <div class="flex items-center gap-4">
              <div class="flex items-center gap-px">
                <input
                  type="checkbox"
                  :id="item.groupType"
                  v-model="item.checked"
                  @change="registSugang.groupTypeChecked(item.checked, item.groupType)"
                />
                <label class="cursor-pointer" :for="item.groupType">{{ item.groupType }}</label>
              </div>
              <ul class="flex gap-2">
                <li class="flex items-center gap-px">
                  <input
                    type="radio"
                    :id="item.groupType + '_studentLimitType1'"
                    v-model="item.studentLimitType"
                    value="Not Limited"
                    @change="registSugang.studentLimitTypeChecked(item)"
                  />
                  <label class="cursor-pointer" :for="item.groupType + '_studentLimitType1'">제한 없음</label>
                </li>
                <li class="flex items-center gap-px">
                  <input
                    type="radio"
                    :id="item.groupType + '_studentLimitType2'"
                    v-model="item.studentLimitType"
                    value="Total"
                    @change="registSugang.studentLimitTypeChecked(item)"
                  />
                  <label class="cursor-pointer" :for="item.groupType + '_studentLimitType2'">과정 전체</label>
                </li>
              </ul>
            </div>
            <div class="flex gap-4" v-if="item.studentLimitType == 'Total'">
              <div class="flex items-center gap-2">
                <p>선착순</p>
                <div class="flex items-center gap-2">
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="item.studentLimit"
                    @change="registSugang.studentLimitInput(item.studentLimit, item.groupType)"
                  />
                  <p>명</p>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <p>대기자</p>
                <div class="flex items-center gap-2">
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="item.studentLimitWait"
                    @change="registSugang.studentLimitWaitInput(item.studentLimitWait, item.groupType)"
                  />
                  <p>명</p>
                </div>
              </div>
            </div>
            <div v-if="item.studentLimitType == 'Total'">
              <span class="font-bold text-red-500">*</span>
              <span>대기자가 없으면 <span class="text-red-500">0명</span> 으로 입력 해주세요</span>
            </div>
            <div class="flex items-center gap-1">
              <label for="code"><span class="font-bold text-red-500">*</span>과정코드입력</label>
              <input
                type="text"
                :id="'code_' + index"
                class="border border-solid rounded border-slate-800 max-w-[100px]"
                v-model="item.isGroupCode"
              />
              <p
                class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
                @click="registSugang.checkCtmGroupCode(item.isGroupCode, item.groupType)"
              >
                코드 확인
              </p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 그룹 유형 선택 
    <div class="mb-5">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">그룹 유형 선택</p>
      <ul class="flex flex-wrap gap-4">
        <li class="flex items-center gap-1" v-for="(item, index) in registSugang.groupTypeList" :key="index">
          <input
            type="checkbox"
            @change="registSugang.groupTypeChecked(item.checked, item.groupType)"
            :id="item.groupType"
            v-model="item.checked"
          />
          <label class="cursor-pointer" :for="item.groupType">{{ item.groupType }}</label>
        </li>
      </ul>
    </div>
    -->
      <!-- 수업횟수/시간 선택 -->
      <div class="mb-5">
        <div class="pb-2 mb-2 border-b-2 border-solid border-slate-800 flex items-center gap-2">
          <p class="text-lg font-semibold">수업 횟수 / 시간</p>
          <p
            class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
            @click="registSugang.showPopupForCopyGroupTime"
          >
            복사하기
          </p>
        </div>
        <table class="mb-2">
          <thead>
            <tr>
              <th>
                <span class="font-bold">*</span>
                수업 횟수
              </th>
              <th>
                <span class="font-bold">*</span>
                요일
              </th>
              <th>
                <span class="font-bold">*</span>
                총 수업 횟수
              </th>
              <th>
                <span class="font-bold">*</span>
                수업 시간(분)
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in registSugang.groupTimeInfoList" :key="index">
              <td>
                <div class="flex items-center justify-center gap-2">
                  <p>주</p>
                  <select
                    class="border border-slate-800 border-solid rounded w-full max-w-[100px]"
                    v-model="item.numberOfClass"
                    @change="registSugang.changeNumberOfClass(item.numberOfClass, index)"
                  >
                    <option value="0" selected>선택</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                  </select>
                  <p>회</p>
                </div>
              </td>
              <td>
                <div class="flex items-center justify-center gap-2">
                  <ul class="flex gap-4 flex-wrap">
                    <li v-for="(lecDay, lecDayIndex) in item.lecDayList" :key="lecDayIndex" class="flex items-center gap-1">
                      <input
                        type="checkbox"
                        :id="'chk1_' + index + '_' + lecDayIndex"
                        v-model="lecDay.checked"
                        @change="registSugang.validationLecDay(item, lecDay)"
                      />
                      <label :for="'chk1_' + index + '_' + lecDayIndex">{{ lecDay.label }}</label>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <div class="flex items-center justify-center gap-2">
                  <p>총</p>
                  <input
                    type="text"
                    class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                    v-model="item.totalNumberOfClass"
                    @change="registSugang.changeTotalNumberOfClass(item.totalNumberOfClass, index)"
                  />
                  <p>회</p>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  class="border border-solid rounded border-slate-800"
                  v-model="item.lessonTime"
                  @change="registSugang.changeLessonTime(item.lessonTime, index)"
                />
              </td>
              <td>
                <p
                  class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
                  @click="registSugang.removeGroupTimeInfo(index, item)"
                >
                  삭제
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-end">
          <p
            class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
            @click="registSugang.addGroupTimeInfo"
          >
            추가입력
          </p>
        </div>
      </div>
      <!-- 수강 언어 선택 -->
      <div class="mb-5">
        <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수강 언어 선택</p>
        <ul class="flex flex-wrap gap-4">
          <li v-for="(item, index) in registSugang.languageList" :key="index" class="flex items-center gap-1">
            <input
              type="checkbox"
              @change="registSugang.languageChecked(item.checked, item.lang)"
              :id="item.lang"
              v-model="item.checked"
            />
            <label class="cursor-pointer" :for="item.lang">{{ item.lang }}</label>
          </li>
        </ul>
      </div>
      <!-- 레벨 선택 -->
      <div class="mb-5">
        <div class="pb-2 mb-2 border-b-2 border-solid border-slate-800 flex items-center gap-2">
          <p class="text-lg font-semibold">레벨 선택</p>
          <p
            class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
            @click="registSugang.showPopupForCopyLevel"
          >
            복사하기
          </p>
        </div>
        <ul class="grid grid-cols-7 gap-4">
          <li v-for="(item, index) in registSugang.levelList" :key="index" class="flex items-center gap-1">
            <input
              type="checkbox"
              @change="registSugang.levelChecked(item.checked, item.levelName)"
              :id="item.levelName"
              v-model="item.checked"
            />
            <label class="cursor-pointer" :for="item.levelName">{{ item.levelName }}</label>
          </li>
        </ul>
      </div>

      <!-- 수업 등록 -->
      <div class="p-2">
        <div class="">
          <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수업 등록</p>
          <p class="mb-2 text-red-500">
            * [그룹] [언어] [레벨] 선택에 따른 수업 등록입니다.<br />
            * 하단의 [그룹] [언어] [레벨] 을 선택하면 개별 수업들의 입력 화면이 나타납니다.
          </p>
          <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800">
            <template v-if="registSugang.sugangGroupTypeList">
              <li
                v-for="(item, index) in registSugang.sugangGroupTypeList"
                :key="index"
                class="p-2 text-white cursor-pointer"
                :class="{
                  'border-b-4 border-solid border-white': registSugang.currentGroupType == index,
                }"
                @click="registSugang.setCurrentGroupType(index)"
              >
                {{ item.groupType }}
              </li>
            </template>
          </ul>
          <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800">
            <template v-if="registSugang.sugangGroupTypeList[0]">
              <li
                v-for="(item, index) in registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList"
                :key="index"
                class="p-2 text-white cursor-pointer"
                :class="{
                  'border-b-4 border-solid border-white': registSugang.currentGroupTimeInfo == index,
                }"
                @click="registSugang.setCurrentGroupTimeInfo(index)"
              >
                주 {{ item.numberOfClass }}회/총 {{ item.totalNumberOfClass }}회/{{ item.lessonTime }}분
              </li>
            </template>
          </ul>
          <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800">
            <template v-if="registSugang.sugangGroupTypeList[0]?.sugangGroupTimeInfoList[0]">
              <li
                v-for="(item, index) in registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList[0].sugangGroupLangList"
                :key="index"
                class="p-2 text-white cursor-pointer"
                :class="{
                  'border-b-4 border-solid border-white': registSugang.currentLanguage == index,
                }"
                @click="registSugang.setCurrentLanguage(index)"
              >
                {{ item.language }}
              </li>
            </template>
          </ul>

          <ul class="flex gap-4 p-1 mb-2 rounded bg-slate-800 overflow-auto">
            <template
              v-if="registSugang.sugangGroupTypeList[0]?.sugangGroupTimeInfoList[0]?.sugangGroupLangList[0]?.sugangGroupLevelList"
            >
              <li
                v-for="(item, index) in registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList[0].sugangGroupLangList[0]
                  .sugangGroupLevelList"
                :key="index"
                class="p-2 text-white cursor-pointer"
                :class="{
                  'border-b-4 border-solid border-white': registSugang.currentLevel == index,
                }"
                @click="registSugang.setCurrentLevel(index)"
              >
                {{ item.levelName }}
              </li>
            </template>
          </ul>
        </div>
      </div>

      <!-- [그룹] [언어] [레벨] 선택에 따른 수업 등록 / 타입, 언어, 레벨이 모두 선택되어야 함.-->
      <div class="">
        <div class="flex items-center justify-between mb-2">
          <ul
            class="flex gap-2"
            v-if="
              registSugang.currentGroupType > -1 &&
              registSugang.currentGroupTimeInfo > -1 &&
              registSugang.currentLanguage > -1 &&
              registSugang.currentLevel > -1
            "
          >
            <li
              class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
              @click="registSugang.addRow()"
            >
              수업 추가
            </li>
            <li
              class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
              @click="registSugang.deleteRows()"
            >
              선택수업 삭제
            </li>
          </ul>
        </div>
        <table
          class="mb-5"
          v-if="
            registSugang.currentGroupType > -1 &&
            registSugang.currentGroupTimeInfo > -1 &&
            registSugang.currentLanguage > -1 &&
            registSugang.currentLevel > -1
          "
        >
          <thead>
            <tr>
              <th></th>
              <th>그룹유형</th>
              <th>언어</th>
              <th>레벨</th>
              <th>수업</th>
              <th>수업 안내</th>
              <th>교재 소개</th>
              <th>교재비</th>
              <th>교육비</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, classIndex) in registSugang.sugangGroupTypeList[registSugang.currentGroupType]
                .sugangGroupTimeInfoList[registSugang.currentGroupTimeInfo].sugangGroupLangList[registSugang.currentLanguage]
                .sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList"
              :key="classIndex"
            >
              <td>
                <input type="radio" :value="classIndex" v-model="registSugang.currentClassListIdx" />
              </td>
              <td>
                {{ registSugang.sugangGroupTypeList[registSugang.currentGroupType].groupType }}
              </td>
              <td>
                {{
                  registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
                    registSugang.currentGroupTimeInfo
                  ].sugangGroupLangList[registSugang.currentLanguage].language
                }}
              </td>
              <td>
                {{
                  registSugang.sugangGroupTypeList[registSugang.currentGroupType].sugangGroupTimeInfoList[
                    registSugang.currentGroupTimeInfo
                  ].sugangGroupLangList[registSugang.currentLanguage].sugangGroupLevelList[registSugang.currentLevel].levelName
                }}
              </td>
              <td>
                <select
                  v-model="item.className"
                  name=""
                  id=""
                  class="w-full text-center border border-solid rounded cursor-pointer border-slate-800 max-w-[120px]"
                  @change="registSugang.checkDuplicateSelection(item.className, classIndex)"
                >
                  <option v-if="!registSugang.containLanguage()">데일리토픽</option>
                  <option v-if="!registSugang.containLanguage()">로이터</option>
                  <option v-if="!registSugang.containLanguage()">리딩북앤톡</option>
                  <option v-if="!registSugang.containLanguage()">인터뷰</option>
                  <option>일반회화</option>
                  <option v-if="!registSugang.containLanguage()">커리어</option>
                  <option v-if="!registSugang.containLanguage()">테드</option>
                  <option v-if="!registSugang.containLanguage()">토론</option>
                  <option v-if="!registSugang.containLanguage()">스킬심화</option>
                  <option v-if="!registSugang.containLanguage()">파닉스</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  class="border border-solid rounded border-slate-800 max-w-[150px]"
                  v-model="item.classInfo"
                  placeholder="수업 안내(URL)"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="border border-solid rounded border-slate-800 max-w-[150px]"
                  v-model="item.bookInfo"
                  placeholder="교재 소개(URL)"
                />
              </td>
              <td>
                <input type="text" class="border border-solid rounded border-slate-800 max-w-[150px]" v-model="item.bookPrice" />
              </td>
              <td>
                <input type="text" class="border border-solid rounded border-slate-800 max-w-[150px]" v-model="item.classPrice" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 그룹 개수 설정 -->
      <div class="mb-5">
        <p class="mb-2 text-red-500">* 각 시간마다 생성할 그룹 개수를 설정해주세요.</p>
        <ul class="gap-4 mb-5">
          <li>
            <table
              v-if="
                registSugang.currentGroupType > -1 &&
                registSugang.currentGroupTimeInfo > -1 &&
                registSugang.currentLanguage > -1 &&
                registSugang.currentLevel > -1
              "
              class="w-auto"
            >
              <thead ref="timeTableHeaderSkticyElement" class="sticky top-0">
                <tr>
                  <th class="w-[220px]">수업시작시간</th>
                  <th
                    v-for="(item, classIndex) in registSugang.sugangGroupTypeList[registSugang.currentGroupType]
                      .sugangGroupTimeInfoList[registSugang.currentGroupTimeInfo].sugangGroupLangList[
                      registSugang.currentLanguage
                    ].sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList"
                    :key="classIndex"
                  >
                    {{ item.className }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in registSugang.BaseClassRoomTimeList" :key="index">
                  <td class="text-sm">{{ item.startTime }} ~ {{ item.endTime }}</td>
                  <td
                    v-for="(item, classIndex) in registSugang.sugangGroupTypeList[registSugang.currentGroupType]
                      .sugangGroupTimeInfoList[registSugang.currentGroupTimeInfo].sugangGroupLangList[
                      registSugang.currentLanguage
                    ].sugangGroupLevelList[registSugang.currentLevel].sugangGroupClassList"
                    :key="classIndex"
                  >
                    <input
                      type="text"
                      class="border border-solid rounded w-full max-w-[80px]"
                      :class="registSugang.getGroupClassInputBorderClass(item.sugangGroupClassRoomList[index])"
                      v-model="item.sugangGroupClassRoomList[index].roomSize"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </li>

          <li ref="timeTableHeaderSkticyDisableElement"></li>
        </ul>
      </div>
    </div>

    <!-- 수업 횟수/ 시간 선택 복사하기 버튼 클릭 시 팝업 활성화 -->
    <div v-if="registSugang.popupCopyGroupTime.isShow">
      <div class="fixed inset-0 overflow-y-auto size-full z-[2]">
        <div class="fixed inset-0 bg-black/50"></div>

        <div class="relative flex items-center justify-center min-h-full p-4">
          <div class="p-4 bg-white rounded w-[1400px]">
            <p class="mb-4 text-xl font-bold">복사 템플릿 선택</p>

            <div class="mb-4">
              <!-- 그룹 유형 -->
              <ul class="grid grid-cols-7 gap-4 mb-4">
                <template v-if="registSugang.popupCopyGroupTime.groupTypeList">
                  <li
                    v-for="(item, index) in registSugang.popupCopyGroupTime.groupTypeList"
                    :key="index"
                    class="flex items-center gap-1"
                  >
                    <input
                      type="radio"
                      :id="'copy-popup-group-type-' + index"
                      :value="index"
                      v-model="registSugang.popupCopyGroupTime.selectedGroupTypeIdx"
                    />
                    <label class="cursor-pointer" :for="'copy-popup-group-type-' + index">{{ item.groupType }}</label>
                  </li>
                </template>
              </ul>

              <!-- 수업 횟수 / 시간 유형 -->
              <div class="mb-4">
                <template v-if="registSugang.popupCopyGroupTime.groupTypeList[0]">
                  <table class="mb-2 w-full">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <span class="font-bold">*</span>
                          수업 횟수
                        </th>
                        <th>
                          <span class="font-bold">*</span>
                          요일
                        </th>
                        <th>
                          <span class="font-bold">*</span>
                          총 수업 횟수
                        </th>
                        <th>
                          <span class="font-bold">*</span>
                          수업 시간(분)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in registSugang.popupCopyGroupTime.groupTypeList[0].sugangGroupTimeInfoList"
                        :key="index"
                      >
                        <td>
                          <div class="flex item-center justify-center">
                            <input
                              type="radio"
                              :id="'copy-popup-group-time-info-' + index"
                              :value="index"
                              v-model="registSugang.popupCopyGroupTime.selectedGroupTimeInfoIdx"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="flex items-center justify-center gap-2">
                            <p>주</p>
                            <select class="border border-slate-800 border-solid rounded max-w-[100px] !bg-[#d3d3d3]" disabled>
                              <option value="0" :selected="String(item.numberOfClass) === '0'">선택</option>
                              <option value="2" :selected="String(item.numberOfClass) === '2'">2</option>
                              <option value="3" :selected="String(item.numberOfClass) === '3'">3</option>
                              <option value="5" :selected="String(item.numberOfClass) === '5'">5</option>
                            </select>
                            <p>회</p>
                          </div>
                        </td>
                        <td>
                          <div class="flex items-center justify-center gap-2">
                            <ul class="flex gap-4 flex-wrap">
                              <template v-for="(lecDay, lecDayIndex) in item.lecDayList" :key="lecDayIndex">
                                <li v-if="lecDay.checked" class="flex items-center gap-1">
                                  <input
                                    type="checkbox"
                                    class="!bg-[#d3d3d3]"
                                    :id="'chk1_' + index + '_' + lecDayIndex"
                                    v-model="lecDay.checked"
                                    disabled
                                  />
                                  <label>{{ lecDay.label }}</label>
                                </li>
                              </template>
                            </ul>
                          </div>
                        </td>
                        <td>
                          <div class="flex items-center justify-center gap-2">
                            <p>총</p>
                            <input
                              type="text"
                              class="border border-solid !border-color-[#d3d3d3] rounded max-w-[50px] !bg-[#d3d3d3]"
                              :value="item.totalNumberOfClass"
                              readonly
                            />
                            <p>회</p>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="border border-solid !border-color-[#d3d3d3] rounded !bg-[#d3d3d3]"
                            v-model="item.lessonTime"
                            readonly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
            </div>

            <p class="mb-4 border-b-2 border-solid border-slate-400"></p>

            <p class="mb-4 text-xl font-bold">붙여넣기 템플릿 선택</p>

            <div class="mb-4">
              <table class="mb-2 w-full">
                <thead>
                  <tr>
                    <th>
                      <span class="font-bold">*</span>
                      수업 횟수
                    </th>
                    <th>
                      <span class="font-bold">*</span>
                      요일
                    </th>
                    <th>
                      <span class="font-bold">*</span>
                      총 수업 횟수
                    </th>
                    <th>
                      <span class="font-bold">*</span>
                      수업 시간(분)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="flex items-center justify-center gap-2">
                        <p>주</p>
                        <select
                          class="border border-slate-800 border-solid rounded max-w-[100px]"
                          v-model="registSugang.popupCopyGroupTime.targetGroupTimeInfo.numberOfClass"
                          @change="registSugang.changeDefaultLecDayList(registSugang.popupCopyGroupTime.targetGroupTimeInfo)"
                        >
                          <option value="0" selected>선택</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="5">5</option>
                        </select>
                        <p>회</p>
                      </div>
                    </td>
                    <td>
                      <div class="flex items-center justify-center gap-2">
                        <ul class="flex gap-4 flex-wrap">
                          <li
                            v-for="(lecDay, lecDayIndex) in registSugang.popupCopyGroupTime.targetGroupTimeInfo.lecDayList"
                            :key="lecDayIndex"
                            class="flex items-center gap-1"
                          >
                            <input
                              type="checkbox"
                              :id="'popup_chk1_' + '_' + lecDayIndex"
                              v-model="lecDay.checked"
                              @change="registSugang.validationLecDay(registSugang.popupCopyGroupTime.targetGroupTimeInfo, lecDay)"
                            />
                            <label>{{ lecDay.label }}</label>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div class="flex items-center justify-center gap-2">
                        <p>총</p>
                        <input
                          type="text"
                          class="border border-slate-800 border-solid rounded max-w-[50px]"
                          v-model="registSugang.popupCopyGroupTime.targetGroupTimeInfo.totalNumberOfClass"
                        />
                        <p>회</p>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="border border-solid rounded border-slate-800"
                        v-model="registSugang.popupCopyGroupTime.targetGroupTimeInfo.lessonTime"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ul class="flex justify-between">
              <li @click="registSugang.closePopupForCopyGroupTimeInfo" class="p-2 border border-solid rounded cursor-pointer">
                취소
              </li>
              <li
                @click="registSugang.copyGroupTimeInfo"
                class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
              >
                적용
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 레벨 선택 복사하기 버튼 클릭 시 팝업 활성화 -->
    <div v-if="registSugang.popupCopyLevel.isShow">
      <div class="fixed inset-0 overflow-y-auto size-full z-[2]">
        <div class="fixed inset-0 bg-black/50"></div>

        <div class="relative flex items-center justify-center min-h-full p-4">
          <div class="p-4 bg-white rounded w-[1400px]">
            <p class="mb-4 text-xl font-bold">복사 템플릿 선택</p>

            <div class="mb-4">
              <!-- 그룹 유형 -->
              <ul class="grid grid-cols-7 gap-4 mb-4">
                <template v-if="registSugang.popupCopyLevel.groupTypeList">
                  <li
                    v-for="(item, index) in registSugang.popupCopyLevel.groupTypeList"
                    :key="index"
                    class="flex items-center gap-1"
                  >
                    <input
                      type="radio"
                      :id="'copy-popup-group-type-' + index"
                      :value="index"
                      v-model="registSugang.popupCopyLevel.selectedGroupTypeIdx"
                    />
                    <label class="cursor-pointer" :for="'copy-popup-group-type-' + index">{{ item.groupType }}</label>
                  </li>
                </template>
              </ul>

              <!-- 수업 횟수 / 시간 유형 -->
              <ul class="grid grid-cols-7 gap-4 mb-4">
                <template v-if="registSugang.popupCopyLevel.groupTypeList[0]">
                  <li
                    v-for="(item, index) in registSugang.popupCopyLevel.groupTypeList[0].sugangGroupTimeInfoList"
                    :key="index"
                    class="flex items-center gap-1"
                  >
                    <input
                      type="radio"
                      :id="'copy-popup-group-time-info-' + index"
                      :value="index"
                      v-model="registSugang.popupCopyLevel.selectedGroupTimeInfoIdx"
                    />
                    <label class="cursor-pointer" :for="'copy-popup-group-time-info-' + index">
                      주 {{ item.numberOfClass }}회/총 {{ item.totalNumberOfClass }}회/{{ item.lessonTime }}분
                    </label>
                  </li>
                </template>
              </ul>

              <!-- 언어 유형 -->
              <ul class="grid grid-cols-7 gap-4 mb-4">
                <template v-if="registSugang.popupCopyLevel.groupTypeList[0]?.sugangGroupTimeInfoList[0]">
                  <li
                    v-for="(item, index) in registSugang.popupCopyLevel.groupTypeList[0].sugangGroupTimeInfoList[0]
                      .sugangGroupLangList"
                    :key="index"
                    class="flex items-center gap-1"
                  >
                    <input
                      type="radio"
                      :id="'copy-popup-group-time-info-lang-' + index"
                      :value="index"
                      v-model="registSugang.popupCopyLevel.selectedGroupTimeInfoLangIdx"
                    />
                    <label class="cursor-pointer" :for="'copy-popup-group-time-info-lang-' + index">
                      {{ item.language }}
                    </label>
                  </li>
                </template>
              </ul>

              <!-- 팝업 노출 전 선택된 레벨 기준으로 복사 템플릿 항목 제공 -->
              <ul class="grid grid-cols-7 gap-4">
                <li
                  v-for="(item, index) in registSugang.popupCopyLevel.templateLevelList"
                  :key="index"
                  class="flex items-center gap-1"
                >
                  <input
                    type="radio"
                    :id="'copy-popup-template' + item.levelName"
                    :value="item.levelName"
                    v-model="registSugang.popupCopyLevel.selectedLevelTemplate"
                  />
                  <label class="cursor-pointer" :for="'copy-popup-template' + item.levelName">{{ item.levelName }}</label>
                </li>
              </ul>
            </div>

            <p class="mb-4 border-b-2 border-solid border-slate-400"></p>

            <p class="mb-4 text-xl font-bold">붙여넣기 템플릿 선택</p>

            <ul class="grid grid-cols-7 gap-4">
              <li
                v-for="(item, index) in registSugang.popupCopyLevel.targetLevelList"
                :key="index"
                class="flex items-center gap-1"
              >
                <input
                  type="checkbox"
                  :id="'copy-popup-target' + item.levelName"
                  v-model="item.checked"
                  :disabled="registSugang.popupCopyLevel.templateLevelList.some((levelBo) => levelBo.levelName == item.levelName)"
                  :class="
                    registSugang.popupCopyLevel.templateLevelList.some((levelBo) => levelBo.levelName == item.levelName)
                      ? '!bg-[#d3d3d3]'
                      : ''
                  "
                />
                <label class="cursor-pointer" :for="'copy-popup-target' + item.levelName">{{ item.levelName }}</label>
              </li>
            </ul>

            <div class="mb-4">
              <!-- 복사 템플릿에서 선택된 레벨은 비활성화 처리 -->
            </div>

            <ul class="flex justify-between">
              <li @click="registSugang.closePopupForCopyLevel" class="p-2 border border-solid rounded cursor-pointer">취소</li>
              <li @click="registSugang.copyLevel" class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer">
                적용
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
