<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">수강신청 바로가기</p>
      </div>
    </div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <div class="mb-14 pc:mb-[140px]">
        <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">[평생교육 수강신청 동의사항]</p>
        <div class="px-5 py-[22px] border border-solid border-[#E7E7E7] rounded-md mb-4">
          <p class="text-sm pc:text-lg text-[#737373] font-bold mb-4 pc:mb-8">
            원활한 수업환경과 학생 및 강사님을 위해 아래 사항을 꼭 지켜주시기 바랍니다.
          </p>
          <ul class="text-sm pc:text-lg text-[#737373] space-y-4 pc:space-y-8">
            <li>
              1. 수업 참여는 학생만 할 수 있게 해주시기 바랍니다.<br />
              <span class="ml-4">
                - 학부모님들의 수업 개입으로 원활한 수업이 진행되지 않을 시, 해당 학생은 임의 조정 될 수 있습니다.
              </span>
            </li>
            <li>
              2. 집중도 높은 수업 진행을 위하여 학생의 주변 환경을 조용하고 독립적인 공간으로 사전에 준비해주시기 바랍니다.<br />
              <span class="ml-4"> - 헤드셋 착용 필수, 주변 소음 차단 </span>
            </li>
            <li>3. 고성, 비속어 사용은 자제해주시고, 수업 중 타학생과 강사님에 대한 존중과 배려 바랍니다.</li>
          </ul>

          <p class="text-sm pc:text-lg text-[#737373] font-bold mb-4 pc:mb-6 pc:mt-8">그룹수업 수강료 환불 안내</p>
          <ul class="text-sm pc:text-lg text-[#737373] space-y-4 pc:space-y-8">
            <li>
              1. 수강료의 환불 규정은 다음과 같습니다<br />
              <ul class="ml-4">
                <li>1) 학습 시스템을 운영할 수 없게 된 경우</li>
                <ul class="ml-4">
                  <li>- 학습 시스템을 운영할 수 없게 된 날로부터 이미 납부한 수강료를 일할 계산하여 환불</li>
                </ul>
                <li>2) 수강생이 본의의 의사로 수강을 포기한 경우</li>
                <ul class="ml-4">
                  <li>- 수업안내문 발송 이전 : 이미 납부한 수강료 전액 환불</li>
                  <li>- 수업안내문 발송 이후 : 환불 불가</li>
                </ul>
              </ul>
            </li>
            <li>2. 자세한 환불규정은 공지사항 메뉴에서 확인 바랍니다.</li>
          </ul>
        </div>

        <div>
          <label class="text-xs font-bold cursor-pointer pc:text-lg check-label text-neutral-950"
            >위 사항을 확인했으며 동의합니다.
            <input type="checkbox" @click="enrolment.sugangEnrolment()" />
            <div class="checkmark border-solid border border-[#c7c7c7] rounded"></div>
          </label>
        </div>
      </div>

      <!-- 동의 시 생김 -->
      <div v-if="enrolment.isShowSugang" class="flex flex-col gap-14 pc:gap-20">
        <div class="flex flex-col gap-16 pc:flex-row">
          <!-- 선택 리스트 -->
          <div class="grow">
            <ul class="flex flex-col gap-14 pc:gap-20">
              <!-- 공통 -->
              <li>
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 스케줄</p>
                <div class="grid grid-cols-1 gap-2.5 pc:gap-4">
                  <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                    <li
                      @click="enrolment.setDefaultData('sugangSupType', '일반')"
                      class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                      :class="{ active: enrolment.commonParamsData.sugangSupType === '일반' }"
                    >
                      {{ enrolment.sugangDefaultInfo.normalButtonName }}
                    </li>
                    <li
                      v-if="enrolment.sugangDefaultInfo.isGroupSup === 'Y' || enrolment.sugangDefaultInfo.isOneSup === 'Y'"
                      @click="enrolment.setDefaultData('sugangSupType', '지원')"
                      class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                      :class="{ active: enrolment.commonParamsData.sugangSupType === '지원' }"
                    >
                      {{ enrolment.sugangDefaultInfo.supButtonName }}
                    </li>
                  </ul>

                  <ul v-if="enrolment.commonParamsData.sugangSupType" class="grid grid-cols-2 gap-2.5 pc:gap-4">
                    <li
                      v-if="enrolment.sugangDefaultInfo.isAdult === 'N'"
                      @click="enrolment.setDefaultData('adult', 'junior')"
                      class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                      :class="{
                        active: enrolment.commonParamsData.adult === 'junior',
                      }"
                    >
                      주니어
                    </li>
                    <li
                      v-if="enrolment.sugangDefaultInfo.mainContent === 'adult' && enrolment.sugangDefaultInfo.isAdult === 'Y'"
                      @click="enrolment.setDefaultData('adult', 'adult')"
                      class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                      :class="{
                        active: enrolment.commonParamsData.adult === 'adult',
                      }"
                    >
                      성인(20세 이상)
                    </li>
                  </ul>

                  <ul v-if="enrolment.commonParamsData.sugangSupType" class="grid grid-cols-2 gap-2.5 pc:gap-4">
                    <li
                      v-if="
                        !(
                          enrolment.sugangDefaultInfo.isOne !== 'Y' ||
                          (enrolment.commonParamsData.sugangSupType === '지원' && enrolment.sugangDefaultInfo.isOneSup !== 'Y')
                        )
                      "
                      @click="enrolment.setDefaultData('classType', 'one')"
                      :class="{ active: enrolment.commonParamsData.classType === 'one' }"
                      class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    >
                      1:1 수업
                    </li>
                    <li
                      v-if="
                        !(
                          enrolment.sugangDefaultInfo.isGroup !== 'Y' ||
                          enrolment.sugangDefaultInfo.isAdult === 'Y' ||
                          (enrolment.commonParamsData.sugangSupType === '지원' && enrolment.sugangDefaultInfo.isGroupSup !== 'Y')
                        )
                      "
                      @click="enrolment.setDefaultData('classType', 'group')"
                      :class="{ active: enrolment.commonParamsData.classType === 'group' }"
                      class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    >
                      그룹수업
                    </li>
                  </ul>
                </div>
              </li>

              <!-- 1:1 -->
              <li v-if="enrolment.commonParamsData.classType === 'one'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 유형</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                  <li
                    v-if="enrolment.oneAndOneSugangData.isClassTypeMobile === 'Y'"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('isMobilePc', 'mobile')"
                    :class="{
                      active: enrolment.detailOneAndOneParamsData.isMobilePc === 'mobile',
                    }"
                  >
                    모바일 화상
                  </li>
                  <li
                    v-if="enrolment.oneAndOneSugangData.isClassTypePc === 'Y'"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('isMobilePc', 'pc')"
                    :class="{
                      active: enrolment.detailOneAndOneParamsData.isMobilePc === 'pc',
                    }"
                  >
                    PC 화상
                  </li>
                </ul>
              </li>

              <!-- 1:1 언어 선택 -->
              <li v-if="enrolment.commonParamsData.classType === 'one'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">언어 선택</p>

                <ul
                  v-if="enrolment.oneAndOneSugangData.idxLeSugangOne && enrolment.commonParamsData.classType === 'one'"
                  class="grid grid-cols-2 gap-2.5 pc:gap-4"
                >
                  <li
                    v-for="langItem in enrolment.oneAndOneSugangData.sugangOneLangList"
                    :key="langItem.idxLeSugangOneLang"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('selectedLanguageInfo', langItem)"
                    :class="{
                      active:
                        enrolment.detailOneAndOneParamsData.selectedLanguageInfo.idxLeSugangOneLang ===
                        langItem.idxLeSugangOneLang,
                    }"
                  >
                    {{ langItem.language }}
                  </li>
                </ul>
              </li>

              <!-- 1:1 -->
              <li
                v-if="
                  enrolment.detailOneAndOneParamsData.selectedLanguageInfo.idxLeSugangOneLang &&
                  enrolment.commonParamsData.classType === 'one'
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 선택</p>
                <select
                  class="w-full text-sm font-medium text-center border border-solid rounded-md cursor-pointer pc:text-lg text-neutral-500 border-neutral-100 active:border-orange-500 active:text-orange-500 active:font-bold pc:py-6"
                  @change="(event) => enrolment.setDetailOneAndOneParamsData('selectedClassInfo', JSON.parse(event.target.value))"
                >
                  <option value="{}">수업 선택</option>
                  <option
                    v-for="classInfo in enrolment.detailOneAndOneParamsData.selectedLanguageInfo.sugangOneClassList"
                    :key="classInfo.idxLeSugangOneClass"
                    :value="JSON.stringify(classInfo)"
                  >
                    {{ classInfo.className }}
                  </option>
                </select>

                <ul class="grid grid-cols-2 gap-3 pc:gap-4 pt-2.5 pc:pt-4">
                  <li
                    v-if="enrolment.detailOneAndOneParamsData.selectedClassInfo.classInfo"
                    class="flex items-center justify-between px-5 py-4 text-sm font-medium text-orange-500 rounded-sm cursor-pointer pc:px-7 pc:py-6 pc:rounded bg-orange-50 pc:text-lg"
                    @click="enrolment.openURL(enrolment.detailOneAndOneParamsData.selectedClassInfo.classInfo)"
                  >
                    <div></div>
                    <p>수업 안내</p>
                    <div>
                      <img src="@/lifeeduWeb/assets/images/enrolment/enrolment/arrow.png" alt="icon" class="w-1 pc:w-2" />
                    </div>
                  </li>
                  <li
                    v-if="enrolment.detailOneAndOneParamsData.selectedClassInfo.bookInfo"
                    class="flex items-center justify-between px-5 py-4 text-sm font-medium text-orange-500 rounded-sm cursor-pointer pc:px-7 pc:py-6 pc:rounded bg-orange-50 pc:text-lg"
                    @click="enrolment.openURL(enrolment.detailOneAndOneParamsData.selectedClassInfo.bookInfo)"
                  >
                    <div></div>
                    <p>교재 소개</p>
                    <div>
                      <img src="@/lifeeduWeb/assets/images/enrolment/enrolment/arrow.png" alt="icon" class="w-1 pc:w-2" />
                    </div>
                  </li>
                </ul>
              </li>

              <!-- 1:1 -->
              <li
                v-if="
                  enrolment.detailOneAndOneParamsData.selectedClassInfo?.idxLeSugangOneClass &&
                  enrolment.oneAndOneSugangData.idxLeSugangOne
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">레벨 선택</p>
                <ul class="grid grid-cols-4 gap-2.5 pc:gap-4 mb-2.5 pc:mb-4">
                  <li
                    v-for="sugangOneLevelInfo in enrolment.detailOneAndOneParamsData.selectedClassInfo.sugangOneLevelList"
                    :key="sugangOneLevelInfo.idxLeSugangOneLevel"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('selectedLevelInfo', sugangOneLevelInfo)"
                    :class="{
                      active:
                        enrolment.detailOneAndOneParamsData.selectedLevelInfo.idxLeSugangOneLevel ===
                        sugangOneLevelInfo.idxLeSugangOneLevel,
                    }"
                  >
                    {{ sugangOneLevelInfo.levelName }}
                  </li>
                </ul>
                <p
                  v-if="enrolment.oneAndOneSugangData.levelChoiceText"
                  class="py-2.5 px-9 text-center text-lg text-rose-600 rounded-3xl shadow-md"
                >
                  {{ enrolment.oneAndOneSugangData.levelChoiceText }}
                </p>
              </li>

              <!-- 1:1 -->
              <li v-if="enrolment.oneAndOneSugangData.idxLeSugangOne">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 횟수 선택</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                  <li
                    v-for="sugangOneTimeInfo in enrolment.oneAndOneSugangData.sugangOneTimeInfoList"
                    :key="sugangOneTimeInfo.idxLeSugangOneTimeInfo"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="
                      enrolment.setDetailOneAndOneParamsData('idxLeSugangOneTimeInfo', sugangOneTimeInfo.idxLeSugangOneTimeInfo)
                    "
                    :class="{
                      active:
                        sugangOneTimeInfo.idxLeSugangOneTimeInfo === enrolment.detailOneAndOneParamsData.idxLeSugangOneTimeInfo,
                    }"
                  >
                    주{{ sugangOneTimeInfo.numberOfClass }}회 {{ sugangOneTimeInfo.lessonTime }}분
                  </li>
                </ul>
                <p
                  v-if="enrolment.oneAndOneSugangData.numberOfClassText"
                  class="py-2.5 px-9 text-center text-lg text-rose-600 rounded-3xl shadow-md"
                >
                  {{ enrolment.oneAndOneSugangData.numberOfClassText }}
                </p>
              </li>

              <!-- 1:1 -->
              <li v-if="!enrolment.isLoading && enrolment.commonParamsData.classType === 'one'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">희망 수업시간 선택</p>
                <ul class="flex flex-col gap-2.5 pc:gap-4">
                  <li class="flex flex-col gap-2.5 pc:gap-4 pc:flex-row">
                    <p
                      class="py-4 text-sm font-bold text-center text-orange-500 border border-solid rounded-sm pc:py-5 pc:px-9 pc:rounded pc:text-lg"
                    >
                      희망 시간 1
                    </p>
                    <ul class="flex items-center gap-1 p-1.5 rounded-sm pc:rounded pc:gap-2 pc:p-2.5 bg-neutral-100 grow">
                      <li class="h-full grow">
                        <select
                          class="w-full h-full text-sm font-medium text-center text-neutral-500 pr-7 pc:pr-10 pc:text-lg"
                          :value="enrolment.detailOneAndOneParamsData.sugangHopeTime1.split(':')[0]"
                          @change="
                            (event) =>
                              enrolment.setDetailOneAndOneParamsData(
                                'sugangHopeTime1',
                                event.target.value +
                                  ':' +
                                  (enrolment.detailOneAndOneParamsData.sugangHopeTime1.split(':')[1]
                                    ? enrolment.detailOneAndOneParamsData.sugangHopeTime1.split(':')[1]
                                    : '00')
                              )
                          "
                        >
                          <option value="">시간</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                        </select>
                      </li>
                      <li class="text-sm font-medium text-neutral-500">:</li>
                      <li class="h-full grow">
                        <select
                          class="w-full h-full text-sm font-medium text-center text-neutral-500 pr-7 pc:pr-10 pc:text-lg"
                          :value="enrolment.detailOneAndOneParamsData.sugangHopeTime1.split(':')[1]"
                          @change="
                            (event) =>
                              enrolment.setDetailOneAndOneParamsData(
                                'sugangHopeTime1',
                                (enrolment.detailOneAndOneParamsData.sugangHopeTime1.split(':')[0]
                                  ? enrolment.detailOneAndOneParamsData.sugangHopeTime1.split(':')[0]
                                  : '06') +
                                  ':' +
                                  event.target.value
                              )
                          "
                        >
                          <option value="">분</option>
                          <option value="00">00</option>
                          <option value="30">30</option>
                        </select>
                      </li>
                    </ul>
                  </li>
                  <li class="flex flex-col gap-2.5 pc:gap-4 pc:flex-row">
                    <p
                      class="py-4 text-sm font-bold text-center text-orange-500 border border-solid rounded-sm pc:py-5 pc:px-9 pc:rounded pc:text-lg"
                    >
                      희망 시간 2
                    </p>
                    <ul class="flex items-center gap-1 p-1.5 rounded-sm pc:rounded pc:gap-2 pc:p-2.5 bg-neutral-100 grow">
                      <li class="h-full grow">
                        <select
                          class="w-full h-full text-sm font-medium text-center text-neutral-500 pr-7 pc:pr-10 pc:text-lg"
                          :value="enrolment.detailOneAndOneParamsData.sugangHopeTime2.split(':')[0]"
                          @change="
                            (event) =>
                              enrolment.setDetailOneAndOneParamsData(
                                'sugangHopeTime2',
                                event.target.value +
                                  ':' +
                                  (enrolment.detailOneAndOneParamsData.sugangHopeTime2.split(':')[1]
                                    ? enrolment.detailOneAndOneParamsData.sugangHopeTime2.split(':')[1]
                                    : '00')
                              )
                          "
                        >
                          <option value="">시간</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                        </select>
                      </li>
                      <li class="text-sm font-medium text-neutral-500">:</li>
                      <li class="h-full grow">
                        <select
                          class="w-full h-full text-sm font-medium text-center text-neutral-500 pr-7 pc:pr-10 pc:text-lg"
                          :value="enrolment.detailOneAndOneParamsData.sugangHopeTime2.split(':')[1]"
                          @change="
                            (event) =>
                              enrolment.setDetailOneAndOneParamsData(
                                'sugangHopeTime2',
                                (enrolment.detailOneAndOneParamsData.sugangHopeTime2.split(':')[0]
                                  ? enrolment.detailOneAndOneParamsData.sugangHopeTime2.split(':')[0]
                                  : '06') +
                                  ':' +
                                  event.target.value
                              )
                          "
                        >
                          <option value="">분</option>
                          <option value="00">00</option>
                          <option value="30">30</option>
                        </select>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p
                  v-if="enrolment.oneAndOneSugangData.hopeTimeText"
                  class="py-2.5 px-9 text-center text-lg text-rose-600 rounded-3xl shadow-md"
                >
                  {{ enrolment.oneAndOneSugangData.hopeTimeText }}
                </p>
              </li>

              <!-- 1:1 -->
              <li v-if="enrolment.commonParamsData.classType === 'one' && enrolment.oneAndOneSugangData.isLevelTest === 'Y'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">레벨테스트 신청</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4 mb-2.5 pc:mb-4">
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('isLevelTest', 'Y')"
                    :class="{
                      active:
                        enrolment.commonParamsData.classType === 'one' && enrolment.detailOneAndOneParamsData.isLevelTest === 'Y',
                    }"
                  >
                    신청함
                  </li>
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('isLevelTest', 'N')"
                    :class="{
                      active:
                        enrolment.commonParamsData.classType === 'one' && enrolment.detailOneAndOneParamsData.isLevelTest === 'N',
                    }"
                  >
                    신청 안함
                  </li>
                </ul>
                <p class="text-sm text-neutral-500 pc:text-lg">
                  * 신청인원이 모두 채워지지 않는 경우 폐강되며, 자동으로 신청 및 결제가 취소돼요.
                </p>
              </li>

              <!-- 1:1 -->
              <li v-if="enrolment.commonParamsData.classType === 'one' && enrolment.oneAndOneSugangData.isBookBuy === 'Y'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">교재 구입 여부</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('isBookBuy', 'Y')"
                    :class="{
                      active: enrolment.detailOneAndOneParamsData.isBookBuy === 'Y',
                    }"
                  >
                    교재 구입
                  </li>
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailOneAndOneParamsData('isBookBuy', 'N')"
                    :class="{
                      active: enrolment.detailOneAndOneParamsData.isBookBuy === 'N',
                    }"
                  >
                    교재 구입 안함
                  </li>
                </ul>
              </li>

              <!-- 그룹 -->
              <li
                v-if="
                  enrolment.sugangDefaultInfo.isGroup === 'Y' &&
                  enrolment.sugangDefaultInfo.isAdult !== 'Y' &&
                  enrolment.commonParamsData.classType === 'group'
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">그룹 선택</p>

                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                  <li
                    v-for="sugangGroupType in enrolment.groupSungangData.sugangGroupTypeList"
                    :key="sugangGroupType.idxLeSugangGroupType"
                    @click="enrolment.setDetailGroupParamsData('selectedGroupType', sugangGroupType)"
                    :class="{
                      active: sugangGroupType.groupType === enrolment.detailGroupParamsData.selectedGroupType.groupType,
                    }"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                  >
                    {{ sugangGroupType.groupType }}
                  </li>
                </ul>
              </li>

              <!-- 그룹 수업 횟수 선택-->
              <li
                v-if="
                  enrolment.detailGroupParamsData.selectedGroupType.idxLeSugangGroupType &&
                  enrolment.commonParamsData.classType === 'group'
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 횟수 선택</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                  <li
                    v-for="sugangGroupTimeInfo in enrolment.detailGroupParamsData.selectedGroupType.sugangGroupTimeInfoList"
                    :key="sugangGroupTimeInfo.idxLeSugangGroupTimeInfo"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('selectedGroupTimeInfo', sugangGroupTimeInfo)"
                    :class="{
                      active:
                        sugangGroupTimeInfo.idxLeSugangGroupTimeInfo ===
                        enrolment.detailGroupParamsData.selectedGroupTimeInfo.idxLeSugangGroupTimeInfo,
                    }"
                  >
                    주{{ sugangGroupTimeInfo.numberOfClass }}회 {{ sugangGroupTimeInfo.lessonTime }}분
                  </li>
                </ul>
                <p
                  v-if="enrolment.groupSungangData.numberOfClassText"
                  class="py-2.5 px-9 text-center text-lg text-rose-600 rounded-3xl shadow-md"
                >
                  {{ enrolment.groupSungangData.numberOfClassText }}
                </p>
              </li>

              <!-- 그룹 선택 언어 -->
              <li
                v-if="
                  enrolment.commonParamsData.classType === 'group' &&
                  enrolment.detailGroupParamsData.selectedGroupTimeInfo.idxLeSugangGroupTimeInfo
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">언어 선택</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4">
                  <li
                    v-for="sugangGroupLang in enrolment.detailGroupParamsData.selectedGroupTimeInfo.sugangGroupLangList"
                    :key="sugangGroupLang.idxLeSugangGroupLang"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('selectedSugangGroupLang', sugangGroupLang)"
                    :class="{
                      active:
                        enrolment.detailGroupParamsData.selectedSugangGroupLang.idxLeSugangGroupLang ===
                        sugangGroupLang.idxLeSugangGroupLang,
                    }"
                  >
                    {{ sugangGroupLang.language }}
                  </li>
                </ul>
              </li>

              <!-- 그룹 -->
              <li
                v-if="
                  enrolment.detailGroupParamsData.selectedSugangGroupLang.idxLeSugangGroupLang &&
                  enrolment.commonParamsData.classType === 'group'
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">레벨 선택</p>
                <ul class="grid grid-cols-4 gap-2.5 pc:gap-4 mb-2.5 pc:mb-4">
                  <li
                    v-for="sugangGroupLevel in enrolment.detailGroupParamsData.selectedSugangGroupLang.sugangGroupLevelList"
                    :key="sugangGroupLevel.idxLeSugangGroupLevel"
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('selectedSugangGroupLevel', sugangGroupLevel)"
                    :class="{
                      active:
                        sugangGroupLevel.idxLeSugangGroupLevel ===
                        enrolment.detailGroupParamsData.selectedSugangGroupLevel.idxLeSugangGroupLevel,
                    }"
                  >
                    {{ sugangGroupLevel.levelName }}
                  </li>
                </ul>
                <p
                  v-if="enrolment.groupSungangData.levelChoiceText"
                  class="py-2.5 px-9 text-center text-lg text-rose-600 rounded-3xl shadow-md"
                >
                  {{ enrolment.groupSungangData.levelChoiceText }}
                </p>
                <div class="flex justify-end pt-5">
                  <p
                    class="p-2 text-white bg-red-700 border border-solid rounded cursor-pointer"
                    @click="enrolment.setLevelCheck()"
                  >
                    레벨별 교재 확인
                  </p>
                </div>
              </li>

              <!-- 그룹 -->
              <li
                v-if="
                  enrolment.commonParamsData.classType === 'group' &&
                  enrolment.detailGroupParamsData.selectedSugangGroupLevel.idxLeSugangGroupLevel
                "
              >
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 시작 시간 선택</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4 mb-2.5 pc:mb-4">
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('timeRange', 'morning')"
                    :class="{
                      active: enrolment.detailGroupParamsData.timeRange === 'morning',
                    }"
                  >
                    오전
                  </li>
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('timeRange', 'afternoon')"
                    :class="{
                      active: enrolment.detailGroupParamsData.timeRange === 'afternoon',
                    }"
                  >
                    오후
                  </li>
                </ul>
                <ul>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 신청인원이 모두 채워지지 않는 경우 폐강되며, 자동으로 신청 및 결제가 취소돼요.
                  </li>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 원활한 교육을 위해 신청자 현황을 확인 후 신청해주세요. 이미 신청자가 있는 그룹에 신청하시면 폐강될 확률이
                    적어요!
                  </li>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 시작시간 +30분 안으로 수업이 배정되니 꼭 확인 후 신청해주세요. 그룹 배정 이후 시간 변경은 불가해요.
                  </li>
                </ul>
              </li>

              <!-- 그룹 -->
              <li v-if="enrolment.commonParamsData.classType === 'group' && enrolment.groupSungangData.isLevelTest === 'Y'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">레벨테스트 신청</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4 mb-2.5 pc:mb-4">
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('isLevelTest', 'Y')"
                    :class="{
                      active:
                        enrolment.commonParamsData.classType === 'group' && enrolment.detailGroupParamsData.isLevelTest === 'Y',
                    }"
                  >
                    신청함
                  </li>
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('isLevelTest', 'N')"
                    :class="{
                      active:
                        enrolment.commonParamsData.classType === 'group' && enrolment.detailGroupParamsData.isLevelTest === 'N',
                    }"
                  >
                    신청 안함
                  </li>
                </ul>
                <p class="text-sm text-neutral-500 pc:text-lg">
                  * 신청인원이 모두 채워지지 않는 경우 폐강되며, 자동으로 신청 및 결제가 취소돼요.
                </p>
              </li>

              <!-- 그룹 -->
              <li v-if="enrolment.commonParamsData.classType === 'group'">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">교재 구입 여부</p>
                <ul class="grid grid-cols-2 gap-2.5 pc:gap-4 mb-2.5">
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('isBookBuy', 'Y')"
                    :class="{
                      active: enrolment.detailGroupParamsData.isBookBuy === 'Y',
                    }"
                  >
                    교재 구입
                  </li>
                  <li
                    class="py-4 text-sm font-medium text-center border border-solid rounded-sm cursor-pointer text-neutral-500 bg-neutral-100 pc:rounded pc:py-6 pc:text-lg border-neutral-100"
                    @click="enrolment.setDetailGroupParamsData('isBookBuy', 'N')"
                    :class="{
                      active: enrolment.detailGroupParamsData.isBookBuy === 'N',
                    }"
                  >
                    교재 구입 안함
                  </li>
                </ul>
                <ul>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 교재비가 있는 과정도 E-book 교재를 무료 제공해요. 실물교재가 필요한 학습자만 구매해주세요.
                  </li>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 교재비가 0원인 과정은 실물교재가 없는 과정이며, E-book 교재를 제공해요.
                  </li>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 매거진 과정은 추후 레벨이 정해지면 개별 안내드리며, E-book 교재가 없어 실물교재 구매가 필수예요.
                  </li>
                  <li class="text-sm text-neutral-500 pc:text-lg">
                    * 구매하신 교재는, 교재가 확정되는 첫수업 후 일주일 內 일괄 배송드려요.
                  </li>
                </ul>
              </li>

              <!-- 모바일 -->
              <!-- 그룹 -->
              <li v-if="enrolment.commonParamsData.classType === 'group'" class="pc:hidden">
                <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 선택</p>
                <ul class="flex flex-col gap-7">
                  <li
                    class="border-t border-b border-solid border-neutral-950"
                    v-for="(mobileGroupSungangRoom, index) in enrolment.groupSungangRoomList"
                    :key="index"
                  >
                    <div class="flex items-center justify-between px-5 py-4 bg-neutral-50">
                      <div class="flex items-center gap-2">
                        <input
                          type="radio"
                          name="selectClass"
                          class="border border-solid border-neutral-200"
                          :disabled="mobileGroupSungangRoom.registerInfo === '신청마감'"
                          @click="console.log(enrolment.detailGroupParamsData.selectedGroupRoomParamsData)"
                          v-model="enrolment.detailGroupParamsData.selectedGroupRoomParamsData"
                          :value="mobileGroupSungangRoom"
                        />
                        <label
                          for=""
                          class="text-sm font-medium text-neutral-950"
                          :class="{
                            'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                          }"
                          >{{ mobileGroupSungangRoom.registerInfo }}</label
                        >
                      </div>
                      <ul class="flex gap-3 mobileGroupSungangRooms-center">
                        <li
                          class="text-sm text-neutral-950"
                          :class="{
                            'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                          }"
                        >
                          현황
                        </li>
                        <li
                          class="text-sm text-neutral-950"
                          :class="{
                            'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                          }"
                        ></li>
                        <li
                          class="text-sm text-neutral-950"
                          :class="{
                            'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                          }"
                        >
                          {{ mobileGroupSungangRoom.studentCount }}/{{ mobileGroupSungangRoom.studentLimitCount }}
                        </li>
                      </ul>
                    </div>
                    <div class="px-5 py-4">
                      <p
                        class="font-bold text-neutral-950 mb-2.5"
                        :class="{
                          'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                        }"
                      >
                        {{ mobileGroupSungangRoom.language }}
                        {{ mobileGroupSungangRoom.groupType }}
                        {{ mobileGroupSungangRoom.levelName }}
                      </p>
                      <p
                        class="text-sm font-medium text-neutral-500 mb-2.5"
                        :class="{
                          'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                        }"
                      >
                        {{ mobileGroupSungangRoom.classTimeinfo }} / {{ mobileGroupSungangRoom.timeRange }} /
                        {{ mobileGroupSungangRoom.time }} / {{ mobileGroupSungangRoom.className }} /
                        {{ mobileGroupSungangRoom.classMonth }}개월 / 교재비 {{ mobileGroupSungangRoom.bookPrice }}원 / 레벨테스트
                        {{ mobileGroupSungangRoom.isLevelTest === "Y" ? "신청함" : "신청안함" }}
                      </p>
                      <ul class="flex gap-2.5">
                        <li
                          :class="{
                            'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                          }"
                          class="text-sm font-medium text-orange-600 underline cursor-pointer"
                          @click="enrolment.openURL(mobileGroupSungangRoom.classInfo)"
                        >
                          수업안내
                        </li>
                        <li
                          :class="{
                            'text-disabled': mobileGroupSungangRoom.registerInfo === '신청마감',
                          }"
                          class="text-sm font-medium text-orange-600 underline cursor-pointer"
                          @click="enrolment.openURL(mobileGroupSungangRoom.bookInfo)"
                        >
                          교재소개
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li
                class="mb-5 text-lg font-bold pc:hidden text-neutral-950 pc:text-2xl pc:mb-4"
                v-if="enrolment.commonParamsData.classType === 'group'"
              >
                <p v-if="enrolment.groupSungangRoomList.length < 1">신청 가능한 클래스가 없습니다.</p>
              </li>
            </ul>
          </div>

          <!-- 선택 결과 -->
          <div class="pc:pt-8">
            <div class="flex-none overflow-hidden top-24 rounded-xl pc:rounded-2xl pc:sticky pc:w-[410px]">
              <div class="px-5 py-7 bg-neutral-700 flex flex-col gap-3.5 pc:gap-4 pc:px-7 pc:py-9">
                <p class="text-lg font-bold text-orange-500 pc:text-2xl">
                  {{ enrolment.sugangDefaultInfo.kname }}
                </p>
                <p class="text-sm font-bold text-white pc:text-lg">
                  {{ enrolment.sugangDefaultInfo.sugangName }}
                </p>
              </div>
              <ul
                class="grid grid-cols-1 gap-4 px-5 py-4 divide-y divide-solid pc:px-7 pc:py-5 bg-neutral-600 divide-neutral-700"
              >
                <!-- <li class="flex items-center justify-between">
                  <p class="text-sm pc:text-lg text-neutral-200">수업 스케줄</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.displayedScheduleUp }}
                  </p>
                </li> -->
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'group'">
                  <p class="text-sm pc:text-lg text-neutral-200">그룹</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailGroupParamsData.selectedGroupType.groupType }}
                  </p>
                </li>
                <li v-if="enrolment.commonParamsData.classType === 'one'" class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">수업 유형</p>
                  <p v-if="enrolment.detailOneAndOneParamsData.isMobilePc" class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailOneAndOneParamsData.isMobilePc === "mobile" ? "모바일 화상" : "PC 화상" }}
                  </p>
                </li>
                <li v-if="enrolment.commonParamsData.classType === 'one'" class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">언어</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailOneAndOneParamsData.selectedLanguageInfo?.language }}
                  </p>
                </li>

                <li v-if="enrolment.commonParamsData.classType === 'one'" class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">수업</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailOneAndOneParamsData.selectedClassInfo?.className }}
                  </p>
                </li>
                <li v-if="enrolment.commonParamsData.classType === 'one'" class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">레벨</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailOneAndOneParamsData.selectedLevelInfo?.levelName }}
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">수업 횟수</p>
                  <p v-if="enrolment.findOneTimeInfo() !== undefined" class="text-sm font-bold text-white pc:text-lg">
                    {{ "주 " + enrolment.findOneTimeInfo().numberOfClass + "회 " }}
                    {{ enrolment.findOneTimeInfo().lessonTime + "분" }}
                  </p>
                </li>
                <li v-if="enrolment.commonParamsData.classType === 'group'" class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">언어</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailGroupParamsData.selectedSugangGroupLang.language }}
                  </p>
                </li>

                <li v-if="enrolment.commonParamsData.classType === 'group'" class="flex items-center justify-between pt-4">
                  <p class="text-sm pc:text-lg text-neutral-200">레벨</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailGroupParamsData.selectedSugangGroupLevel.levelName }}
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'group'">
                  <p class="text-sm pc:text-lg text-neutral-200">수업 시작 시간</p>
                  <p
                    v-if="enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.timeRange"
                    class="text-sm font-bold text-white pc:text-lg"
                  >
                    {{
                      enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.timeRange +
                      " " +
                      enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.time
                    }}
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'one'">
                  <p class="text-sm pc:text-lg text-neutral-200">희망 수업시간 1</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailOneAndOneParamsData.sugangHopeTime1 }}
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'one'">
                  <p class="text-sm pc:text-lg text-neutral-200">희망 수업시간 2</p>
                  <p class="text-sm font-bold text-white pc:text-lg">
                    {{ enrolment.detailOneAndOneParamsData.sugangHopeTime2 }}
                  </p>
                </li>
              </ul>
              <p class="px-5 py-4 text-sm font-bold text-white bg-neutral-700 pc:px-7 pc:py-5 pc:text-lg">총 결제예정 금액</p>
              <ul
                class="grid grid-cols-1 gap-4 px-5 py-4 divide-y divide-solid pc:px-7 pc:py-5 bg-neutral-600 divide-neutral-700"
              >
                <li class="flex items-center justify-between" v-if="enrolment.commonParamsData.classType === 'one'">
                  <p class="text-sm pc:text-lg text-neutral-200">교육비</p>
                  <p
                    v-if="enrolment.detailOneAndOneParamsData.selectedClassInfo?.classPrice !== undefined"
                    class="text-sm font-bold text-white pc:text-lg"
                  >
                    {{ enrolment.detailOneAndOneParamsData.selectedClassInfo?.classPrice }}원
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'one'">
                  <p class="text-sm pc:text-lg text-neutral-200">교재비</p>
                  <p
                    v-if="enrolment.detailOneAndOneParamsData.selectedClassInfo?.bookPrice !== undefined"
                    class="text-sm font-bold text-white pc:text-lg"
                  >
                    {{
                      enrolment.detailOneAndOneParamsData.isBookBuy === "N"
                        ? 0
                        : enrolment.detailOneAndOneParamsData.selectedClassInfo?.bookPrice
                    }}원
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'group'">
                  <p class="text-sm pc:text-lg text-neutral-200">교육비</p>
                  <p
                    v-if="enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.classPrice !== undefined"
                    class="text-sm font-bold text-white pc:text-lg"
                  >
                    {{ enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.classPrice }}원
                  </p>
                </li>
                <li class="flex items-center justify-between pt-4" v-if="enrolment.commonParamsData.classType === 'group'">
                  <p class="text-sm pc:text-lg text-neutral-200">교재비</p>
                  <p
                    v-if="enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.bookPrice !== undefined"
                    class="text-sm font-bold text-white pc:text-lg"
                  >
                    {{
                      enrolment.detailGroupParamsData.isBookBuy === "N"
                        ? 0
                        : enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.bookPrice
                    }}원
                  </p>
                </li>
              </ul>
              <!-- 1:1 -->
              <div
                v-if="enrolment.commonParamsData.classType === 'one'"
                class="flex items-center justify-between px-5 py-7 bg-neutral-700 pc:px-7 pc:py-9"
              >
                <p class="text-sm pc:text-lg text-neutral-200">총 결제 금액</p>
                <p v-if="enrolment.findOneTimeInfo() !== undefined" class="text-sm font-bold text-white pc:text-lg">
                  {{
                    (enrolment.detailOneAndOneParamsData.isBookBuy === "N"
                      ? 0
                      : enrolment.detailOneAndOneParamsData.selectedClassInfo?.bookPrice) +
                    enrolment.detailOneAndOneParamsData.selectedClassInfo
                      ? enrolment.detailOneAndOneParamsData.selectedClassInfo.classPrice
                      : 0
                  }}
                  원
                </p>
              </div>
              <!-- 그룹 -->
              <div
                v-if="enrolment.commonParamsData.classType === 'group'"
                class="flex items-center justify-between px-5 py-7 bg-neutral-700 pc:px-7 pc:py-9"
              >
                <p class="text-sm pc:text-lg text-neutral-200">총 결제 금액</p>
                <p
                  v-if="enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.bookPrice !== undefined"
                  class="text-sm font-bold text-white pc:text-lg"
                >
                  {{
                    (enrolment.detailGroupParamsData.isBookBuy === "N"
                      ? 0
                      : enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.bookPrice) +
                    enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.classPrice
                  }}원
                </p>
              </div>
              <!-- 1:1 -->
              <p
                v-if="enrolment.commonParamsData.classType === 'one'"
                class="py-6 text-xl font-bold text-center text-white cursor-pointer bg-violet-500 pc:text-2xl pc:py-8"
                @click="enrolment.registerOneAndOneData()"
              >
                수강신청 하기
              </p>

              <!-- 그룹 -->
              <p
                v-if="enrolment.commonParamsData.classType === 'group'"
                class="py-6 text-xl font-bold text-center text-white cursor-pointer bg-violet-500 pc:text-2xl pc:py-8"
                @click="enrolment.registerGroupData()"
              >
                수강신청 하기
              </p>
            </div>
          </div>
        </div>

        <!-- 그룹 -->
        <div v-if="enrolment.commonParamsData.classType === 'group'" class="hidden pc:block">
          <p class="mb-5 text-lg font-bold text-neutral-950 pc:text-2xl pc:mb-4">수업 선택</p>
          <table class="border-t border-b border-solid border-neutral-950">
            <thead class="bg-neutral-50">
              <tr>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">상태</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">그룹</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">현황</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">언어</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">횟수</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">시간대</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">시작시간</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">레벨</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">수업</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">개월</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">수업안내</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">교재소개</th>
                <th class="p-3 text-lg border-r border-solid border-neutral-200">교재비</th>
                <!-- <th class="p-3 text-lg">레벨테스트</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="groupSungangRoom in enrolment.groupSungangRoomList" :key="groupSungangRoom.idxLeSugangGroupClassRoom">
                <td class="p-3 border-r border-solid border-neutral-200">
                  <div
                    @click="
                      groupSungangRoom.registerInfo === '신청마감'
                        ? () => {}
                        : (enrolment.detailGroupParamsData.selectedGroupRoomParamsData = groupSungangRoom)
                    "
                    class="flex items-center justify-center gap-4 cursor-pointer"
                  >
                    <input
                      :checked="enrolment.detailGroupParamsData.selectedGroupRoomParamsData === groupSungangRoom"
                      type="radio"
                      name="selectClassPC"
                      class="border border-solid border-neutral-200"
                      :disabled="groupSungangRoom.registerInfo === '신청마감'"
                    />
                    <p
                      class="font-medium text-neutral-950"
                      :class="{
                        'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                      }"
                    >
                      {{ groupSungangRoom.registerInfo }}
                    </p>
                  </div>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.groupType }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.studentCount }}/{{ groupSungangRoom.studentLimitCount }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.language }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.classTimeinfo }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.timeRange }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.time }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.levelName }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.className }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.classMonth }}개월
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-orange-500 underline cursor-pointer"
                    @click="enrolment.openURL(groupSungangRoom.classInfo)"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.classInfo ? "확인" : "-" }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-orange-500 underline cursor-pointer"
                    @click="enrolment.openURL(groupSungangRoom.bookInfo)"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.bookInfo ? "확인" : "-" }}
                  </p>
                </td>
                <td class="p-3 border-r border-solid border-neutral-200">
                  <p
                    class="font-medium text-neutral-950"
                    :class="{
                      'text-disabled': groupSungangRoom.registerInfo === '신청마감',
                    }"
                  >
                    {{ groupSungangRoom.bookPrice === 0 ? "교재비 없음" : groupSungangRoom.bookPrice + "원" }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="hidden mb-5 text-lg font-bold text-center pc:block text-neutral-950 pc:text-2xl pc:mb-4"
          v-if="enrolment.commonParamsData.classType === 'group'"
        >
          <p v-if="enrolment.groupSungangRoomList.length < 1">신청 가능한 클래스가 없습니다.</p>
        </div>

        <!-- 레벨별 교재 확인 팝업 -->
        <div v-if="enrolment.isShowLevelPop">
          <div class="fixed inset-0 w-full h-full bg-black/80 z-[10] overflow-y-auto">
            <div class="flex flex-col justify-center items-center p-5 w-[900px] mx-auto">
              <div class="flex justify-end w-full p-2 bg-white fl">
                <p class="text-xl font-bold underline cursor-pointer" @click="enrolment.setCloseLevelCheck()">닫기</p>
              </div>
              <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/curriculum.jpg" />
            </div>
          </div>
        </div>
      </div>

      <!-- 신청하기 모달 -->
      <div v-if="enrolment.isShowWaitModal" class="fixed inset-0 flex items-center justify-center bg-black/50">
        <div class="p-5 bg-white rounded-2xl">
          <p class="mb-5 text-2xl font-bold">
            현재 수강신청이 마감되어 대기 신청만 가능합니다.<br />
            대기 신청은 결제가 진행되지 않으며<br />
            취소자 발생으로 인해 신청이 확정되면<br />
            순차적으로 개별 결제 안내 드립니다.<br />
            대기 신청을 하시겠습니까?
          </p>
          <ul class="flex justify-end gap-2">
            <li class="px-3 py-2 bg-blue-300 cursor-pointer rounded-2xl" @click="enrolment.waitApply()">신청하기</li>
            <li class="px-3 py-2 bg-red-300 cursor-pointer rounded-2xl" @click="enrolment.waitClose()">닫기</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  props: [],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain; // store 값에 저장된 idxLeSiteMain 값

    const enrolment = reactive({
      // 레벨 모달 보여주는 변수
      isShowLevelPop: false,

      // 대기 팝업 보여주는 변수
      isShowWaitModal: false,

      isLoading: false,

      // 동의사항 체크 변수
      isShowSugang: false,

      // 최초 schedule data
      sugangDefaultInfo: {
        isOne: "",
        kname: "",
        groupSupButtonName: "",
        supButtonName: "",
        normalButtonName: "",
        idxSugang: -1,
        isOneSup: "",
        idxCompany: -1,
        oneSupButtonName: "",
        oneButtonName: "",
        groupButtonName: "",
        mainContent: "",
        isGroup: "",
        isGroupSup: "",
        isAdult: "",
        sugangName: "",
      },

      // 1:1 수업 data
      oneAndOneSugangData: {},

      // group 수업 data
      groupSungangData: {},

      // group 수강에서 가져오는 table list
      groupSungangRoomList: [],

      // 1:1 수업 등록시 쓰는 데이터 정보
      detailOneAndOneParamsData: {
        selectedLanguageInfo: {},
        selectedClassInfo: {},
        selectedLevelInfo: {},
        idxLeSugangOneLevel: "",
        idxLeSugangOneTimeInfo: "",
        isBookBuy: "N",
        isLevelTest: "N",
        isMobilePc: "",
        sugangHopeTime1: "",
        sugangHopeTime2: "",
        sugangSupType: "",
      },

      // 그룹 수업 등록시 쓰는 데이터 정보
      detailGroupParamsData: {
        selectedGroupType: {},
        selectedGroupTimeInfo: {},
        selectedSugangGroupLang: {},
        selectedSugangGroupLevel: {},
        timeRange: "",
        isLevelTest: "N",
        isBookBuy: "N",
        selectedGroupRoomParamsData: {},
      },

      // 최초 API 호출을 위한 Data
      commonParamsData: {
        idxLeSugang: -1,
        classType: "",
        adult: "",
        sugangSupType: "",
      },

      // 동의사항 여부 체크후 수강신청 버튼 보이는 함수
      sugangEnrolment() {
        enrolment.isShowSugang = !enrolment.isShowSugang;
      },

      // 상단에 주니어, 성인 / 1:1 , 그룹 / 지원, 일반 클릭 시 data 초기화 함수
      clearData() {
        enrolment.detailOneAndOneParamsData = {
          selectedLanguageInfo: {},
          selectedClassInfo: {},
          selectedLevelInfo: {},
          idxLeSugangOneLevel: "",
          idxLeSugangOneTimeInfo: "",
          isBookBuy: "N",
          isLevelTest: "N",
          isMobilePc: "",
          sugangHopeTime1: "",
          sugangHopeTime2: "",
          sugangSupType: "",
        };
        enrolment.detailGroupParamsData = {
          selectedGroupType: {},
          selectedGroupTimeInfo: {},
          selectedSugangGroupLang: {},
          selectedSugangGroupLevel: {},
          timeRange: "",
          isLevelTest: "N",
          isBookBuy: "N",
        };
        enrolment.oneAndOneSugangData = {};
        enrolment.groupSungangData = {};
        enrolment.groupSungangRoomList = [];
      },

      // 사이드 바에서 중복 내용 timeInfo 보여 줄 때 사용
      findOneTimeInfo() {
        let data;
        if (enrolment.commonParamsData.classType === "group") {
          data = enrolment.detailGroupParamsData.selectedGroupTimeInfo.idxLeSugangGroupTimeInfo
            ? enrolment.detailGroupParamsData.selectedGroupTimeInfo
            : undefined;
        } else {
          data = enrolment.oneAndOneSugangData?.sugangOneTimeInfoList?.find(
            (item) => item.idxLeSugangOneTimeInfo === enrolment.detailOneAndOneParamsData.idxLeSugangOneTimeInfo
          );
        }

        if (data !== undefined) return data;
        else return undefined;
      },

      // 키 설정 및 함수 호출
      setDefaultData(key, value) {
        enrolment.commonParamsData[key] = value;
        enrolment.getDetailData();
      },

      // 그룹 수업 정보 세팅 함수
      async setDetailGroupParamsData(key, value) {
        enrolment.detailGroupParamsData[key] = value;

        // 그룹 변경 시에 나머지 초기화
        if (key === "selectedGroupType") {
          enrolment.detailGroupParamsData = {
            ...enrolment.detailGroupParamsData,
            selectedGroupTimeInfo: {},
            selectedSugangGroupLang: {},
            selectedSugangGroupLevel: {},
            timeRange: "",
            selectedGroupRoomParamsData: {},
          };
        }

        // 수업 횟수 선택 시에 나머지 초기화
        if (key === "selectedGroupTimeInfo") {
          enrolment.detailGroupParamsData = {
            ...enrolment.detailGroupParamsData,
            selectedSugangGroupLang: {},
            selectedSugangGroupLevel: {},
            timeRange: "",
            selectedGroupRoomParamsData: {},
          };
        }

        // 언어 선택 시에 나머지 초기화
        if (key === "selectedSugangGroupLang") {
          enrolment.detailGroupParamsData = {
            ...enrolment.detailGroupParamsData,
            selectedSugangGroupLevel: {},
            timeRange: "",
            selectedGroupRoomParamsData: {},
          };
        }

        // 레벨 선택 시에 나머지 초기화
        if (key === "selectedSugangGroupLevel") {
          enrolment.detailGroupParamsData = {
            ...enrolment.detailGroupParamsData,
            timeRange: "",
            selectedGroupRoomParamsData: {},
          };
          enrolment.groupSungangRoomList = [];
        }

        // 시간 오전 | 오후 눌렀을 때 class Room 가져오기
        if (key === "timeRange") {
          const result = await axios.get("/user/sugang/class/groupClassRoom", {
            params: {
              idxLeSugang: enrolment.commonParamsData.idxLeSugang,
              idxLeSugangGroupLevel: enrolment.detailGroupParamsData.selectedSugangGroupLevel.idxLeSugangGroupLevel,
              timeRange: enrolment.detailGroupParamsData.timeRange,
            },
          });

          const { data, message, success } = result.data;

          if (success) {
            // 수강 신청 마감 설정된 방은 제외
            const filteringNotEnrolmentCloseManualList = data.filter((room) => room.isEnrollmentClosedManual !== "Y");

            enrolment.groupSungangRoomList = filteringNotEnrolmentCloseManualList;
          } else {
            enrolment.groupSungangRoomList = [];
            enrolment.detailGroupParamsData.selectedGroupRoomParamsData = {};
            alert(message);
          }
        }
      },

      // 1:1 화면 클릭 시 event
      setDetailOneAndOneParamsData(key, value) {
        // 같은 value 면 입력 값 변경 X
        if (enrolment.detailOneAndOneParamsData[key] === value) {
          return;
        }

        enrolment.detailOneAndOneParamsData[key] = value;

        // 언어가 변경 되었을 때
        if (key === "selectedLanguageInfo") {
          enrolment.detailOneAndOneParamsData = {
            ...enrolment.detailOneAndOneParamsData,
            selectedClassInfo: {},
            selectedLevelInfo: {},
            idxLeSugangOneLevel: "",
          };
        }

        // 수업이 변경되었을 때
        if (key === "selectedClassInfo") {
          enrolment.detailOneAndOneParamsData = {
            ...enrolment.detailOneAndOneParamsData,
            selectedLevelInfo: {},
            idxLeSugangOneLevel: "",
          };
        }
      },

      // 그룹 및 1:1 을 눌렀을 때 자식 보여주기
      async getDetailData() {
        // 만약 한개라도 하지 않았다면, 실행 되지 않음
        // 일반 | 지원 // 1:1 수업 | 그룹수업 // 주니어 | 성인
        if (
          !enrolment.commonParamsData.classType ||
          !enrolment.commonParamsData.adult ||
          !enrolment.commonParamsData.sugangSupType
        ) {
          return;
        }

        // 데이터 초기화
        enrolment.clearData();

        // data 가져오기
        enrolment.isLoading = true;
        const result = await axios.get(`/user/sugang/class/detail`, { params: enrolment.commonParamsData });
        const { data, message, success } = result.data;
        if (success) {
          if (enrolment.commonParamsData.classType === "one") {
            enrolment.oneAndOneSugangData = data;
          } else {
            enrolment.groupSungangData = data;
          }
        } else {
          alert(message);
        }
        enrolment.isLoading = false;
      },
      // 그룹 등록을 위한 유효성 검사 및 API 호출
      async registerGroupData() {
        if (!enrolment.detailGroupParamsData.selectedGroupType.groupType) {
          alert("그룹을 선택해주세요.");
          return;
        }

        if (!enrolment.detailGroupParamsData.selectedGroupTimeInfo.idxLeSugangGroupTimeInfo) {
          alert("수업 횟수를 선택해주세요.");
          return;
        }

        if (!enrolment.detailGroupParamsData.selectedSugangGroupLang.idxLeSugangGroupLang) {
          alert("언어를 선택해주세요.");
          return;
        }

        if (!enrolment.detailGroupParamsData.selectedSugangGroupLevel.idxLeSugangGroupLevel) {
          alert("레벨을 선택해주세요.");
          return;
        }

        if (!enrolment.detailGroupParamsData.timeRange) {
          alert("수업 시작 시간을 선택해주세요.");
          return;
        }

        if (enrolment.groupSungangData.isLevelTest === "Y" && !enrolment.detailGroupParamsData.isLevelTest) {
          alert("레벨 테스트를 선택해주세요.");
          return;
        }

        if (!enrolment.detailGroupParamsData.selectedGroupRoomParamsData.idxLeSugangGroupClassRoom) {
          alert("수업을 선택해주세요.");
          return;
        }

        const waitResult = await enrolment.checkWaitData();

        if (!waitResult) {
          return;
        }

        await enrolment.postRegistingGroup();
      },

      // 그룹 등록 API
      async postRegistingGroup() {
        const params = {
          idxLeSugangGroupClassRoom: enrolment.detailGroupParamsData.selectedGroupRoomParamsData.idxLeSugangGroupClassRoom,
          isLevelTest: enrolment.detailGroupParamsData.isLevelTest,
          isBookBuy: enrolment.detailGroupParamsData.isBookBuy,
        };

        const result = await axios.post(`/user/sugang/class/registerGroup`, params);

        const { data, message, success } = result.data;

        if (success) {
          router.push({
            path: `/enrolment/checkDetail`,
            query: {
              idxLeSugangPayInfo: data.idxLeSugangPayInfo,
            },
          });
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        } else {
          alert(message);
        }
      },

      // 1:1 등록을 위한 유효성 검사 및 API 호출
      async registerOneAndOneData() {
        // 수강 레벨 및 지원 | 일반 타입 설정
        enrolment.detailOneAndOneParamsData.idxLeSugangOneLevel =
          enrolment.detailOneAndOneParamsData.selectedLevelInfo.idxLeSugangOneLevel;
        enrolment.detailOneAndOneParamsData.sugangSupType = enrolment.commonParamsData.sugangSupType;

        // 유효성 검사
        if (!enrolment.detailOneAndOneParamsData.isMobilePc) {
          alert("수업 유형을 선택해주세요.");
          return;
        }

        if (!enrolment.detailOneAndOneParamsData.selectedLanguageInfo.idxLeSugangOneLang) {
          alert("언어를 선택해주세요.");
          return;
        }
        if (!enrolment.detailOneAndOneParamsData.selectedClassInfo.idxLeSugangOneClass) {
          alert("수업을 선택해주세요.");
          return;
        }

        if (!enrolment.detailOneAndOneParamsData.idxLeSugangOneLevel) {
          alert("레벨을 선택해주세요.");
          return;
        }

        if (!enrolment.detailOneAndOneParamsData.idxLeSugangOneTimeInfo) {
          alert("수업 횟수를 선택해주세요.");
          return;
        }

        if (!enrolment.detailOneAndOneParamsData.sugangHopeTime1 || !enrolment.detailOneAndOneParamsData.sugangHopeTime2) {
          alert("희망 수업시간을 선택해주세요.");
          return;
        }

        if (enrolment.oneAndOneSugangData.isBookBuy === "Y" && !enrolment.detailOneAndOneParamsData.isBookBuy) {
          alert("교재 구입 여부를 선택해주세요.");
          return;
        }

        if (enrolment.oneAndOneSugangData.isLevelTest === "Y" && !enrolment.detailOneAndOneParamsData.isLevelTest) {
          alert("레벨테스트 신청을 선택해주세요.");
          return;
        }

        const waitResult = await enrolment.checkWaitData();

        if (!waitResult) {
          return;
        }
        await enrolment.postRegistingOneAndOne();
      },

      // 1:1 등록 API
      async postRegistingOneAndOne() {
        const result = await axios.post(`/user/sugang/class/registerOne`, enrolment.detailOneAndOneParamsData);

        const { data, success, message } = result.data;
        if (success) {
          router.push({
            path: `/enrolment/checkDetail`,
            query: {
              idxLeSugangPayInfo: data.idxLeSugangPayInfo,
            },
          });
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        } else {
          alert(message);
        }
      },

      // 대기 목록 확인 API
      async checkWaitData() {
        const params = {
          classType: enrolment.commonParamsData.classType,
          idxLeSiteMain: idxLeSiteMain,
          sugangSupType: enrolment.commonParamsData.sugangSupType,
          idxLeSugangGroupClassRoom: enrolment.detailGroupParamsData.selectedGroupRoomParamsData?.idxLeSugangGroupClassRoom,
        };

        const result = await axios.get("/user/sugang/class/waitCheck", { params });
        const { data, message, success } = result.data;
        if (success) {
          if (data.isWait === "Y") {
            enrolment.isShowWaitModal = true;
            return false;
          } else if (data.isWait === "F") {
            alert("1:1 수강 인원이 가득 찼습니다.");
            return false;
          } else if (data.isWait === "D") {
            alert("이미 등록된 수업이 있습니다. (중복신청 불가)");
            return false;
          } else {
            return true;
          }
        } else {
          alert(message);
          return false;
        }
      },

      // 팝업 오픈 URL
      openURL(url) {
        url && window.open(url.includes("https://") ? url : "https://" + url);
      },

      // 최초 스케쥴 가져오는 API
      getSchedule() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
        };
        axios.get(`/user/sugang/class/schedule`, { params }).then(function (res) {
          if (res.data.success) {
            enrolment.sugangDefaultInfo = res.data.data;
            enrolment.commonParamsData.idxLeSugang = res.data.data.idxSugang;
          } else {
            alert(res.data.message);
            if (res.data.code === 401) {
              router.push({
                path: "/mypage",
                query: {
                  idxLeSiteMain: idxLeSiteMain,
                },
              });
            }
          }
        });
      },

      // 대기 신청하기
      waitApply() {
        if (enrolment.commonParamsData.classType === "group") {
          enrolment.postRegistingGroup();
        } else {
          enrolment.postRegistingOneAndOne();
        }
      },

      // 대기 닫기
      waitClose() {
        router.push({
          path: "/home",
          query: {
            idxLeSiteMain: idxLeSiteMain,
          },
        });
      },

      setLevelCheck() {
        enrolment.isShowLevelPop = true;
      },
      setCloseLevelCheck() {
        enrolment.isShowLevelPop = false;
      },

      async getRegisterCheck() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
        };
        const result = await axios.get(`/user/sugang/class/registerCheck`, { params });
        const { success } = result.data;
        if (success) {
          router.push({
            path: `/enrolment/check`,
            query: {
              idxLeSiteMain: idxLeSiteMain,
            },
          });
        } else {
          const params = {
            idxLeSiteMain: idxLeSiteMain,
          };

          const result = await axios.get(`/user/sugang/class/last`, { params });

          const { success, data } = result.data;

          if (success) {
            const idxLeSugangPayInfo = data.userSugangPayInfo.idxLeSugangPayInfo;
            router.push({
              path: `/enrolment/classDetail`,
              query: {
                idxLeSugangPayInfo: idxLeSugangPayInfo,
              },
            });
          }
        }
      },
    });

    onMounted(() => {
      enrolment.getRegisterCheck();
      enrolment.getSchedule();
    });

    return {
      enrolment,
      idxLeSiteMain,
    };
  },
};
</script>
<style scoped>
.active {
  background-color: white !important;
  border: 1px solid #f97316;
  font-weight: bold !important;
  color: #f97316 !important;
}

.text-disabled {
  color: #e5e5e5;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
