export default class BaseClassListObject {
  constructor() {
    this.idxLeSugangGroupClass = 0;
    this.idxLeSugangGroupLevel = 0;
    this.className = "";
    this.classInfo = "";
    this.classPrice = 0;
    this.bookInfo = "";
    this.bookPrice = 0;
    this.staffIdx = 0;
    this.sugangGroupClassRoomList = new Array();
  }
}
