import axios from "axios";
import store from "@lifeeduAdmin/store";
// import { useStore } from 'vuex'
// const store = useStore();
// axios.defaults.timeout = 5000; // ms
// axios.defaults.retry = 4; // count
// axios.defaults.retryDelay = 1000; // ms

let targetUrl = "";
if (location.origin.includes("local") || location.origin.includes("stage")) {
  targetUrl = `https://stage-life-api.carrotenglish.net/`;
  // targetUrl = `http://192.168.100.62:8500/`;
} else if (location.origin.includes("life-admin")) {
  targetUrl = `https://api-life.carrotapply.com`;
}
const instance = axios.create({
  baseURL: targetUrl,
  timeout: 1000 * 60 * 20,
  withCredentials: false,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getters.getAccessToken;
    config.headers.accessToken = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.data.errorMessage !== undefined) {
      alert(response.data.errorMessage);
      // window.location.href = "https://carrotians.net";
      // console.error("interceptors.response Message: ", response.data);
    }
    return response;
  },
  function (error) {
    alert("연결이 끊어졌습니다. 인터넷을 확인해보시고, 다시 접속해주세요");
    // window.location.href = "carrotians.net";
    console.error("Network Error, Retry", error);
  }
);

export default instance;
