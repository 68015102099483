<template>
  <div>
    <!-- 메인 -->
    <section class="pb-10 pc-con-wrapper1 pt-14 pc:pt-24 pc:pb-5">
      <div class="pc:flex pc:flex-col pc:justify-between pc:gap-16">
        <div class="flex flex-col gap-9 pc:flex-row pc:justify-between">
          <div class="flex flex-col justify-between gap-9 pc:gap-[100px]">
            <div class="flex flex-col gap-6">
              <p class="text-lg pc:text-2xl text-neutral-950">
                현지 원어민 강사님과 실시간 수업,<br />4단계 완전학습으로 영어 실력을 한 단계 끌어올리세요!
              </p>
              <p class="text-[32px] font-bold pc:text-[70px] text-neutral-950 pc:leading-[85px]">
                {{ homeMainInfo.homeMainInfoDetail.homeMainDetailList.sugangName }}
              </p>
            </div>
            <ul v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.isButton == 'N'" class="flex flex-col gap-2.5 pc:hidden">
              <li
                class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
                @click="idxLeSiteMain == '52' ? homeMainInfo.openGlobalsugang() : homeMainInfo.goEnrolmentJunior()"
              >
                <div>
                  <p class="font-bold text-orange-600 pc:text-[18px]">Junior</p>
                  <p class="font-medium text-neutral-950 pc:text-[18px]">수강신청 바로가기</p>
                </div>
                <div>
                  <img src="@lifeeduWeb/assets/images/main/title/go-junior.png" alt="icon" class="w-8 pc:w-11" />
                </div>
              </li>
              <li
                v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'adult'"
                class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
                @click="homeMainInfo.goEnrolmentAdult()"
              >
                <div>
                  <p class="font-bold text-rose-600 pc:text-[18px]">성인(20세 이상)</p>
                  <p class="font-medium text-neutral-950 pc:text-[18px]">수강신청 바로가기</p>
                </div>
                <div>
                  <img src="@lifeeduWeb/assets/images/main/title/go-adult.png" alt="icon" class="w-8 pc:w-11" />
                </div>
              </li>
            </ul>
            <!-- 버튼 링크 기능 -->
            <div v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.isButton == 'Y'">
              <div class="flex gap-4 pb-2">
                <ul
                  v-if="homeMainInfo.homeMainInfoDetail.button1Link && homeMainInfo.homeMainInfoDetail.button1Name"
                  class="flex flex-col gap-2.5 pc:flex-row"
                >
                  <li
                    class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
                    @click="goToSugang(homeMainInfo.homeMainInfoDetail.button1Link)"
                  >
                    <div>
                      <p class="font-bold text-orange-600 pc:text-[18px]">{{ homeMainInfo.homeMainInfoDetail.button1Name }}</p>
                    </div>
                    <div>
                      <img src="@lifeeduWeb/assets/images/main/title/go-junior.png" alt="icon" class="w-8 pc:w-11" />
                    </div>
                  </li>
                </ul>

                <ul
                  v-if="homeMainInfo.homeMainInfoDetail.button2Link && homeMainInfo.homeMainInfoDetail.button2Name"
                  class="flex flex-col gap-2.5 pc:flex-row"
                >
                  <li
                    class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
                    @click="goToSugang(homeMainInfo.homeMainInfoDetail.button2Link)"
                  >
                    <div>
                      <p class="font-bold text-orange-600 pc:text-[18px]">{{ homeMainInfo.homeMainInfoDetail.button2Name }}</p>
                    </div>
                    <div>
                      <img src="@lifeeduWeb/assets/images/main/title/go-junior.png" alt="icon" class="w-8 pc:w-11" />
                    </div>
                  </li>
                </ul>
              </div>
              <div class="flex gap-4 pt-2">
                <ul
                  v-if="homeMainInfo.homeMainInfoDetail.button3Link && homeMainInfo.homeMainInfoDetail.button3Name"
                  class="flex flex-col gap-2.5 pc:flex-row"
                >
                  <li
                    class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
                    @click="goToSugang(homeMainInfo.homeMainInfoDetail.button3Link)"
                  >
                    <div>
                      <p class="font-bold text-orange-600 pc:text-[18px]">{{ homeMainInfo.homeMainInfoDetail.button3Name }}</p>
                    </div>
                    <div>
                      <img src="@lifeeduWeb/assets/images/main/title/go-junior.png" alt="icon" class="w-8 pc:w-11" />
                    </div>
                  </li>
                </ul>

                <ul
                  v-if="homeMainInfo.homeMainInfoDetail.button4Link && homeMainInfo.homeMainInfoDetail.button4Name"
                  class="flex flex-col gap-2.5 pc:flex-row"
                >
                  <li
                    class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
                    @click="goToSugang(homeMainInfo.homeMainInfoDetail.button4Link)"
                  >
                    <div>
                      <p class="font-bold text-orange-600 pc:text-[18px]">{{ homeMainInfo.homeMainInfoDetail.button4Name }}</p>
                    </div>
                    <div>
                      <img src="@lifeeduWeb/assets/images/main/title/go-junior.png" alt="icon" class="w-8 pc:w-11" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-none pc:max-w-[500px]">
            <img
              v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainImg"
              :src="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainImg"
              alt="icon"
            />
          </div>
        </div>

        <div class="items-center justify-between hidden pc:flex">
          <ul
            v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.isButton == 'N'"
            class="w-full flex flex-col gap-2.5 pc:flex-row"
          >
            <li
              class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
              @click="idxLeSiteMain == '52' ? homeMainInfo.openGlobalsugang() : homeMainInfo.goEnrolmentJunior()"
            >
              <div>
                <p class="font-bold text-orange-600 pc:text-xl">Junior</p>
                <p class="font-medium text-neutral-950 pc:text-xl">수강신청 바로가기</p>
              </div>
              <div>
                <img src="@lifeeduWeb/assets/images/main/title/go-junior.png" alt="icon" class="w-8 pc:w-11" />
              </div>
            </li>
            <li
              v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'adult'"
              class="flex items-center justify-between p-5 cursor-pointer pc:px-7 bg-neutral-100 rounded-xl pc:rounded-2xl pc:gap-10"
              @click="homeMainInfo.goEnrolmentAdult()"
            >
              <div>
                <p class="font-bold text-rose-600 pc:text-xl">성인(20세 이상)</p>
                <p class="font-medium text-neutral-950 pc:text-xl">수강신청 바로가기</p>
              </div>
              <div>
                <img src="@lifeeduWeb/assets/images/main/title/go-adult.png" alt="icon" class="w-8 pc:w-11" />
              </div>
            </li>
          </ul>

          <ul class="w-full flex justify-end gap-2.5">
            <li
              v-if="idxLeSiteMain == '52'"
              class="w-full max-w-[260px] flex justify-between items-center gap-3 p-5 bg-white border border-solid border-[#E5E5E5] cursor-pointer rounded-2xl"
              @click="goPage('/learningSupport/noticeList')"
            >
              <div class="flex flex-col">
                <p class="flex-none text-xl font-bold text-neutral-950">공지사항</p>
                <div class="flex items-center gap-2.5">
                  <p class="text-base font-bold text-neutral-500">바로가기</p>
                  <img src="@lifeeduWeb/assets/images/main/help/arrow.png" alt="바로가기" class="w-min" />
                </div>
              </div>
              <div class="flex-none">
                <img src="@lifeeduWeb/assets/images/main/help/notice.png" alt="notice" class="w-12" />
              </div>
            </li>
            <li
              class="w-full max-w-[260px] flex justify-between items-center gap-3 p-5 bg-white border border-solid border-[#E5E5E5] cursor-pointer rounded-2xl"
              @click="goSite('https://zoom.us/test')"
            >
              <div class="flex flex-col">
                <p class="flex-none text-xl font-bold text-neutral-950">Zoom 테스트</p>
                <div class="flex items-center gap-2.5">
                  <p class="text-base font-bold text-neutral-500">바로가기</p>
                  <img src="@lifeeduWeb/assets/images/main/help/arrow.png" alt="바로가기" class="w-min" />
                </div>
              </div>
              <div class="flex-none">
                <img src="@lifeeduWeb/assets/images/main/help/zoom.png" alt="zoom" class="w-12" />
              </div>
            </li>
            <li
              v-if="idxLeSiteMain != '52'"
              class="w-full flex justify-between items-center gap-3 px-5 py-4 bg-white border border-solid border-[#E5E5E5] cursor-pointer rounded-2xl"
              @click="goSite('https://pp7lz.channel.io/home')"
            >
              <div class="flex flex-col gap-2.5">
                <p class="flex-none text-sm font-bold text-neutral-950">1:1 질문방</p>
                <div class="flex items-center gap-2.5">
                  <p class="text-xs font-bold text-neutral-500">바로가기</p>
                  <img src="@lifeeduWeb/assets/images/main/help/arrow.png" alt="바로가기" class="w-min" />
                </div>
              </div>
              <div class="flex-none">
                <img src="@lifeeduWeb/assets/images/main/help/question.png" alt="question" class="w-8" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="pc-con-wrapper1 pb-[18px] pc:hidden">
      <ul class="flex justify-end gap-2.5">
        <li
          v-if="idxLeSiteMain == '52'"
          class="w-full flex justify-between items-center gap-3 px-5 py-4 bg-white border border-solid border-[#E5E5E5] cursor-pointer rounded-2xl"
          @click="goPage('/learningSupport/noticeList')"
        >
          <div class="flex flex-col gap-2.5">
            <p class="flex-none text-sm font-bold text-neutral-950">공지사항</p>
            <div class="flex items-center gap-2.5">
              <p class="text-xs font-bold text-neutral-500">바로가기</p>
              <img src="@lifeeduWeb/assets/images/main/help/arrow.png" alt="바로가기" class="w-min" />
            </div>
          </div>
          <div class="flex-none">
            <img src="@lifeeduWeb/assets/images/main/help/notice.png" alt="notice" class="w-8" />
          </div>
        </li>
        <li
          class="w-full flex justify-between items-center gap-3 px-5 py-4 bg-white border border-solid border-[#E5E5E5] cursor-pointer rounded-2xl"
          @click="goSite('https://zoom.us/test')"
        >
          <div class="flex flex-col gap-2.5">
            <p class="flex-none text-sm font-bold text-neutral-950">Zoom 테스트</p>
            <div class="flex items-center gap-2.5">
              <p class="text-xs font-bold text-neutral-500">바로가기</p>
              <img src="@lifeeduWeb/assets/images/main/help/arrow.png" alt="바로가기" class="w-min" />
            </div>
          </div>
          <div class="flex-none">
            <img src="@lifeeduWeb/assets/images/main/help/zoom.png" alt="zoom" class="w-8" />
          </div>
        </li>
        <li
          v-if="idxLeSiteMain != '52'"
          class="w-full flex justify-between items-center gap-3 px-5 py-4 bg-white border border-solid border-[#E5E5E5] cursor-pointer rounded-2xl"
          @click="goSite('https://pp7lz.channel.io/home')"
        >
          <div class="flex flex-col gap-2.5">
            <p class="flex-none text-sm font-bold text-neutral-950">1:1 질문방</p>
            <div class="flex items-center gap-2.5">
              <p class="text-xs font-bold text-neutral-500">바로가기</p>
              <img src="@lifeeduWeb/assets/images/main/help/arrow.png" alt="바로가기" class="w-min" />
            </div>
          </div>
          <div class="flex-none">
            <img src="@lifeeduWeb/assets/images/main/help/question.png" alt="question" class="w-8" />
          </div>
        </li>
      </ul>
    </section>

    <!-- 바로가기 -->
    <section :class="{ 'pb-10': idxLeSiteMain == '52' }" class="pc-con-wrapper1">
      <div class="overflow-x-auto border border-solid border-neutral-200 rounded-xl pc:rounded-2xl">
        <ul
          :class="{ 'pc:grid-cols-6': idxLeSiteMain != '52', 'pc:grid-cols-3': idxLeSiteMain == '52' }"
          class="grid grid-cols-3 gap-y-6 py-3.5 pc:py-5 pc:gap-0"
        >
          <li
            class="flex flex-col items-center flex-none gap-1 px-5 cursor-pointer pc:gap-4 pc:px-6 pc:py-3"
            @click="idxLeSiteMain == '52' ? homeMainInfo.opencheckGlobalsugang() : homeMainInfo.goClassList()"
          >
            <div>
              <img
                src="@lifeeduWeb/assets/images/main/pride/quick0.png"
                alt="수강신청 내역확인"
                class="w-[46px] h-[46px] pc:w-16 pc:h-16"
              />
            </div>
            <p class="text-sm font-bold text-center pc:text-lg text-neutral-950">수강신청 내역확인</p>
          </li>
          <li
            class="flex flex-col items-center flex-none gap-1 px-5 border-l border-r border-solid cursor-pointer pc:gap-4 pc:px-6 border-neutral-200 pc:py-3"
            @click="homeMainInfo.goMyClass('/myClass')"
          >
            <div>
              <img
                src="@lifeeduWeb/assets/images/main/pride/quick1.png"
                alt="나의 학습방"
                class="w-[46px] h-[46px] pc:w-16 pc:h-16"
              />
            </div>
            <p class="text-sm font-bold text-center pc:text-lg text-neutral-950">나의 학습방</p>
          </li>
          <li
            class="flex flex-col items-center flex-none gap-1 px-5 cursor-pointer pc:gap-4 pc:px-6 pc:border-r pc:border-solid pc:border-neutral-200 pc:py-3"
            @click="goPage('/learningSupport/faq')"
          >
            <div>
              <img src="@lifeeduWeb/assets/images/main/pride/quick2.png" alt="FAQ" class="w-[46px] h-[46px] pc:w-16 pc:h-16" />
            </div>
            <p class="text-sm font-bold text-center pc:text-lg text-neutral-950">FAQ</p>
          </li>
          <li
            v-if="idxLeSiteMain != '52'"
            class="flex flex-col items-center flex-none gap-1 px-5 cursor-pointer pc:gap-4 pc:px-6 pc:py-3"
            @click="goSmooth('all-content')"
          >
            <div>
              <img
                src="@lifeeduWeb/assets/images/main/pride/quick3.png"
                alt="화상영어 소개"
                class="w-[46px] h-[46px] pc:w-16 pc:h-16"
              />
            </div>
            <p class="text-sm font-bold text-center pc:text-lg text-neutral-950">화상영어 소개</p>
          </li>
          <li
            v-if="idxLeSiteMain != '52'"
            class="flex flex-col items-center flex-none gap-1 px-5 border-l border-r border-solid cursor-pointer pc:gap-4 pc:px-6 pc:py-3 border-neutral-200"
            @click="goSite('https://www.carrotenglish.com/')"
          >
            <div>
              <img
                src="@lifeeduWeb/assets/images/main/pride/quick4.png"
                alt="당근영어 성인"
                class="w-[46px] h-[46px] pc:w-16 pc:h-16"
              />
            </div>
            <p class="text-sm font-bold text-center pc:text-lg text-neutral-950">당근영어 성인</p>
          </li>
          <li
            v-if="idxLeSiteMain != '52'"
            class="flex flex-col items-center flex-none gap-1 px-5 cursor-pointer pc:gap-4 pc:px-6 pc:py-3"
            @click="goSite('https://carrotjunior.kr/')"
          >
            <div>
              <img
                src="@lifeeduWeb/assets/images/main/pride/quick5.png"
                alt="당근영어 주니어"
                class="w-[46px] h-[46px] pc:w-16 pc:h-16"
              />
            </div>
            <p class="text-sm font-bold text-center pc:text-lg text-neutral-950">당근영어 주니어</p>
          </li>
        </ul>
      </div>
    </section>

    <!-- 성인, 주니어에 따라 컨텐츠 달라지는 영역 -->
    <section v-if="idxLeSiteMain != '52'" id="all-content">
      <!-- 당근영어 주니어로 커지는 - 주니어일 때 노출 -->
      <section
        class="py-24 pc-con-wrapper1 pc:py-32"
        v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'junior'"
      >
        <div v-if="idxLeSiteMain != '52'" class="flex justify-center mb-10 pc:mb-16">
          <div class="flex flex-col gap-5 pc:gap-10">
            <p class="p-3 text-xl font-medium text-center text-white bg-orange-500 rounded-lg pc:p-4 pc:text-4xl">
              "영어, 어디서 시작했냐고 다들 물어봐요."
            </p>
            <p class="text-3xl font-bold text-center pc:text-6xl text-neutral-950 pc:leading-tight">
              당근영어 주니어로 커지는<br /><span class="text-orange-500">영어 회화 자부심!</span>
            </p>
          </div>
        </div>
        <ul v-if="idxLeSiteMain != '52'" class="grid max-w-lg grid-cols-2 gap-5 mx-auto pc:grid-cols-4 pc:max-w-none">
          <li class="flex flex-col items-center justify-center pc:gap-5 gap-2.5">
            <img src="@lifeeduWeb/assets/images/main/pride/con0-img.jpg" alt="1위" class="max-w-[100px] pc:w-min" />
            <p class="text-2xl font-bold pc:text-4xl text-neutral-950">1위</p>
            <p class="text-center font-sm pc:text-xl text-neutral-500">2020년 화상/전화영어<br />교육 서비스 부분 1위</p>
          </li>
          <li class="flex flex-col items-center justify-center pc:gap-5 gap-2.5">
            <img src="@lifeeduWeb/assets/images/main/pride/con1-img.jpg" alt="23년" class="max-w-[100px] pc:w-min" />
            <p class="text-2xl font-bold pc:text-4xl text-neutral-950">23년</p>
            <p class="text-center font-sm pc:text-xl text-neutral-500">Since 2000, 당근영어<br />23년 노하우 그대로</p>
          </li>
          <li class="flex flex-col items-center justify-center pc:gap-5 gap-2.5">
            <img src="@lifeeduWeb/assets/images/main/pride/con2-img.jpg" alt="30만명" class="max-w-[100px] pc:w-min" />
            <p class="text-2xl font-bold pc:text-4xl text-neutral-950">30만명</p>
            <p class="text-center font-sm pc:text-xl text-neutral-500">매년 당근영어와<br />함께하는 학습자, 30만명</p>
          </li>
          <li class="flex flex-col items-center justify-center pc:gap-5 gap-2.5">
            <img src="@lifeeduWeb/assets/images/main/pride/con3-img.jpg" alt="5,000명" class="max-w-[100px] pc:w-min" />
            <p class="text-2xl font-bold pc:text-4xl text-neutral-950">5,000명</p>
            <p class="text-center font-sm pc:text-xl text-neutral-500">엄선된 5,000명 이상의<br />원어민 강사진</p>
          </li>
        </ul>
      </section>

      <!-- 자녀를 위한 최고의 선택 - 주니어일 때 노출 -->
      <section
        class="py-24 overflow-hidden pc:py-32 bg-zinc-100"
        v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'junior'"
      >
        <div class="relative pc-con-wrapper1 curriculum">
          <div class="flex justify-center mb-10 pc:mb-16">
            <div class="flex flex-col gap-5 pc:gap-10">
              <p class="text-xl font-medium text-center text-neutral-950 pc:text-4xl">
                레벨, 학습, 관심사까지!<br />선택의 폭이 넓은 커리큘럼 LINE UP
              </p>
              <p class="text-3xl font-bold text-center pc:text-6xl text-neutral-950 pc:leading-tight">
                자녀를 위한 최고의 선택<br /><span class="text-orange-500">주니어 프리미엄 커리큘럼</span>
              </p>
            </div>
          </div>
          <swiper
            @swiper="setThumbsSwiper"
            :loop="false"
            :slidesPerView="7"
            :freeMode="false"
            class="p-4 mb-8 bg-white shadow-lg curriculum-list rounded-[50px]"
          >
            <swiper-slide
              ><p class="relative text-xl font-bold text-center cursor-pointer py-7">
                리딩북&Talk
                <span class="absolute -top-1 right-6 py-1 px-2.5 rounded-2xl bg-red-600 text-yellow-300 text-sm">new!</span>
              </p></swiper-slide
            >
            <swiper-slide><p class="text-xl font-bold text-center cursor-pointer py-7">일반회화</p></swiper-slide>
            <swiper-slide><p class="text-xl font-bold text-center cursor-pointer py-7">영어매거진</p></swiper-slide>
            <swiper-slide
              ><p class="relative text-xl font-bold text-center cursor-pointer py-7">
                영어인터뷰
                <span class="absolute -top-1 right-6 py-1 px-2.5 rounded-2xl bg-red-600 text-yellow-300 text-sm">new!</span>
              </p></swiper-slide
            >
            <swiper-slide><p class="text-xl font-bold text-center cursor-pointer py-7">직업탐구</p></swiper-slide>
            <swiper-slide><p class="text-xl font-bold text-center cursor-pointer py-7">토론심화</p></swiper-slide>
            <swiper-slide><p class="text-xl font-bold text-center cursor-pointer py-7">아카데믹</p></swiper-slide>
          </swiper>

          <swiper
            :loop="true"
            :spaceBetween="10"
            :autoHeight="true"
            :thumbs="{ swiper: thumbsSwiper }"
            :modules="modules"
            class="curriculum-content bg-white shadow-lg rounded-[50px]"
          >
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="flex items-center gap-2 mb-2 pc:mb-4">
                  <p class="px-2 py-1.5 text-2xl font-bold text-white bg-orange-600 rounded-full">NEW</p>
                  <p class="text-2xl font-bold">영어 원서 수업과정</p>
                </div>
                <p class="mb-5 text-lg">
                  퀴즈, 토론, 영작, 발음연습 등 다양한 활동을
                  <span class="font-bold text-orange-600">영어 원서를 통해<br />리딩, 스피킹, 라이팅까지 한번에</span>
                  끝내는 올인원 프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>초등부 · 중등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 5 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="w-2/3">
                  <img src="@lifeeduWeb/assets/images/main/curriculum/slide0-con.png" alt="image" class="w-min" />
                </div>
                <p
                  class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                  @click="goSite('https://www.carrotenglish.kr/carrotdata/pdf/Timmy_Failure.pdf')"
                >
                  수업 미리 보기
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="mb-2 pc:mb-4">
                  <p class="text-2xl font-bold">주니어 일반회화</p>
                </div>
                <p class="mb-5 text-lg">
                  언어 발달 단계에 맞춰
                  <span class="font-bold text-orange-600">개발된 16권의 코스북</span>으로<br /><span
                    class="font-bold text-orange-600"
                    >영어가 모국어처럼 편해질</span
                  >
                  수 있는 주니어 맞춤 프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>유치부 · 초등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 1 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="inline-block w-2/3">
                  <div class="flex relative z-[2] ml-3">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/slide1-con.png" alt="image" class="w-min" />
                  </div>
                  <div class="relative inline-block pt-24 -mt-24 pc:bg-gray-200 pc:w-80 px-7 pb-7 rounded-2xl">
                    <p class="text-xl font-bold mb-2.5">It's Me!</p>
                    <p class="mb-5 text-sm text-orange-600">Level 1 ~ 7</p>
                    <p
                      class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                      @click="goSite('https://www.carrotenglish.kr/carrotdata/pdf/SB_Jump09_sample.pdf')"
                    >
                      교재 미리 보기
                    </p>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="mb-2 pc:mb-4">
                  <p class="text-2xl font-bold">주니어 영어매거진</p>
                </div>
                <p class="mb-5 text-lg">
                  매월 발간되는
                  <span class="font-bold text-orange-600">수준별 영어 교육 전문 매거진</span>으로<br /><span
                    class="font-bold text-orange-600"
                    >영어 활용 능력과 비판적 사고력</span
                  >을 향상 시킬 수 있는 프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>유치부 · 초등부 · 중등부 · 고등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 2 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="inline-block w-2/3">
                  <div class="flex relative z-[2] ml-3">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/slide2-con.png" alt="image" class="w-min" />
                  </div>
                  <div class="relative inline-block pt-24 -mt-24 pc:bg-gray-200 pc:w-80 px-7 pb-7 rounded-2xl">
                    <p class="text-xl font-bold mb-2.5">NE Times</p>
                    <p class="mb-5 text-sm text-orange-600">Level 2 ~ 7</p>
                    <p
                      class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                      @click="goSite('https://www.carrotenglish.kr/carrotdata/pdf/program_j_2.pdf')"
                    >
                      교재 미리 보기
                    </p>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="flex items-center gap-2 mb-2 pc:mb-4">
                  <p class="px-2 py-1.5 text-2xl font-bold text-white bg-orange-600 rounded-full">NEW</p>
                  <p class="text-2xl font-bold">주니어 영어인터뷰</p>
                </div>
                <p class="mb-5 text-lg">
                  <span class="font-bold text-orange-600">질문 유형을 사전에 습득</span>하고
                  <span class="font-bold text-orange-600">실전 답변 스킬</span>을 향상함으로써<br /><span
                    class="font-bold text-orange-600"
                    >해외 유학 또는 구제 학교 입학의 가능성</span
                  >을 열어주는 맞춤 인터뷰 프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>초등부 · 중등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 1 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="w-2/3">
                  <img src="@lifeeduWeb/assets/images/main/curriculum/slide3-con.png" alt="image" class="w-min" />
                </div>
                <p
                  class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                  @click="goSite('https://carrotenglish.kr/carrotdata/pdf/Junior_English_Interview.pdf')"
                >
                  수업 미리 보기
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="mb-2 pc:mb-4">
                  <p class="text-2xl font-bold">글로벌 직업탐구</p>
                </div>
                <p class="mb-5 text-lg">
                  80년 역사의
                  <span class="font-bold text-orange-600">커리어 솔루션 프로그램 Kuder</span>를<br />기반으로 만들어진
                  <span class="font-bold text-orange-600">주니어 전용 글로벌 직업 이해도 향상</span>
                  프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>유치부 · 초등부 · 중등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 2 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="inline-block w-2/3">
                  <div class="flex relative z-[2] ml-3">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/slide4-con.png" alt="image" class="w-min" />
                  </div>
                  <div class="relative inline-block pt-24 -mt-24 pc:bg-gray-200 pc:w-80 px-7 pb-7 rounded-2xl">
                    <p class="text-xl font-bold mb-2.5">My Career World</p>
                    <p class="mb-5 text-sm text-orange-600">Level 2 ~ 7</p>
                    <p
                      class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                      @click="goSite('https://www.carrotenglish.kr/carrotdata/pdf/program_j_12.pdf')"
                    >
                      교재 미리 보기
                    </p>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="mb-2 pc:mb-4">
                  <p class="text-2xl font-bold">주니어 토론심화</p>
                </div>
                <p class="mb-5 text-lg">
                  관심사에 맞춘
                  <span class="font-bold text-orange-600">48개의 다양한 핵심 토픽</span>을 통해
                  <span class="font-bold text-orange-600">통합적 사고력</span>과<br /><span class="font-bold text-orange-600"
                    >스피킹 실력을 함께 향상</span
                  >시킬 수 있는 토론 프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>중등부 · 고등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 3 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="inline-block w-2/3">
                  <div class="flex relative z-[2] ml-3">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/slide5-con.png" alt="image" class="w-min" />
                  </div>
                  <div class="relative inline-block pt-24 -mt-24 pc:bg-gray-200 pc:w-80 px-7 pb-7 rounded-2xl">
                    <p class="text-xl font-bold mb-2.5">365 Think&Talk</p>
                    <p class="mb-5 text-sm text-orange-600">Level 3 ~ 7</p>
                    <p
                      class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                      @click="goSite('https://www.carrotenglish.kr/carrotdata/pdf/program_j_1.pdf')"
                    >
                      교재 미리 보기
                    </p>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p-7 pc:p-24">
                <div class="mb-2 pc:mb-4">
                  <p class="text-2xl font-bold">주니어 아카데믹</p>
                </div>
                <p class="mb-5 text-lg">
                  역사, 사회, 과학 등 주제별 학습 영상을 활용한 활동으로<br /><span class="font-bold text-orange-600"
                    >회화와 영작 능력까지 통합적 향상</span
                  >이 가능한 아카데믹 프로그램
                </p>
                <ul class="flex flex-col gap-2.5 pc:flex-row pc:gap-20 mb-14">
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/goal.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">TARGET</p>
                      <p>초등부 · 중등부 · 고등부</p>
                    </div>
                  </li>
                  <li class="flex items-center gap-5">
                    <img src="@lifeeduWeb/assets/images/main/curriculum/target.png" alt="icon" class="w-10 pc:w-min" />
                    <div>
                      <p class="text-xl font-bold text-orange-600">Carrot Lv</p>
                      <p>Lv 4 ~ 7</p>
                    </div>
                  </li>
                </ul>
                <div class="w-2/3">
                  <img src="@lifeeduWeb/assets/images/main/curriculum/slide6-con.png" alt="image" class="w-min" />
                </div>
                <p
                  class="inline-block text-sm py-1 px-2.5 text-white bg-orange-600 rounded-lg cursor-pointer"
                  @click="goSite('https://carrotenglish.kr/carrotdata/pdf/ted_ed.pdf')"
                >
                  수업 미리 보기
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>

      <!-- 당근영어 디지털 학습매니저 켈리 - 성인, 주니어 공통 노출 -->
      <section class="py-24 pc:py-32 pc-con-wrapper1">
        <div class="flex flex-col gap-5 mb-10 pc:gap-10 pc:mb-16">
          <p class="text-xl font-medium text-center text-neutral-950 pc:text-4xl">
            당근영어 디지털 학습매니저<br />켈리가 쉽게 설명해드려요
          </p>
          <p class="text-3xl font-bold text-center pc:text-6xl text-neutral-950 pc:leading-tight">
            영어 실력을<br />한 단계 끌어올리는<br />당근영어의<span class="text-orange-500"> 4단계 완전학습</span>
          </p>
        </div>
        <div>
          <video muted autoplay loop playsinline controls preload="auto">
            <source src="https://kr.object.ncloudstorage.com/lifeedu/user/meta.mp4" />
          </video>
        </div>
      </section>

      <!-- 우리 아이 맞춤형 - 주니어일 때 노출 -->
      <section
        class="py-20 overflow-hidden pc:pt-32 pc:pb-0 bg-orange-50"
        v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'junior'"
      >
        <div class="pc-con-wrapper1">
          <div class="flex justify-center mb-10 pc:mb-16">
            <div class="flex flex-col pc:gap-4 gap-2.5">
              <p class="text-3xl font-bold text-center pc:text-6xl text-neutral-950 pc:leading-tight">
                우리 아이 맞춤형<br /><span class="text-orange-500">1:1 수업 관리</span>가 만드는 실력의 차이
              </p>
            </div>
          </div>
          <div class="hidden pc:block">
            <ul class="grid grid-cols-1 gap-14 relative z-[1]">
              <li class="flex items-center gap-2.5">
                <img src="@lifeeduWeb/assets/images/main/management/icon0-pc.png" alt="icon" class="flex-none w-20" />
                <p class="text-xl font-bold">북미 현지 원어민 담임 강사님과<br />수준별 맞춤 학습과 문화를 이해 합니다.</p>
              </li>
              <li class="flex items-center gap-2.5">
                <img src="@lifeeduWeb/assets/images/main/management/icon1-pc.png" alt="icon" class="flex-none w-20" />
                <p class="text-xl font-bold">수업 후 녹화 파일로 다시 한번<br />기본을 탄탄히 합니다.</p>
              </li>
              <li class="flex items-center gap-2.5">
                <img src="@lifeeduWeb/assets/images/main/management/icon2-pc.png" alt="icon" class="flex-none w-20" />
                <p class="text-xl font-bold">나의 학습 스토리와 피드백을 통해<br />공부 습관이 개선됩니다.</p>
              </li>
            </ul>
            <div class="-mt-[400px]">
              <img src="@lifeeduWeb/assets/images/main/management/con-pc.png" alt="image" />
            </div>
          </div>
          <div class="max-w-lg mx-auto pc:hidden">
            <swiper :pagination="true" :modules="modules" class="relative pb-5 custom-slide">
              <swiper-slide>
                <div>
                  <img src="@lifeeduWeb/assets/images/main/management/img0-m.png" alt="img" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img src="@lifeeduWeb/assets/images/main/management/img1-m.png" alt="img" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img src="@lifeeduWeb/assets/images/main/management/img2-m.png" alt="img" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <!-- 나만을 위한 수업 스타일과 - 성인일 때 노출 -->
      <section class="overflow-hidden my-style" v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'adult'">
        <div class="py-24 pc:pt-32 pc-con-wrapper1">
          <div class="flex flex-col gap-5 mb-10 pc:gap-10 pc:mb-16">
            <p class="text-xl font-medium text-center text-neutral-950 pc:text-4xl">
              나만을 위한 수업 스타일과, 나의 실력 향상 스토리까지
            </p>
            <p class="text-3xl font-bold text-center pc:text-6xl text-neutral-950 pc:leading-tight">
              한 발 더 앞선 나를 위한 개인 맞춤형 수업 관리
            </p>
          </div>
          <div class="relative">
            <ul class="grid max-w-lg grid-cols-1 gap-16 mx-auto pc:grid-cols-2 pc:max-w-none">
              <li class="flex flex-col items-center gap-8 p-12 bg-white shadow-xl rounded-2xl">
                <p class="text-2xl font-bold text-center text-neutral-950">
                  내가 원하는
                  <span class="text-rose-500">수업 스타일</span>로<br />더 높아지는 학습 효과!
                </p>
                <p class="text-lg text-center text-neutral-950">
                  다 똑같이 구성된 수업은 그만!<br /><span class="font-bold">내가 선택</span>하는
                  <span class="font-bold">내게 가장 알맞은 수업 스타일</span>로<br />더 높아지는 학습 효과를 느껴보세요!
                </p>
                <img
                  src="@lifeeduWeb/assets/images/main/custom/style.png"
                  alt="수업 방식, 수업 시작, 집중 학습 영역"
                  class="w-min"
                />
              </li>
              <li class="flex flex-col items-center gap-8 p-12 bg-white shadow-xl rounded-2xl">
                <p class="text-2xl font-bold text-center text-neutral-950">
                  어제보다 성장한<br /><span class="text-rose-500">나의 스토리</span>를 확인하세요!
                </p>
                <p class="text-lg text-center text-neutral-950">
                  떨려서 더 잘하지 못했던 것 같은 첫 수업을 기억하시나요?<br /><span class="font-bold"
                    >나의 수업 스토리를 한눈에</span
                  >
                  확인해 보세요!<br /><span class="font-bold">자신감은 Up 하고 복습 효과는 덤!</span>
                </p>
                <img src="@lifeeduWeb/assets/images/main/custom/story.png" alt="스토리" class="w-min" />
              </li>
            </ul>
            <div class="absolute -right-28 -bottom-24">
              <img src="@lifeeduWeb/assets/images/main/custom/up.png" alt="image" class="w-min" />
            </div>
          </div>
        </div>
      </section>

      <!-- 성인일 때 노출 -->
      <section
        class="py-24 pc:py-32 pc-con-wrapper1"
        v-if="homeMainInfo.homeMainInfoDetail.homeMainDetailList.mainContent === 'adult'"
      >
        <div class="flex flex-col gap-5 mb-10 pc:gap-10 pc:mb-16">
          <p class="text-xl font-medium text-center text-neutral-950 pc:text-4xl">언제 어디서나 내맘대로, 맞춤형 수업!</p>
          <p class="text-3xl font-bold text-center pc:text-6xl text-neutral-950 pc:leading-tight">
            바쁜 일정에도 걱정 없이<br />내 스케줄에 맞춰 수업을 들으세요!
          </p>
        </div>
        <div
          class="flex flex-col items-center justify-between max-w-lg gap-10 mx-auto pc:items-start pc:gap-0 anywhere pc:max-w-none"
        >
          <ul class="flex flex-col justify-between w-full gap-10 pc:gap-16 pc:flex-row top">
            <li class="flex justify-between bg-white gap-14 p-10 pc:w-[465px] w-full relative">
              <div class="flex flex-col gap-2.5">
                <p class="text-lg font-bold text-neutral-950">회수 제한 없는 스케줄 조정</p>
                <p class="text-lg text-neutral-950">
                  여행? 회식? 약속? 그냥 피곤?<br />상관 없어요!<br />내 수업은 횟수제한 없이<br />스케줄 조정이 가능하니까!
                </p>
              </div>
              <div class="absolute hidden right-10 bottom-10 pc:block">
                <img src="@lifeeduWeb/assets/images/main/anywhere/icon0.png" alt="icon" class="w-min" />
              </div>
            </li>
            <li class="flex justify-between bg-white gap-14 p-10 pc:w-[465px] w-full relative">
              <div class="flex flex-col gap-2.5">
                <p class="text-lg font-bold text-neutral-950">다른 강사님과도 수업해보기</p>
                <p class="text-lg text-neutral-950">
                  다른 강사님 수업도 궁금하신가요?<br />오늘만 강사님을 변경해서<br />부담없이 들어보세요!
                </p>
              </div>
              <div class="absolute hidden right-10 bottom-10 pc:block">
                <img src="@lifeeduWeb/assets/images/main/anywhere/icon1.png" alt="icon" class="w-min" />
              </div>
            </li>
          </ul>
          <p class="hidden text-4xl font-bold text-center text-white pc:block">이 모든 것이,<br />당근영어의 혜택!</p>
          <ul class="flex flex-col justify-between w-full gap-10 pc:gap-16 pc:flex-row bottom">
            <li class="flex justify-between bg-white gap-14 p-10 pc:w-[465px] w-full relative">
              <div class="flex flex-col gap-2.5">
                <p class="text-lg font-bold text-neutral-950">1번의 수업도 놓치지 않도록! 보강 자동 알람</p>
                <p class="text-lg text-neutral-950">
                  미진행된 수업은 보강 알림톡으로<br />보강 수업은 보강 알림 기능으로<br />빠지지 않고 수업을 들을 수 있도록<br />스케줄링
                  가능해요!
                </p>
              </div>
              <div class="absolute hidden right-10 bottom-10 pc:block">
                <img src="@lifeeduWeb/assets/images/main/anywhere/icon2.png" alt="icon" class="w-min" />
              </div>
            </li>

            <li class="flex justify-between bg-white gap-14 p-10 pc:w-[465px] w-full relative">
              <div class="flex flex-col gap-2.5">
                <p class="text-lg font-bold text-neutral-950">인터넷 환경이라면, 해외에서도 OK!</p>
                <p class="text-lg text-neutral-950">
                  해외 출장이라도,<br />여행 중이라도 인터넷만 연결되면<br />해외에서도 OK!<br />걱정없이 수업을 들을 수 있어요!
                </p>
              </div>
              <div class="absolute hidden right-10 bottom-10 pc:block">
                <img src="@lifeeduWeb/assets/images/main/anywhere/icon3.png" alt="icon" class="w-min" />
              </div>
            </li>
          </ul>
        </div>
      </section>
    </section>

    <!-- 공지사항 -->
    <section v-if="idxLeSiteMain != '52'" class="border-t border-solid border-neutral-300">
      <div class="flex items-center justify-between gap-2 py-5 pc-con-wrapper1 pc:py-9">
        <div class="flex items-center gap-2.5 pc:gap-16">
          <p class="text-lg font-medium pc:text-3xl text-neutral-950">공지사항</p>
          <p
            v-if="homeMainInfo && homeMainInfo.homeMainInfoDetail && homeMainInfo.homeMainInfoDetail.homeMainNoticeList[0]"
            class="text-xs cursor-pointer pc:text-xl text-neutral-500"
            @click="homeMainInfo.getNotice"
          >
            {{ homeMainInfo.homeMainInfoDetail.homeMainNoticeList[0].title }}
          </p>
        </div>
        <div class="cursor-pointer" @click="goPage('/learningSupport/noticeList')">
          <img src="@lifeeduWeb/assets/images/main/notice/more.png" alt="icon" class="w-7 pc:w-min" />
        </div>
      </div>
    </section>

    <!-- 원격지원, 고객센터 -->
    <section v-if="idxLeSiteMain != '52'" class="py-9 pc:py-12 bg-zinc-100">
      <div class="flex flex-col divide-y gap-9 pc:gap-12 pc-con-wrapper1 divide-solid divide-neutral-300">
        <!-- <div class="flex flex-col gap-4 pc:flex-row pc:justify-between">
          <div class="flex flex-col gap-4">
            <p class="text-lg font-medium pc:text-3xl text-neutral-950">원격지원으로 문제 해결</p>
            <p class="text-xs pc:text-xl text-neutral-500">
              당근영어 원격지원 서비스는 직접 인터넷을 통해 회원님의<br />PC상태 및 화상장비를 점검하고 쉽고 빠른 웹기반 형식의<br />원격지원으로
              문제를 해결해 드리는 서비스를 제공하고 있습니다.
            </p>
          </div>
        </div> -->

        <div v-if="idxLeSiteMain != '52'" class="flex flex-col pc:flex-row pc:items-center pc:justify-between">
          <div class="flex flex-col gap-4">
            <p class="text-lg font-medium pc:text-3xl text-neutral-950">고객센터</p>
            <p class="text-sm font-bold pc:text-xl text-neutral-950">02-518-0036</p>
          </div>
          <div class="flex flex-col gap-5 pc:flex-row pc:items-center pc:gap-44">
            <p class="text-sm pc:text-xl text-neutral-500">09:00 - 18:00 주말&공휴일 휴무</p>
            <ul class="flex gap-2.5 pc:gap-4 pc:grid pc:grid-cols-4">
              <li class="cursor-pointer" @click="goSite('https://carrotenglish.com/')">
                <img src="@lifeeduWeb/assets/images/main/help/sns0.png" alt="당근영어" class="w-12 pc:w-min" />
              </li>
              <li class="cursor-pointer" @click="goSite('https://blog.naver.com/carrotkr6404')">
                <img src="@lifeeduWeb/assets/images/main/help/sns1.png" alt="네이버 블로그" class="w-12 pc:w-min" />
              </li>
              <li class="cursor-pointer" @click="goSite('https://www.instagram.com/carrotenglish')">
                <img src="@lifeeduWeb/assets/images/main/help/sns2.png" alt="인스타그램" class="w-12 pc:w-min" />
              </li>
              <li class="cursor-pointer" @click="goSite('https://www.youtube.com/@carrotenglish')">
                <img src="@lifeeduWeb/assets/images/main/help/sns3.png" alt="유튜브" class="w-12 pc:w-min" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="idxLeSiteMain != '52'"
      class="fixed go-inquiry right-2 bottom-2 pc:bottom-10 pc:right-10 z-[1] cursor-pointer w-[155px] h-[70px]"
      @click="openChannelTalk"
    >
      <img src="@lifeeduWeb/assets/images/common/inquiry.png" alt="icon" />
    </div>
  </div>

  <!-- 이벤트 팝업1 -->
  <div v-if="homeMainInfo.homeMainInfoDetail.popupImg1">
    <div
      class="fixed top-0 left-0 w-full h-full overflow-y-auto z-[2] pc:absolute pc:top-[300px] pc:left-1/2 pc:-translate-x-1/2 pc:w-auto pc:h-auto"
      v-if="isPopup1Visible"
    >
      <div class="flex items-center justify-center min-h-full p-5">
        <div
          class="flex flex-col items-center justify-center max-w-[850px] mx-auto bg-white border border-solid border-neutral-300"
        >
          <div class="w-full pr-5 text-xl text-right bg-green-500 cursor-pointer" @click="closePopup1">X</div>
          <div class="content">
            <div>
              <img :src="homeMainInfo.homeMainInfoDetail.popupImg1" alt="img" class="aspect-square" />
            </div>
            <ul class="flex">
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer border-r border-solid border-neutral-300">
                <a @click="closePopup1OneDay">오늘하루 보지 않기</a>
              </li>
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer">
                <a @click="closePopup1">닫기</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 이벤트 팝업2 -->
  <div v-if="homeMainInfo.homeMainInfoDetail.popupImg2">
    <div
      class="fixed top-0 left-0 w-full h-full overflow-y-auto z-[2] pc:absolute pc:top-[400px] pc:left-1/2 pc:-translate-x-1/2 pc:w-auto pc:h-auto"
      v-if="isPopup2Visible"
    >
      <div class="flex items-center justify-center min-h-full p-5">
        <div
          class="flex flex-col items-center justify-center max-w-[850px] mx-auto bg-white border border-solid border-neutral-300"
        >
          <div class="w-full pr-5 text-xl text-right bg-red-500 cursor-pointer" @click="closePopup2">X</div>
          <div class="content">
            <div>
              <img :src="homeMainInfo.homeMainInfoDetail.popupImg2" alt="img" class="aspect-square" />
            </div>
            <ul class="flex">
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer border-r border-solid border-neutral-300">
                <a @click="closePopup2OneDay">오늘하루 보지 않기</a>
              </li>
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer">
                <a @click="closePopup2">닫기</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 이벤트 팝업3 -->
  <div v-if="homeMainInfo.homeMainInfoDetail.popupImg3">
    <div
      class="fixed top-0 left-0 w-full h-full overflow-y-auto z-[2] pc:absolute pc:top-[500px] pc:left-1/2 pc:-translate-x-1/2 pc:w-auto pc:h-auto"
      v-if="isPopup3Visible"
    >
      <div class="flex items-center justify-center min-h-full p-5">
        <div
          class="flex flex-col items-center justify-center max-w-[850px] mx-auto bg-white border border-solid border-neutral-300"
        >
          <div class="w-full pr-5 text-xl text-right bg-blue-500 cursor-pointer" @click="closePopup3">X</div>
          <div class="content">
            <div>
              <img :src="homeMainInfo.homeMainInfoDetail.popupImg3" alt="img" class="aspect-square" />
            </div>
            <ul class="flex">
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer border-r border-solid border-neutral-300">
                <a @click="closePopup3OneDay">오늘하루 보지 않기</a>
              </li>
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer">
                <a @click="closePopup3">닫기</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 이벤트 팝업4 -->
  <div v-if="homeMainInfo.homeMainInfoDetail.popupImg4">
    <div
      class="fixed top-0 left-0 w-full h-full overflow-y-auto z-[2] pc:absolute pc:top-[600px] pc:left-1/2 pc:-translate-x-1/2 pc:w-auto pc:h-auto"
      v-if="isPopup4Visible"
    >
      <div class="flex items-center justify-center min-h-full p-5">
        <div
          class="flex flex-col items-center justify-center max-w-[850px] mx-auto bg-white border border-solid border-neutral-300"
        >
          <div class="w-full pr-5 text-xl text-right cursor-pointer bg-lime-500" @click="closePopup4">X</div>
          <div class="content">
            <div>
              <img :src="homeMainInfo.homeMainInfoDetail.popupImg4" alt="img" class="aspect-square" />
            </div>
            <ul class="flex">
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer border-r border-solid border-neutral-300">
                <a @click="closePopup4OneDay">오늘하루 보지 않기</a>
              </li>
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer">
                <a @click="closePopup4">닫기</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 이벤트 팝업5 -->
  <div v-if="homeMainInfo.homeMainInfoDetail.popupImg5">
    <div
      class="fixed top-0 left-0 w-full h-full overflow-y-auto z-[2] pc:absolute pc:top-[700px] pc:left-1/2 pc:-translate-x-1/2 pc:w-auto pc:h-auto"
      v-if="isPopup5Visible"
    >
      <div class="flex items-center justify-center min-h-full p-5">
        <div
          class="flex flex-col items-center justify-center max-w-[850px] mx-auto bg-white border border-solid border-neutral-300"
        >
          <div class="w-full pr-5 text-xl text-right bg-purple-500 cursor-pointer" @click="closePopup5">X</div>
          <div class="content">
            <div>
              <img :src="homeMainInfo.homeMainInfoDetail.popupImg5" alt="img" class="aspect-square" />
            </div>
            <ul class="flex">
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer border-r border-solid border-neutral-300">
                <a @click="closePopup5OneDay">오늘하루 보지 않기</a>
              </li>
              <li class="grow w-1/2 p-2.5 text-center cursor-pointer">
                <a @click="closePopup5">닫기</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { ref, reactive, onMounted, watch } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode, Thumbs, Pagination } from "swiper/modules";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const openChannelTalk = () => {
      ChannelService.loadScript();
      ChannelService.boot({
        pluginKey: "652f8e43-1d56-41b7-b594-bd6c8f2bd4e4",
        hideChannelButtonOnBoot: true,
      });
      ChannelService.openChat();
    };

    let idxLeSiteMain = "";
    // 로컬 테스트용
    // if (route.query.idxLeSiteMain) {
    //   idxLeSiteMain = route.query.idxLeSiteMain;
    // 실서버 운영용
    if (store.getters.getIdxLeSiteMain) {
      idxLeSiteMain = store.getters.getIdxLeSiteMain;
      store.commit("setIdxLeSiteMain", idxLeSiteMain);
    } else if (store.getters.getIdxLeSiteMain != "") {
      idxLeSiteMain = store.getters.getIdxLeSiteMain;
      console.log("idxLeSiteMain", idxLeSiteMain);
    }

    const goPage = (path) => {
      router.push(path);
    };

    const goSite = (url) => {
      window.open(url, "_blank");
    };

    const goToSugang = (url) => {
      window.location.href = url;
    };

    const goSmooth = (idx) => {
      document.getElementById(idx).scrollIntoView({ behavior: "smooth" });
    };

    // 팝업 오늘하루, 닫기
    const isPopup1Visible = ref(true);
    const isPopup2Visible = ref(true);
    const isPopup3Visible = ref(true);
    const isPopup4Visible = ref(true);
    const isPopup5Visible = ref(true);

    // 팝업1
    const getPopupKey1 = () => {
      // 현재 사이트의 고유한 키를 생성하는 로직
      const siteKey = `site_${store.getters.getIdxLeSiteMain || "default"}`;
      return `lastHiddenTime1_${siteKey}`;
    };

    const closePopup1OneDay = () => {
      isPopup1Visible.value = false;
      const currentDate = new Date();
      const lastHiddenTimeKey = getPopupKey1();
      localStorage.setItem(lastHiddenTimeKey, currentDate.getTime());
    };

    const closePopup1 = () => {
      isPopup1Visible.value = false;
    };

    // 화면 로딩 시 팝업 상태 초기화
    const initializePopup1State = () => {
      isPopup1Visible.value = !isHiddenToday1();
    };

    const isHiddenToday1 = () => {
      const lastHiddenTimeKey = getPopupKey1();
      const lastHiddenTime = localStorage.getItem(lastHiddenTimeKey);

      if (!lastHiddenTime) return false;

      const currentDate = new Date();
      return currentDate.getTime() - parseInt(lastHiddenTime) < 23 * 60 * 60 * 1000;
    };

    // 팝업2
    const getPopupKey2 = () => {
      // 현재 사이트의 고유한 키를 생성하는 로직
      const siteKey = `site_${store.getters.getIdxLeSiteMain || "default"}`;
      return `lastHiddenTime2_${siteKey}`;
    };

    const closePopup2OneDay = () => {
      isPopup2Visible.value = false;
      const currentDate = new Date();
      const lastHiddenTimeKey = getPopupKey2();
      localStorage.setItem(lastHiddenTimeKey, currentDate.getTime());
    };

    const closePopup2 = () => {
      isPopup2Visible.value = false;
    };

    // 화면 로딩 시 팝업 상태 초기화
    const initializePopup2State = () => {
      isPopup2Visible.value = !isHiddenToday2();
    };

    const isHiddenToday2 = () => {
      const lastHiddenTimeKey = getPopupKey2();
      const lastHiddenTime = localStorage.getItem(lastHiddenTimeKey);

      if (!lastHiddenTime) return false;

      const currentDate = new Date();
      return currentDate.getTime() - parseInt(lastHiddenTime) < 23 * 60 * 60 * 1000;
    };

    // 팝업3
    const getPopupKey3 = () => {
      // 현재 사이트의 고유한 키를 생성하는 로직
      const siteKey = `site_${store.getters.getIdxLeSiteMain || "default"}`;
      return `lastHiddenTime3_${siteKey}`;
    };

    const closePopup3OneDay = () => {
      isPopup3Visible.value = false;
      const currentDate = new Date();
      const lastHiddenTimeKey = getPopupKey3();
      localStorage.setItem(lastHiddenTimeKey, currentDate.getTime());
    };

    const closePopup3 = () => {
      isPopup3Visible.value = false;
    };

    // 화면 로딩 시 팝업 상태 초기화
    const initializePopup3State = () => {
      isPopup3Visible.value = !isHiddenToday3();
    };

    const isHiddenToday3 = () => {
      const lastHiddenTimeKey = getPopupKey3();
      const lastHiddenTime = localStorage.getItem(lastHiddenTimeKey);

      if (!lastHiddenTime) return false;

      const currentDate = new Date();
      return currentDate.getTime() - parseInt(lastHiddenTime) < 23 * 60 * 60 * 1000;
    };

    // 팝업4
    const getPopupKey4 = () => {
      // 현재 사이트의 고유한 키를 생성하는 로직
      const siteKey = `site_${store.getters.getIdxLeSiteMain || "default"}`;
      return `lastHiddenTime4_${siteKey}`;
    };

    const closePopup4OneDay = () => {
      isPopup4Visible.value = false;
      const currentDate = new Date();
      const lastHiddenTimeKey = getPopupKey4();
      localStorage.setItem(lastHiddenTimeKey, currentDate.getTime());
    };

    const closePopup4 = () => {
      isPopup4Visible.value = false;
    };

    // 화면 로딩 시 팝업 상태 초기화
    const initializePopup4State = () => {
      isPopup4Visible.value = !isHiddenToday4();
    };

    const isHiddenToday4 = () => {
      const lastHiddenTimeKey = getPopupKey4();
      const lastHiddenTime = localStorage.getItem(lastHiddenTimeKey);

      if (!lastHiddenTime) return false;

      const currentDate = new Date();
      return currentDate.getTime() - parseInt(lastHiddenTime) < 23 * 60 * 60 * 1000;
    };

    // 팝업5
    const getPopupKey5 = () => {
      // 현재 사이트의 고유한 키를 생성하는 로직
      const siteKey = `site_${store.getters.getIdxLeSiteMain || "default"}`;
      return `lastHiddenTime5_${siteKey}`;
    };

    const closePopup5OneDay = () => {
      isPopup5Visible.value = false;
      const currentDate = new Date();
      const lastHiddenTimeKey = getPopupKey5();
      localStorage.setItem(lastHiddenTimeKey, currentDate.getTime());
    };

    const closePopup5 = () => {
      isPopup5Visible.value = false;
    };

    // 화면 로딩 시 팝업 상태 초기화
    const initializePopup5State = () => {
      isPopup5Visible.value = !isHiddenToday5();
    };

    const isHiddenToday5 = () => {
      const lastHiddenTimeKey = getPopupKey5();
      const lastHiddenTime = localStorage.getItem(lastHiddenTimeKey);

      if (!lastHiddenTime) return false;

      const currentDate = new Date();
      return currentDate.getTime() - parseInt(lastHiddenTime) < 23 * 60 * 60 * 1000;
    };

    // 커리큘럼 슬라이더
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    const homeMainInfo = reactive({
      homeMainInfoDetail: {
        idxLeSiteMain: idxLeSiteMain,
        pageNum: 1,
        pageSize: 50,
        homeMainDetailList: [],
        homeMainNoticeList: [],
        idxCompany: "",
        isAdult: "",
        popupImg1: "",
        popupImg2: "",
        popupImg3: "",
        popupImg4: "",
        popupImg5: "",
      },

      openGlobalsugang() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        console.log(store.state.userId);
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            window.location.href = `https://apply.carrotglobal.com/lnk/busanedu?id=${store.state.userId}&accesscode=2024`;
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      opencheckGlobalsugang() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            if (location.origin.includes("stage")) {
              window.location.href = "https://stage-itmsugang.carrotenglish.net/lnk/hunitest?loginPageMode=history";
            } else {
              window.location.href = "https://apply.carrotglobal.com/lnk/busanedu?loginPageMode=history";
            }
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },
      goEnrolmentJunior() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            if (homeMainInfo.homeMainInfoDetail.isAdult === "N") {
              router.push({
                path: "/enrolment/newEnrolment",
                query: { idxLeSiteMain },
              });
            } else if (homeMainInfo.homeMainInfoDetail.isAdult === "Y") {
              alert("성인은 주니어과정을 신청하실 수 없습니다. 성인과정을 신청해주세요.");
            }
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      goEnrolmentAdult() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            if (homeMainInfo.homeMainInfoDetail.isAdult === "Y") {
              router.push({
                path: "/enrolment/newEnrolment",
                query: { idxLeSiteMain },
              });
            } else if (homeMainInfo.homeMainInfoDetail.isAdult === "N") {
              alert("주니어는 성인과정을 신청하실 수 없습니다. 주니어과정을 신청해주세요.");
            }
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      goClassList() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/enrolment/classList",
              query: { idxLeSiteMain },
            });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      goMyClass() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/myClass",
              query: { idxLeSiteMain },
            });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      getSiteMain() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
        };

        axios.get(`/user/page/siteMain`, { params }).then(function (res) {
          if (res.data.success === true) {
            homeMainInfo.homeMainInfoDetail.homeMainDetailList = res.data.data;
            const companyLogo = homeMainInfo.homeMainInfoDetail.homeMainDetailList.companyLogo;
            homeMainInfo.homeMainInfoDetail.idxCompany = res.data.data.idxCompany;
            homeMainInfo.homeMainInfoDetail.isAdult = res.data.data.isAdult;
            store.dispatch("setCompanyLogo", companyLogo);
            store.commit("setIdxCompany", res.data.data.idxCompany);
            store.commit("setIsCancleFunction", res.data.data.isCancleFunction);
            homeMainInfo.homeMainInfoDetail.popupImg1 = res.data.data.popupImg1;
            homeMainInfo.homeMainInfoDetail.popupImg2 = res.data.data.popupImg2;
            homeMainInfo.homeMainInfoDetail.popupImg3 = res.data.data.popupImg3;
            homeMainInfo.homeMainInfoDetail.popupImg4 = res.data.data.popupImg4;
            homeMainInfo.homeMainInfoDetail.popupImg5 = res.data.data.popupImg5;
            homeMainInfo.homeMainInfoDetail.button1Link = res.data.data.button1Link;
            homeMainInfo.homeMainInfoDetail.button1Name = res.data.data.button1Name;
            homeMainInfo.homeMainInfoDetail.button2Link = res.data.data.button2Link;
            homeMainInfo.homeMainInfoDetail.button2Name = res.data.data.button2Name;
            homeMainInfo.homeMainInfoDetail.button3Link = res.data.data.button3Link;
            homeMainInfo.homeMainInfoDetail.button3Name = res.data.data.button3Name;
            homeMainInfo.homeMainInfoDetail.button4Link = res.data.data.button4Link;
            homeMainInfo.homeMainInfoDetail.button4Name = res.data.data.button4Name;
          } else {
            alert(res.data.message);
          }
        });
      },

      getNoticeList() {
        const params = {
          idxLeSiteMain: idxLeSiteMain,
          pageNum: homeMainInfo.homeMainInfoDetail.pageNum,
          pageSize: homeMainInfo.homeMainInfoDetail.pageSize,
        };
        axios.get(`/user/page/noticeList`, { params }).then(function (res) {
          if (res.data.success === true) {
            homeMainInfo.homeMainInfoDetail.homeMainNoticeList = res.data.data;
          } else {
            alert(res.data.message);
          }
        });
      },

      getNotice() {
        const idxNotice = homeMainInfo.homeMainInfoDetail.homeMainNoticeList[0].idxNotice;
        const idxLeSiteMain = store.getters.getIdxLeSiteMain;

        axios.get(`/user/page/notice?idxLeSiteMain=${idxLeSiteMain}&idxNotice=${idxNotice}`).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/learningSupport/noticeDetail",
              query: {
                idxNotice: idxNotice,
                idxLeSiteMain: idxLeSiteMain,
              },
            });
          } else {
            alert(res.data.message);
          }
        });
      },
    });

    onMounted(async () => {
      const token = store.getters.getAccessToken;
      const params = {
        token: token,
      };

      const result = await axios.get(`/user/auth/token`, { params });

      const { success } = result.data;

      if (!success) {
        store.commit("setAccessToken", null);
      }

      homeMainInfo.getSiteMain();
      homeMainInfo.getNoticeList();
      initializePopup1State();
      initializePopup2State();
      initializePopup3State();
      initializePopup4State();
      initializePopup5State();
    });

    return {
      idxLeSiteMain,
      // 커리큘럼 슬라이더
      thumbsSwiper,
      setThumbsSwiper,

      // 우리아이 맞춤형 슬라이더
      Swiper,
      SwiperSlide,

      // 팝업 오늘하루, 닫기
      isPopup1Visible, // 팝업1
      isPopup2Visible, // 팝업2
      isPopup3Visible, // 팝업3
      isPopup4Visible, // 팝업4
      isPopup5Visible, // 팝업5

      // 팝업1 상태 관리
      closePopup1OneDay,
      closePopup1,
      initializePopup1State,

      // 팝업2 상태 관리
      closePopup2OneDay,
      closePopup2,
      initializePopup2State,

      // 팝업3 상태 관리
      closePopup3OneDay,
      closePopup3,
      initializePopup3State,

      // 팝업4 상태 관리
      closePopup4OneDay,
      closePopup4,
      initializePopup4State,

      // 팝업5 상태 관리
      closePopup5OneDay,
      closePopup5,
      initializePopup5State,

      // 그 외
      currentRoute: route,
      modules: [FreeMode, Thumbs, Pagination],
      goPage,
      goSite,
      goToSugang,
      goSmooth,
      homeMainInfo,
      openChannelTalk,
    };
  },
};
</script>
<style scoped>
@media screen and (min-width: 1200px) {
  .curriculum::after {
    content: "";
    position: absolute;
    width: 673px;
    height: 573px;
    background: url(@lifeeduWeb/assets/images/main/curriculum/content-bg.png) no-repeat;
    right: -300px;
    bottom: -100px;
    z-index: 1;
  }
}

.curriculum-list .swiper-wrapper .swiper-slide-thumb-active {
  color: #fff;
  background-color: #ea580c;
  border-radius: 50px;
  overflow-x: auto;
}

:deep(.curriculum-list) .swiper-wrapper {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: row;
  gap: 20px;
}

.curriculum-list .swiper-wrapper .swiper-slide {
  width: 100% !important;
}

.curriculum-content .swiper-wrapper .swiper-slide:nth-child(1) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide0-bg.png) no-repeat right bottom / contain;
}
.curriculum-content .swiper-wrapper .swiper-slide:nth-child(2) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide1-bg.png) no-repeat right bottom / contain;
}
.curriculum-content .swiper-wrapper .swiper-slide:nth-child(3) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide2-bg.png) no-repeat right bottom / contain;
}
.curriculum-content .swiper-wrapper .swiper-slide:nth-child(4) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide3-bg.png) no-repeat right bottom / contain;
}
.curriculum-content .swiper-wrapper .swiper-slide:nth-child(5) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide4-bg.png) no-repeat right bottom / contain;
}
.curriculum-content .swiper-wrapper .swiper-slide:nth-child(6) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide5-bg.png) no-repeat right bottom / contain;
}
.curriculum-content .swiper-wrapper .swiper-slide:nth-child(7) > div {
  background: url(@lifeeduWeb/assets/images/main/curriculum/slide6-bg.png) no-repeat right bottom / contain;
}

:deep(.custom-slide) .swiper-pagination {
  bottom: 0;
}

.my-style {
  background: #f4f4f4 url(@lifeeduWeb/assets/images/main/custom/bg.png) no-repeat center bottom / contain;
}

@media screen and (min-width: 1200px) {
  .anywhere {
    background: url(@lifeeduWeb/assets/images/main/anywhere/bg.png) no-repeat center / 492px 490px;
  }

  .anywhere > ul.top > li:nth-of-type(1) {
    background: #fff url(@lifeeduWeb/assets/images/main/anywhere/num0.png) no-repeat right bottom / 50px;
  }
  .anywhere > ul.top > li:nth-of-type(2) {
    background: #fff url(@lifeeduWeb/assets/images/main/anywhere/num1.png) no-repeat left bottom / 50px;
  }
  .anywhere > ul.bottom > li:nth-of-type(1) {
    background: #fff url(@lifeeduWeb/assets/images/main/anywhere/num2.png) no-repeat right top / 50px;
  }
  .anywhere > ul.bottom > li:nth-of-type(2) {
    background: #fff url(@lifeeduWeb/assets/images/main/anywhere/num3.png) no-repeat left top / 50px;
  }
}

.anywhere > ul > li {
  box-shadow: -2px -1px 9px #ccc;
}
</style>
