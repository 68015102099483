<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">[{{ registSugang.getCompanyKoreanName() }} {{ registSugang.getSugangName() }}]</p>
      <p class="mb-2 text-lg">
        수강신청기간 : {{ registSugang.getDisplayStartDate() }} ~ {{ registSugang.getDisplayEndDate() }} | 진행상태 :
        {{ registSugang.getStatus() }}
      </p>
    </div>
    <div class="p-2 mb-5">
      <!-- 수정일 땐 그룹 수강신청 수정 으로 변경  -->
      <p class="text-xl font-semibold">그룹 수강신청 등록</p>
      <p class="flex-none font-semibold mt-3">
        <span class="font-bold text-red-500">*</span> 수강신청 기간에는 그룹 수 감소는 불가능합니다.
      </p>
    </div>

    <!-- 일반/지원 선택 -->
    <div class="p-2 flex gap-2">
      <div
        :class="{
          'bg-slate-800 text-white': registSugang.registSugangData.sugangSupType == '일반',
          'text-slate-800 ': registSugang.registSugangData.sugangSupType == '지원',
        }"
        class="p-2 rounded border border-solid border-slate-800"
      >
        일반
      </div>
      <div
        v-if="registSugang.registSugangInfo.isGroupSup == 'Y'"
        :class="{
          'bg-slate-800 text-white': registSugang.registSugangData.sugangSupType == '지원',
          'text-slate-800 ': registSugang.registSugangData.sugangSupType == '일반',
        }"
        class="p-2 rounded border border-solid border-slate-800"
      >
        지원
      </div>
    </div>

    <!-- 그룹 유형, 수강 언어, 레벨 선택 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <GroupClass
        :sugangGroupTypeList="registSugang.registSugangData.sugangGroupTypeList"
        @set-regist-sugang-data="registSugang.setRegistSugangData"
      ></GroupClass>

      <!-- 수업 횟수 / 시간 -->
      <!-- <div class="p-2 mb-5 rounded shadow-md">
        <GroupClassEduPay
          :sugangGroupTimeInfo="registSugang.registSugangData.sugangGroupTimeInfoList"
          @set-edupay-data="registSugang.setEduPayData"
        ></GroupClassEduPay>
      </div> -->

      <div class="flex justify-end">
        <p
          class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
          @click="registSugang.registSugangGroupClass()"
        >
          그룹 임시저장
        </p>
      </div>
    </div>

    <!-- 개월 수 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">개월 수</p>
      <div class="flex items-center gap-2">
        <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 개월 수</p>
        <ul class="flex items-baseline gap-4">
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_0" value="0" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_0"
              >사용 안함</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_1" value="1" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_1"
              >1개월</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_2" value="2" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_2">2개월</label>
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_3" value="3" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_3">3개월</label>
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_4" value="4" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_4">4개월</label>
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_5" value="5" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_5"
              >5개월</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_6" value="6" v-model="registSugang.registSugangData.classMonth" /><label
              class="cursor-pointer"
              for="rdo1_6"
              >6개월</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo1" id="rdo1_7" value="12" v-model="registSugang.registSugangData.classMonth" />
            <label class="cursor-pointer" for="rdo1_7">12개월</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 레벨테스트 신청 여부 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">레벨테스트 신청 여부</p>
      <div class="flex items-center gap-2">
        <p class="flex-none font-semibold w-36"><span class="font-bold text-red-500">*</span> 레벨테스트 신청</p>
        <ul class="flex items-baseline gap-4">
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo2" id="rdo2_0" value="Y" v-model="registSugang.registSugangData.isLevelTest" /><label
              class="cursor-pointer"
              for="rdo2_0"
              >사용</label
            >
          </li>
          <li class="flex items-center gap-2">
            <input type="radio" name="rdo2" id="rdo2_1" value="N" v-model="registSugang.registSugangData.isLevelTest" />
            <label class="cursor-pointer" for="rdo2_1">사용 안함</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 안내문구 입력 -->
    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">안내문구 입력</p>
      <li class="flex items-center gap-2 pb-4 border-b border-solid">
        <div class="flex items-center flex-none gap-2 font-semibold w-44">
          <input
            type="checkbox"
            id="numberOfClassText"
            :checked="registSugang.registSugangData.isNumberOfClassText"
            v-model="registSugang.registSugangData.isNumberOfClassText"
            true-value="Y"
            false-value="N"
          />
          <label class="cursor-pointer" for="numberOfClassText">
            <p>수업횟수 / 시간 안내</p>
          </label>
        </div>
        <textarea
          class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
          v-model="registSugang.registSugangData.numberOfClassText"
        ></textarea>
      </li>
      <li class="flex items-center gap-2 pb-4 border-b border-solid">
        <div class="flex items-center flex-none gap-2 font-semibold w-44">
          <input
            type="checkbox"
            name=""
            id="levelChoiceText"
            :checked="registSugang.registSugangData.isLevelChoiceText"
            v-model="registSugang.registSugangData.isLevelChoiceText"
            true-value="Y"
            false-value="N"
          />
          <label class="cursor-pointer" for="levelChoiceText">
            <p>레벨 선택 안내</p>
          </label>
        </div>
        <textarea
          class="w-full max-w-xl p-2 border border-gray-400 border-solid resize-none"
          v-model="registSugang.registSugangData.levelChoiceText"
        ></textarea>
      </li>
    </div>

    <div class="flex justify-between">
      <p class="p-2 border border-solid rounded cursor-pointer border-slate-800" @click="registSugang.goBack()">이전 페이지</p>

      <!-- 지원수강이 존재하는 경우 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangGroupAll"
        v-if="registSugang.registSugangData.sugangSupType == '일반' && registSugang.registSugangInfo.isGroupSup == 'Y'"
      >
        저장하고 그룹 지원수강 작성하기
      </p>
      <!-- 그 외 -->
      <p
        class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        @click="registSugang.registSugangGroupAll"
        v-else
      >
        완료하기
      </p>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, watch, ref, computed, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import { useStore } from "vuex";

import GroupClass from "@/lifeeduAdmin/components/RegistGroupClass.vue";
import GroupClassEduPay from "@/lifeeduAdmin/components/RegistGroupClassEduPay.vue";

export default {
  components: {
    GroupClass,
    GroupClassEduPay,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let isApiCallInProgress = false;

    const registSugang = reactive({
      // 홈페이지 정보
      registMainSugangInfo: {
        kname: "", // 한글이름
      },

      // 수강신청 메타 정보
      registSugangInfo: {
        isGroup: "N", //그룹 일반수강 사용여부값
        isGroupSup: "N", //그룹 지원수강 사용여부값
        isOne: "N", //1:1 일반수강 사용여부 값
        isOneSup: "N", //1:1 지원수강 사용여부 값

        startDate: "", // 시작일시
        endDate: "", // 종료일시
        status: "", // 진행 상태 - 예정, 진행중, 종료
      },

      registSugangData: {
        idxLeSugangGroup: 0,
        idxLeSugang: 0,
        classMonth: 0,
        isLevelTest: "",
        isNumberOfClassText: "N",
        isLevelChoiceText: "N",
        numberOfClassText: "",
        levelChoiceText: "",
        isBookBuy: "",
        staffIdx: null,
        sugangGroupTypeList: [],
        sugangSupType: "일반", //일반, 지원 구분하는 값. 기본은 일반
        buttonName: "",
      },

      // registParams: {
      //     isOne: computed(() => (isOneValue.value ? "Y" : "N")),
      //     isGroup: computed(() => (isGroupValue.value ? "Y" : "N")),
      // },
      groupTypeList: [
        {
          groupType: "1:2",
          checked: false,
          studentLimit: 0,
          studentLimitWait: 0,
        },
        {
          groupType: "1:3",
          checked: false,
          studentLimit: 0,
          studentLimitWait: 0,
        },
        {
          groupType: "1:4",
          checked: false,
          studentLimit: 0,
          studentLimitWait: 0,
        },
        {
          groupType: "1:5",
          checked: false,
          studentLimit: 0,
          studentLimitWait: 0,
        },
      ],
      groupLevelList: [
        {
          levelName: "초등(저학년) 입문1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 입문2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 초급1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 초급2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 중급1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 중급2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급1",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급2",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급3",
          checked: false,
        },
        {
          levelName: "초등(저학년) 고급4",
          checked: false,
        },

        {
          levelName: "초등(고학년) 입문1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 입문2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 초급1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 초급2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 중급1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 중급2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급1",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급2",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급3",
          checked: false,
        },
        {
          levelName: "초등(고학년) 고급4",
          checked: false,
        },

        {
          levelName: "중등 입문1",
          checked: false,
        },
        {
          levelName: "중등 입문2",
          checked: false,
        },
        {
          levelName: "중등 초급1",
          checked: false,
        },
        {
          levelName: "중등 초급2",
          checked: false,
        },
        {
          levelName: "중등 중급1",
          checked: false,
        },
        {
          levelName: "중등 중급2",
          checked: false,
        },
        {
          levelName: "중등 고급1",
          checked: false,
        },
        {
          levelName: "중등 고급2",
          checked: false,
        },
        {
          levelName: "중등 고급3",
          checked: false,
        },
        {
          levelName: "중등 고급4",
          checked: false,
        },

        {
          levelName: "고등 입문1",
          checked: false,
        },
        {
          levelName: "고등 입문2",
          checked: false,
        },
        {
          levelName: "고등 초급1",
          checked: false,
        },
        {
          levelName: "고등 초급2",
          checked: false,
        },
        {
          levelName: "고등 중급1",
          checked: false,
        },
        {
          levelName: "고등 중급2",
          checked: false,
        },
        {
          levelName: "고등 고급1",
          checked: false,
        },
        {
          levelName: "고등 고급2",
          checked: false,
        },
        {
          levelName: "고등 고급3",
          checked: false,
        },
        {
          levelName: "고등 고급4",
          checked: false,
        },
      ],
      setEduPayData(val) {
        registSugang.sugangGroupTimeInfoList = val;
      },
      setRegistSugangData(val) {
        registSugang.registSugangData.sugangGroupTypeList = val;
      },

      async getSugang() {
        //route.query.idxSugang 값이 존재하면 값을 가져오고, 아니라면 값을 비운다.
        console.log("getSugang");
        if (registSugang.registSugangData.idxLeSugang > 0) {
          const params = {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
          };
          console.log(params);
          await axios
            .get("/admin/sugang", {
              params: params,
            })
            .then(function (res) {
              if (res.data.success) {
                registSugang.registSugangInfo = { ...registSugang.registSugangInfo, ...res.data.data.sugangInfo };
                registSugang.registMainSugangInfo = res.data.data.siteMainInfo;
                // console.log("sugang", registSugang.registSugangInfo);
              } else {
                alert(res.data.message);
                router.push({ path: "/home" });
              }
            });
        }
      },

      // 1:1 수강 언어 선택 및 저장
      async getGroupClass() {
        //route.query.idxSugang 값이 존재하면 값을 가져오고, 아니라면 값을 비운다.
        console.log("getGroupClass");
        if (registSugang.registSugangData.idxLeSugang > 0) {
          const params = {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
            sugangSupType: registSugang.registSugangData.sugangSupType,
          };
          // console.log(params);
          await axios
            .get("/admin/sugangGroup", {
              params: params,
            })
            .then(function (res) {
              if (res.data.success) {
                registSugang.registSugangData = { ...registSugang.registSugangData, ...res.data.data.pop() };

                registSugang.registSugangData.sugangGroupTypeList.forEach((item, index) => {
                  //그룹 타입별 제한여부를 선택한 값이 없으므로 생성해준다.
                  if (item.studentLimit == 0 && item.studentLimitWait == 0) {
                    item.studentLimitType = "Not Limited";
                  } else {
                    item.studentLimitType = "Total";
                  }
                  // console.log("Index: " + index + " Value: " + item);
                });
                //받아온 그룹 수강신청 목록 데이터를 레벨 리스트를 기초로 재 정렬한다.
                console.log(
                  "registSugang.registSugangData 정렬전",
                  registSugang.registSugangData
                );
                registSugang.registSugangData = registSugang.arrangeGroupTypeList(registSugang.registSugangData);
                registSugang.registSugangData.sugangGroupTypeList = registSugang.arrangeGroupTimeInfoList(registSugang.registSugangData.sugangGroupTypeList);
                registSugang.registSugangData.sugangGroupTypeList = registSugang.arrangeGroupLangList(registSugang.registSugangData.sugangGroupTypeList);
                registSugang.registSugangData.sugangGroupTypeList = registSugang.arrangeGroupLevelList(registSugang.registSugangData.sugangGroupTypeList);
                console.log(
                  "registSugang.registSugangData 정렬후",
                  registSugang.registSugangData
                );
              }
            });
        }
      },
      arrangeGroupTypeList(sugangGroup) {
        if (sugangGroup) {
          let reArrangeTypeList = [];
          registSugang.groupTypeList.forEach((groupType, index) => {
            let tempItem = sugangGroup.sugangGroupTypeList.filter(
              (sugangGroupTypeItem) => sugangGroupTypeItem.groupType == groupType.groupType
            );
            if (tempItem) {
              reArrangeTypeList = [...reArrangeTypeList, ...tempItem];
            }
          });
        }
        return sugangGroup;
      },
      groupTimeInfoList: [],
      arrangeGroupTimeInfoList(sugangGroupTypeList) {
        //registSugang.sugangGroupTypeList[0].sugangGroupTimeInfoList 를 기준으로 다른것들을 모두 정렬한다.
        if(sugangGroupTypeList?.[0]?.sugangGroupTimeInfoList) {
          registSugang.groupTimeInfoList = sugangGroupTypeList?.[0]?.sugangGroupTimeInfoList;
        }
        console.log("registSugang.groupTimeInfoList", registSugang.groupTimeInfoList);
        if (sugangGroupTypeList && registSugang.groupTimeInfoList) {
          sugangGroupTypeList.forEach((sugangGroupTypeItem, indexSugangGroupTypeItem) => {
            let reArrangeTimeInfoList = [];
            registSugang.groupTimeInfoList.forEach((groupTimeInfoItem, index) => {
              let tempItem = sugangGroupTypeItem.sugangGroupTimeInfoList.filter(
                  (sugangGroupTimeInfoItem) => sugangGroupTimeInfoItem.lessonTime == groupTimeInfoItem.lessonTime &&
                  sugangGroupTimeInfoItem.numberOfClass == groupTimeInfoItem.numberOfClass &&
                  sugangGroupTimeInfoItem.totalNumberOfClass == groupTimeInfoItem.totalNumberOfClass &&
                  sugangGroupTimeInfoItem.lecDay == groupTimeInfoItem.lecDay &&
                  sugangGroupTimeInfoItem.lecDayList == groupTimeInfoItem.lecDayList
                );
              if (tempItem) {
                reArrangeTimeInfoList = [...reArrangeTimeInfoList, ...tempItem];
              }
            });
            sugangGroupTypeItem.sugangGroupTimeInfoList = reArrangeTimeInfoList;
          });
        }
        return sugangGroupTypeList;
      },
      languageList: [
        {
          lang: "영어(원어민)",
          checked: false,
        },
        {
          lang: "영어(필리핀)",
          checked: false,
        },
        {
          lang: "중국어",
          checked: false,
        },
        {
          lang: "일본어",
          checked: false,
        },
      ],
      arrangeGroupLangList(sugangGroupTypeList) {
        if (sugangGroupTypeList) {
          sugangGroupTypeList.forEach((sugangGroupTypeItem, indexSugangGroupTypeItem) => {
            sugangGroupTypeItem.sugangGroupTimeInfoList.forEach((sugangGroupTimeInfoItem, indexSugangGroupTimeInfoItem) => {
              let reArrangeLangList = [];
              registSugang.languageList.forEach((language, index) => {
                let tempItem = sugangGroupTimeInfoItem.sugangGroupLangList.filter(
                  (sugangGroupLangItem) => sugangGroupLangItem.language == language.lang
                );
                if (tempItem) {
                  reArrangeLangList = [...reArrangeLangList, ...tempItem];
                }
              });
              sugangGroupTimeInfoItem.sugangGroupLangList = reArrangeLangList;
            });
          });
        }
        return sugangGroupTypeList;
      },
      arrangeGroupLevelList(sugangGroupTypeList) {
        if (sugangGroupTypeList) {
          sugangGroupTypeList.forEach((sugangGroupTypeItem, indexSugangGroupTypeItem) => {
            sugangGroupTypeItem.sugangGroupTimeInfoList.forEach(
              (sugangGroupTimeInfoItem, indexSugangGroupTimeInfoItem) => {
                sugangGroupTimeInfoItem.sugangGroupLangList.forEach((sugangGroupLangItem, indexSugangGroupLangItem) => {
                  let reArrangeLevelList = [];
                  registSugang.groupLevelList.forEach((groupLevel, index) => {
                    //sugangGroupLangItem.sugangGroupLevelList 에서 groupLevel.levelName 이 포함되어 있는 경우, 해당 레벨을 찾아서 재정렬 리스트에 추가한다.
                    let tempItem = sugangGroupLangItem.sugangGroupLevelList.filter(
                      (sugangGroupLevelItem) => sugangGroupLevelItem.levelName == groupLevel.levelName
                    );
                    if (tempItem) {
                      reArrangeLevelList = [...reArrangeLevelList, ...tempItem];
                    }
                  });
                  sugangGroupLangItem.sugangGroupLevelList = reArrangeLevelList;
                });
              }
            );
          });
        }
        return sugangGroupTypeList;
      },

      // 수강신청
      async registSugangGroupClass() {
        if (isApiCallInProgress) {
          alert("처리중 입니다. data가 많을 경우 오랜 시간이 걸릴수 있습니다.");
          return;
        }
        isApiCallInProgress = true;
        const params = {
          idxLeSugang: registSugang.registSugangData.idxLeSugang,
          sugangSupType: registSugang.registSugangData.sugangSupType,
          sugangGroupTypeList: registSugang.registSugangData.sugangGroupTypeList,
          buttonName: "",
        };
        try {
          const res = await axios.post("/admin/sugangGroup", params, {});
          if (res.data.success) {
            alert("수정이 완료되었습니다.");
            registSugang.getSugang();
            // registSugang.getGroupClass();
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          alert("API 통신 중 오류가 발생했습니다.");
        } finally {
          isApiCallInProgress = false;
        }
        // listPage.is_loader = false;
      },

      //   수강신청
      async registSugangGroupAll() {
        if (isApiCallInProgress) {
          alert("처리중 입니다. data가 많을 경우 오랜 시간이 걸릴수 있습니다.");
          return;
        }
        isApiCallInProgress = true;
        const params = registSugang.registSugangData;
        try {
          const res = await axios.post("/admin/sugangGroupAll", params, {});
          if (res.data.success) {
            //일반과 그룹이 함께 존재하는 경우, 일반을 우선 저장하도록 유도해야 오류가 적을 수 있다.
            //일반을 저장하는 경우, 지원이 존재하면 지원으로 이동된다.
            if (registSugang.registSugangData.sugangSupType == "일반" && registSugang.registSugangInfo.isGroupSup == "Y") {
              registSugang.setSugangSupType("지원");
            } else {
              alert("수정이 완료되었습니다.");
              router.push({ path: "/home" });
            }
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          alert("API 통신 중 오류가 발생했습니다.");
        } finally {
          isApiCallInProgress = false;
        }
      },

      setSugangSupType(val) {
        // console.log("setSugangSupType :", val);
        router.push({
          name: "enrolmentStep3_2",
          query: {
            idxLeSugang: registSugang.registSugangData.idxLeSugang,
            sugangSupType: val,
          },
        });
      },

      goBack() {
        //이전페이지의 동작은 무조건 histroy.back 로 한다.
        router.go(-1);
      },

      // 수강신청 홈페이지 정보
      getSugangHomepageInfo() {
        return this.registMainSugangInfo;
      },

      // 회사명
      getCompanyKoreanName() {
        const { kname } = this.getSugangHomepageInfo();
        return kname;
      },

      // 수강신청 정보
      getSugangInfo() {
        return this.registSugangInfo;
      },

      // 과정명
      getSugangName() {
        const { sugangName } = this.getSugangInfo();
        return sugangName;
      },

      // 수강신청기간 시작일시 표시 메서드
      getDisplayStartDate() {
        const { startDate } = this.getSugangInfo();

        if (!startDate) return;

        const [date, time] = startDate.split(" ");
        const [hour, minute] = time.split(":");
        return this.formatDate(date, hour, minute);
      },

      // 수강신청기간 종료일시 표시 메서드
      getDisplayEndDate() {
        const { endDate } = this.getSugangInfo();

        if (!endDate) return;

        const [date, time] = endDate.split(" ");
        const [hour, minute] = time.split(":");
        return this.formatDate(date, hour, minute);
      },

      formatDate(date, hour, minute) {
        return `${date} ${hour}:${minute}`;
      },

      // 수강신청 진행상태
      getStatus() {
        const { status } = this.getSugangInfo();

        if (!status) return "";
        return status;
      },
    });

    onMounted(() => {
      if (route.query.idxLeSugang) {
        registSugang.registSugangData.idxLeSugang = Number(route.query.idxLeSugang);
        registSugang.registSugangData.sugangSupType = "일반";
        if (route.query.sugangSupType && route.query.sugangSupType == "지원") {
          registSugang.registSugangData.sugangSupType = "지원";
        }

        registSugang.getSugang();
        registSugang.getGroupClass();
      } else {
        alert("잘못된 접근입니다.");
        router.push({ path: "/home" });
      }
    });

    return {
      registSugang,
      isApiCallInProgress,
    };
  },
};
</script>
<style></style>
