export default class BaseClassRoomListObject {
  constructor() {
    this.idxLeSugangGroupClass = 0;
    this.idxLeSugangGroupClassRoom = 0;
    this.idxLeSugangGroupTimeInfo = "";
    this.roomSize = 0;
    this.time = "";
    this.startTime = "";
    this.endTime = "";
    this.staffIdx = 0;
  }
}
