export default class BaseTypeListObject {
  constructor() {
    this.idxLeSugangGroupType = 0;
    this.idxLeSugangGroup = 0;
    this.groupType = "";
    this.studentLimitType = "Not Limited";
    this.studentLimit = 0;
    this.studentLimitWait = 0;
    this.sugangGroupTimeInfoList = new Array();
    this.staffIdx = 0;
  }
}
