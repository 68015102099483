export default class BaseLangListObject {
  constructor() {
    this.idxLeSugangGroupLang = 0;
    this.idxLeSugangGroupTimeInfo = 0;
    this.idxLeSugangGroupType = 0;
    this.language = "";
    this.sugangGroupLevelList = new Array();
    this.staffIdx = 0;
  }
}
