const groupTypeList = [
  {
    groupType: "1:2",
    checked: false,
    studentLimitType: "Not Limited",
    studentLimit: 0,
    studentLimitWait: 0,
    isGroupCode: "",
  },
  {
    groupType: "1:3",
    checked: false,
    studentLimitType: "Not Limited",
    studentLimit: 0,
    studentLimitWait: 0,
    isGroupCode: "",
  },
  {
    groupType: "1:4",
    checked: false,
    studentLimitType: "Not Limited",
    studentLimit: 0,
    studentLimitWait: 0,
    isGroupCode: "",
  },
  {
    groupType: "1:5",
    checked: false,
    studentLimitType: "Not Limited",
    studentLimit: 0,
    studentLimitWait: 0,
    isGroupCode: "",
  },
];
const languageList = [
  {
    lang: "영어(원어민)",
    checked: false,
  },
  {
    lang: "영어(필리핀)",
    checked: false,
  },
  {
    lang: "중국어",
    checked: false,
  },
  {
    lang: "일본어",
    checked: false,
  },
];
const levelList = [
  {
    levelName: "초등(저학년) 입문1",
    checked: false,
  },
  {
    levelName: "초등(저학년) 입문2",
    checked: false,
  },
  {
    levelName: "초등(저학년) 초급1",
    checked: false,
  },
  {
    levelName: "초등(저학년) 초급2",
    checked: false,
  },
  {
    levelName: "초등(저학년) 중급1",
    checked: false,
  },
  {
    levelName: "초등(저학년) 중급2",
    checked: false,
  },
  {
    levelName: "초등(저학년) 고급1",
    checked: false,
  },
  {
    levelName: "초등(저학년) 고급2",
    checked: false,
  },
  {
    levelName: "초등(저학년) 고급3",
    checked: false,
  },
  {
    levelName: "초등(저학년) 고급4",
    checked: false,
  },

  {
    levelName: "초등(고학년) 입문1",
    checked: false,
  },
  {
    levelName: "초등(고학년) 입문2",
    checked: false,
  },
  {
    levelName: "초등(고학년) 초급1",
    checked: false,
  },
  {
    levelName: "초등(고학년) 초급2",
    checked: false,
  },
  {
    levelName: "초등(고학년) 중급1",
    checked: false,
  },
  {
    levelName: "초등(고학년) 중급2",
    checked: false,
  },
  {
    levelName: "초등(고학년) 고급1",
    checked: false,
  },
  {
    levelName: "초등(고학년) 고급2",
    checked: false,
  },
  {
    levelName: "초등(고학년) 고급3",
    checked: false,
  },
  {
    levelName: "초등(고학년) 고급4",
    checked: false,
  },

  {
    levelName: "중등 입문1",
    checked: false,
  },
  {
    levelName: "중등 입문2",
    checked: false,
  },
  {
    levelName: "중등 초급1",
    checked: false,
  },
  {
    levelName: "중등 초급2",
    checked: false,
  },
  {
    levelName: "중등 중급1",
    checked: false,
  },
  {
    levelName: "중등 중급2",
    checked: false,
  },
  {
    levelName: "중등 고급1",
    checked: false,
  },
  {
    levelName: "중등 고급2",
    checked: false,
  },
  {
    levelName: "중등 고급3",
    checked: false,
  },
  {
    levelName: "중등 고급4",
    checked: false,
  },

  {
    levelName: "고등 입문1",
    checked: false,
  },
  {
    levelName: "고등 입문2",
    checked: false,
  },
  {
    levelName: "고등 초급1",
    checked: false,
  },
  {
    levelName: "고등 초급2",
    checked: false,
  },
  {
    levelName: "고등 중급1",
    checked: false,
  },
  {
    levelName: "고등 중급2",
    checked: false,
  },
  {
    levelName: "고등 고급1",
    checked: false,
  },
  {
    levelName: "고등 고급2",
    checked: false,
  },
  {
    levelName: "고등 고급3",
    checked: false,
  },
  {
    levelName: "고등 고급4",
    checked: false,
  },
];
const lecDayList = [
  {
    label: "월",
    checked: false,
  },
  {
    label: "화",
    checked: false,
  },
  {
    label: "수",
    checked: false,
  },
  {
    label: "목",
    checked: false,
  },
  {
    label: "금",
    checked: false,
  },
  {
    label: "토",
    checked: false,
  },
  {
    label: "일",
    checked: false,
  },
];
export { groupTypeList, languageList, levelList, lecDayList };
