<template>
  <div class="mb-5">
    <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">수업 횟수 / 시간</p>
    <table class="mb-2">
      <thead>
        <tr>
          <th>
            <span class="font-bold">*</span>
            수업 횟수
          </th>
          <th>
            <span class="font-bold">*</span>
            요일
          </th>
          <th>
            <span class="font-bold">*</span>
            총 수업 횟수
          </th>
          <th>
            <span class="font-bold">*</span>
            수업 시간(분)
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in registSugang.sugangOneTimeInfo" :key="index">
          <td>
            <div class="flex items-center justify-center gap-2">
              <p>주</p>
              <select
                v-model="registSugang.sugangOneTimeInfo[index].numberOfClass"
                class="border border-slate-800 border-solid rounded w-full max-w-[100px]"
                @change="registSugang.changeDefaultLecDayList(registSugang.sugangOneTimeInfo[index])"
              >
                <option value="0" selected>선택</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="5">5</option>
              </select>
              <p>회</p>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-center gap-2">
              <ul class="flex gap-4 flex-wrap">
                <li
                    v-for="(item, lecDayIndex) in registSugang.sugangOneTimeInfo[index].lecDayList"
                    :key="lecDayIndex"
                    class="flex items-center gap-1"
                >
                    <input
                        type="checkbox"
                        :id="'chk1_' + index + '_' + lecDayIndex"
                        v-model="item.checked"
                        @change="registSugang.validationLecDay(sugangOneTimeInfo[index], item)"
                    />
                    <label :for="'chk1_' + index + '_' + lecDayIndex">{{ item.label }}</label>
                </li>
              </ul>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-center gap-2">
              <p>총</p>
              <input
                type="text"
                class="border border-slate-800 border-solid rounded w-full max-w-[50px]"
                v-model="registSugang.sugangOneTimeInfo[index].totalNumberOfClass"
              />
              <p>회</p>
            </div>
          </td>
          <td>
            <input
              type="text"
              class="border border-solid rounded border-slate-800"
              v-model="registSugang.sugangOneTimeInfo[index].lessonTime"
            />
          </td>
          <td>
            <p
              class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800"
              @click="registSugang.deleteRows(index)"
            >
              삭제
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-end">
      <p class="p-2 border border-solid rounded cursor-pointer text-slate-800 border-slate-800" @click="registSugang.addRows">
        추가입력
      </p>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed, watch, watchEffect } from "vue";

class BaseOneTimeObject {
  constructor() {
    this.idxLeSugangOneTimeInfo = 0;
    this.idxLeSugang = 0;
    this.numberOfClass = "";
    this.lecDayList = new Array(
        {
          label: "월",
          checked: false
        },
        {
          label: "화",
          checked: false
        },
        {
          label: "수",
          checked: false
        },
        {
          label: "목",
          checked: false
        },
        {
          label: "금",
          checked: false
        },
        {
          label: "토",
          checked: false
        },
        {
          label: "일",
          checked: false
        },
    )
    this.lessonTime = "";
    this.totalNumberOfClass = "";
    this.staffIdx = 0;
  }
}

export default {
  name: "RegistOneClassEduPay",
  components: {},
  props: {
    sugangOneTimeInfo: Array,
  },
  setup(props, { emit }) {
    const registSugang = reactive({
      // levelList: ["입문1", "입문2", "초급1", "초급2", "중급1", "중급2", "고급"],
      // checkedLanguageList: [],
      // directInput1: "",
      // directInput2: "",
      // directInput3: "",
      // directInputChecked1: false,
      // directInputChecked2: false,
      // directInputChecked3: false,
      // directInputListIdx1: 0,
      // directInputListIdx2: 0,
      // directInputListIdx3: 0,
      // currentLanguage: -1,
      // currentClassListIdx: -1,

      sugangOneTimeInfo: props.sugangOneTimeInfo,
      
      addRows: () => {
        let bo = new BaseOneTimeObject();
        console.log(registSugang.sugangOneTimeInfo);
        if (registSugang.sugangOneTimeInfo == undefined) registSugang.sugangOneTimeInfo = [];
        registSugang.sugangOneTimeInfo.push(bo);
        return registSugang.sugangOneTimeInfo.length;
      },
      deleteRows: (index) => {
        if (index > -1) {
          registSugang.sugangOneTimeInfo.splice(index, 1);
        }
      },

      //요일 설정 정보 기본값 설정 메서드
      changeDefaultLecDayList(oneTimeInfo) {
        const { numberOfClass, lecDayList } = oneTimeInfo
        
        // 체크된 요일 정보 초기화
        lecDayList.forEach(lecDay => lecDay.checked = false) 

        switch(String(numberOfClass)) {
          // 선택하지 않음
          case "0":
            break;
          // 주 2회는 화, 목
          case "2":
            this.checkedLecDay(lecDayList, 1)
            this.checkedLecDay(lecDayList, 3)
            break;
          // 주 3회는 월, 수, 금
          case "3":
            this.checkedLecDay(lecDayList, 0)
            this.checkedLecDay(lecDayList, 2)
            this.checkedLecDay(lecDayList, 4)
            break;
          // 주 3회는 월, 화, 수, 목, 금
          case "5":
            this.checkedLecDay(lecDayList, 0)
            this.checkedLecDay(lecDayList, 1)
            this.checkedLecDay(lecDayList, 2)
            this.checkedLecDay(lecDayList, 3)
            this.checkedLecDay(lecDayList, 4)
            break;
        }
      },

      // 요일 선택 
      checkedLecDay(lecDayList, weekOfDay) {
          lecDayList[weekOfDay].checked = true
      },

      // 요일 설정 정보의 유효성 검증 메서드
      validationLecDay(oneTimeInfo, lecDay) {
        const { numberOfClass, lecDayList } = oneTimeInfo

        const currentCheckedLecDayCount = lecDayList.filter(lecDay => lecDay.checked).length
        
        if (currentCheckedLecDayCount > numberOfClass) {
          // 수업 횟수 주 N회를 초과할 수 없음
          lecDay.checked = false
        }
      }
    });

    onMounted(() => {
      registSugang.addRows();
    });

    watch(
      () => props.sugangOneTimeInfo,
      (cur, prev) => {
        
        cur.forEach(oneTimeInfo => {
          const bo = new BaseOneTimeObject()

          const { lecDayList } = oneTimeInfo
          if (!lecDayList) {
            // 기존 규격에 요일 설정 정보 목록이 없는 경우 기본값 생성
            oneTimeInfo.lecDayList = bo.lecDayList

            registSugang.changeDefaultLecDayList(oneTimeInfo)
          } else {
            // 요일 설정 정보 목록이 있는 경우
            lecDayList.forEach((lecDay, index) => {
              // 요일 이름 설정
              lecDay.label = bo.lecDayList[index].label
            })
          }
        })


        registSugang.sugangOneTimeInfo = cur;
      }
    );

    watch(
      () => registSugang.sugangOneTimeInfo,
      (cur, prev) => {
        console.log(cur);
        // 이벤트를 통해 부모로 데이터셋을 전달.
        emit("set-edupay-data", cur); // 일단 주석처리.
      }
    );

    return {
      registSugang
    };
  },
};
</script>

<style></style>
