<template>
  <div class="p-4">
    <div class="p-4">
      <div class="p-2 flex gap-2">
        <div
          @click="regist.handleComon()"
          :class="{
            'bg-slate-800 text-white border border-solid border-slate-800': regist.is_show_common,
          }"
          class="p-2 rounded cursor-pointer text-slate-800 border border-solid border-slate-800"
        >
          일반
        </div>
        <div
          v-if="regist.isGroupSup == 'Y'"
          @click="regist.handleSupport()"
          :class="{
            'bg-slate-800 text-white border border-solid border-slate-800': regist.is_show_support,
          }"
          class="p-2 rounded cursor-pointer text-slate-800 border border-solid border-slate-800"
        >
          지원
        </div>
      </div>
    </div>
    <!-- 안내문구 기입 -->
    <!-- <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">평생교육 홈페이지 관리자 안내</p>
      <ul class="flex flex-col gap-2">
        <li>- <span class="text-red-500">[Compnay] </span>클릭 시, 홈페이지 수정페이지로 이동합니다.</li>
      </ul>
    </div> -->
    <div class="p-2 mb-5 rounded shadow-md">
      <div class="flex justify-between">
        <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">그룹 유형</p>
        <p class="p-2 text-red-600 border border-red-600 border-solid rounded cursor-pointer" @click="regist.handleLevelCheck()">
          신청 레벨 확인
        </p>
      </div>
      <ul class="flex gap-2">
        <li
          v-for="groupTypeInfo in regist.sugangInfo.sugangGroupTypeList"
          :key="groupTypeInfo.idxLeSugangGroupType"
          class="flex gap-2 p-2 cursor-pointer text-slate-800 border border-solid border-slate-800"
          :class="{
            'bg-slate-800' : regist.selectedGroupType === groupTypeInfo.groupType,
            'text-white': regist.selectedGroupType === groupTypeInfo.groupType
          }"
          @click="regist.handleSugangLang(groupTypeInfo)"
        >
          {{ groupTypeInfo.groupType }}
        </li>
      </ul>
    </div>

    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">시간</p>
      <ul class="flex gap-2">
        <li
          v-for="groupTimeInfo in regist.sugangInfo.sugangGroupTimeInfoList"
          :key="groupTimeInfo.idxLeSugangGroupTimeInfo"
          class="p-2 cursor-pointer text-slate-800 border border-solid border-slate-800"
          :class="{
            'bg-slate-800' : regist.selectedGroupTimeValue === `${groupTimeInfo.numberOfClass}-${groupTimeInfo.lessonTime}`,
            'text-white': regist.selectedGroupTimeValue === `${groupTimeInfo.numberOfClass}-${groupTimeInfo.lessonTime}`
          }"
          @click="regist.updateTime(groupTimeInfo)"
        >
          주{{ groupTimeInfo.numberOfClass }}회 {{ groupTimeInfo.lessonTime }}분
        </li>
      </ul>
    </div>

    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">언어</p>
      <ul class="flex gap-2">
        <li
          v-for="languageInfo in regist.selectedLangList"
          :key="languageInfo.idxLeSugangGroupLang"
          class="p-2 cursor-pointer text-slate-800 border border-solid border-slate-800"
          :class="{
            'bg-slate-800' : regist.selectedGroupTimeLanguage === languageInfo.language,
            'text-white': regist.selectedGroupTimeLanguage === languageInfo.language
          }"
          @click="regist.updateLanguageLevels(languageInfo)"
        >
          {{ languageInfo.language }}
        </li>
      </ul>
    </div>

    <div class="p-2 mb-5 rounded shadow-md">
      <p class="pb-2 mb-2 text-lg font-semibold border-b-2 border-solid border-slate-800">레벨</p>
      <ul class="flex gap-2 overflow-auto">
        <li
          v-for="levelInfo in regist.selectedLanguageLevels"
          :key="levelInfo.idxLeSugangGroupLevel"
          class="p-2 cursor-pointer text-slate-800 border border-solid border-slate-800"
          :class="{
            'bg-slate-800' : regist.selectedGroupTimeLanguageLevel === levelInfo.levelName,
            'text-white': regist.selectedGroupTimeLanguageLevel === levelInfo.levelName
          }"
          @click="regist.sugangGroupDetailRoom(levelInfo)"
        >
          {{ levelInfo.levelName }}
        </li>
      </ul>
    </div>

    <!-- 그룹 시간 테이블 -->
    <div class="p-2 mb-5 rounded shadow-md h-[500px]">

      <div class="table-group-class-tbody overflow-x-auto">
        <table>
          <tbody v-if="regist.sugangGroupDetailRoomClassList.length > 0">
            <tr>
              <!-- 과정/시간대 헤더 -->
              <th class="p-2 bg-slate-800 text-white sticky-col" rowspan="2">
                과정/시간대
              </th>
              <!-- 시간대 헤더 -->
              <th
                v-for="(timeInfo, index) in regist.sugangGroupDetailRoomClassList[0]?.timeList"
                :key="'time-header-' + index"
                class="p-2 bg-slate-800 text-white"
                :colspan="regist.maxTimeInfoListLength * 2"
              >
              {{ timeInfo.time }} ~ {{ regist.calculateEndTime(timeInfo.time) }}
              </th>
            </tr>
            <tr>
              <!-- 주간 시간 헤더 -->
              <th
                v-for="(weekInfo, index) in regist.getAllWeekTimes()"
                :key="'week-header-' + index"
                colspan="2"
              >
                {{ weekInfo }}
              </th>
            </tr>
            <tr v-for="classInfo in regist.sugangGroupDetailRoomClassList" :key="classInfo.className">
              <td class="sticky-col">{{ classInfo.className }}</td>
              <template v-for="(timeInfo, timeIndex) in classInfo.timeList" :key="timeIndex">
                <template v-for="(weekInfo, weekIndex) in timeInfo.timeInfoList" :key="weekIndex">
                  <td colspan="2">
                    <ul>
                      <li class="flex flex-col gap-1">
                        <p class="p-1 rounded text-white bg-[#49cc90] cursor-pointer"
                           @click="regist.goToEnrolmentClosed(classInfo, timeInfo.time, weekInfo)"
                        >
                          총 {{ weekInfo.totalCount }}개
                        </p>
                        <p class="p-2 text-red-700 border border-red-700 border-solid rounded">
                          마감 {{ weekInfo.closeCount }}개
                        </p>
                        <p class="p-2 text-blue-300 border-2 border-blue-300 border-solid rounded">
                          신청가능 {{ weekInfo.openCount }}개
                        </p>
                      </li>
                    </ul>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

  <!-- 신청 레벨 확인 팝업 -->
  <div v-if="regist.is_ShowLevelPop">
    <div class="fixed inset-0 w-full h-full bg-black/80 z-[10] overflow-y-auto">
      <div class="flex flex-col justify-center items-center p-5 w-[900px] mx-auto">
        <div class="w-full flex fl justify-end p-2 bg-white">
          <p class="cursor-pointer text-xl font-bold underline" @click="regist.handleCloseLevelCheck()">닫기</p>
        </div>
        <img src="@/lifeeduAdmin/assets/images/levelcheck.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { idxLeSugang } = route.query

    const regist = reactive({
      is_ShowLevelPop: false,

      isGroupSup: "",
      is_show_common: true,
      is_show_support: false,

      sugangInfo: {
        sugangGroupTypeList: [],
        sugangGroupTimeInfoList: [],
        sugangGroupLangList: [],
        sugangGroupLevelList: [],
        selectedLangList: [],
      },

      sugangSupType: "",


      selectedGroupType: "", // 선택된 그룹 유형
      selectedGroupTime: "" , // 선택된 시간
      selectedGroupTimeValue: "" , // 선택된 시간 값
      selectedGroupTimeLanguage: "", // 선택된 언어
      
      selectedGroupTimeLanguageIdxLeSugangGroupLevel: 0, // 선택된 레벨 그룹 인덱스
      selectedGroupTimeLanguageLevel: "", // 선택된 레벨

      maxTimeInfoListLength: 0,
      sugangGroupDetailRoomClassList: [],

      // 수강 홈페이지 조회
      // getSugangHomePage() {
      //   const params = {
      //     idxLeSugang: route.query.idxLeSugang,
      //     sugangSupType: regist.sugangSupType,
      //   };

      //   axios.get(`/admin/sugangGroupDetail`, { params }).then(function (res) {
      //     if (res.data.success) {
      //       if (regist.sugangSupType == "일반") {
      //         regist.sugangInfo = res.data.data[0];
      //       } else {
      //         regist.sugangInfo = res.data.data[1];
      //       }
      //     } else {
      //       // 처리 실패 시의 동작
      //     }
      //   });
      // },

      getSugangHomePage() {
        const params = {
          idxLeSugang: route.query.idxLeSugang,
          sugangSupType: regist.sugangSupType,
        };

        axios
          .get(`/admin/sugangGroupDetail`, { params })
          .then(function (res) {
            if (res.data.success) {
              if (res.data.data.length > 0) {
                // 데이터가 있는지 확인
                if (regist.sugangSupType === "일반" || regist.sugangSupType === "지원") {
                  regist.sugangInfo = res.data.data[0];
                }
              } else {
                // 데이터가 없는 경우 처리
                alert("등록된 수업이 없습니다.");
              }
            } else {
              // 처리 실패 시의 동작
            }
          })
          .catch(function (error) {
            console.error("Error fetching data:", error);
            // 에러 처리
          });
      },

      getSugang() {
        const idxLeSugang = route.query.idxLeSugang;

        if (idxLeSugang && idxLeSugang !== "0") {
          const params = {
            idxLeSugang: idxLeSugang,
          };
          axios.get("/admin/sugang", { params }).then(function (res) {
            if (res.data.success) {
              regist.isGroupSup = res.data.data.sugangInfo.isGroupSup;
            } else {
              // 처리 실패 로직
            }
          });
        }
      },

      handleLevelCheck() {
        regist.is_ShowLevelPop = true;
      },

      handleCloseLevelCheck() {
        regist.is_ShowLevelPop = false;
      },

      handleSugangLang(groupTypeInfo) {
        const { idxLeSugangGroupType, groupType } = groupTypeInfo 

        // 선택된 그룹 유형 설정
        this.selectedGroupType = groupType

        console.log("idxLeSugangGroupType:", idxLeSugangGroupType);
        const selectedGroup = regist.sugangInfo.sugangGroupTypeList.find(
          (group) => group.idxLeSugangGroupType === idxLeSugangGroupType
        );
        if (selectedGroup) {
          regist.sugangInfo.sugangGroupTimeInfoList = selectedGroup.sugangGroupTimeInfoList;
        }
      },

      updateTime(groupTimeInfo) {
        const { idxLeSugangGroupTimeInfo, numberOfClass, lessonTime } = groupTimeInfo

        // 선택된 시간 설정
        regist.selectedGroupTime = `주${numberOfClass}회 ${lessonTime}분`
        regist.selectedGroupTimeValue = `${numberOfClass}-${lessonTime}`

        // 선택된 시간에 해당하는 언어 목록을 찾기
        const selectedTime = regist.sugangInfo.sugangGroupTimeInfoList.find(
          (time) => time.idxLeSugangGroupTimeInfo === idxLeSugangGroupTimeInfo
        );
        // 언어 목록이 존재하면
        if (selectedTime && selectedTime.sugangGroupLangList) {
          // 선택된 언어 목록을 설정
          regist.selectedLangList = selectedTime.sugangGroupLangList;
        } else {
          // 선택된 시간에 해당하는 언어 목록이 없으면 빈 배열로 설정
          regist.selectedLangList = [];
        }
      },

      updateLanguageLevels(languageInfo) {
        const { idxLeSugangGroupLang, language } = languageInfo

        // 선택된 언어 설정
        regist.selectedGroupTimeLanguage = language

        // 선택된 언어에 해당하는 레벨 목록을 찾기
        const selectedLanguage = regist.selectedLangList.find((lang) => lang.idxLeSugangGroupLang === idxLeSugangGroupLang);
        // 레벨 목록이 존재하면
        if (selectedLanguage && selectedLanguage.sugangGroupLevelList) {
          // 선택된 레벨 목록을 설정
          regist.selectedLanguageLevels = selectedLanguage.sugangGroupLevelList;
        } else {
          // 선택된 언어에 해당하는 레벨 목록이 없으면 빈 배열로 설정
          regist.selectedLanguageLevels = [];
        }
      },

      // 그룹 룸 상세 종료 시간 계산
      calculateEndTime(startTime) {
        const [hours, minutes] = startTime.split(":").map(Number);
        const newMinutes = (minutes + 30) % 60;
        let newHours = hours + Math.floor((minutes + 30) / 60);
        if (newHours === 24) newHours = 0;
        return `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`;
      },

      // 주간 시간 리스트 가져오기
      getAllWeekTimes() {
        return regist.sugangGroupDetailRoomClassList[0]?.timeList.map((timeInfo) => timeInfo.timeInfoList.map((weekInfo) => weekInfo.weekTime)).flat() || [];
      },

      // 그룹 룸 상세 조회
      sugangGroupDetailRoom(levelInfo) {
        const { idxLeSugangGroupLevel, levelName } = levelInfo

        // 선택된 레벨 설정
        regist.selectedGroupTimeLanguageIdxLeSugangGroupLevel = idxLeSugangGroupLevel
        regist.selectedGroupTimeLanguageLevel = levelName

        const params = {
          idxLeSugangGroupLevel: idxLeSugangGroupLevel,
        };

        console.log("idxLeSugangGroupLevel", idxLeSugangGroupLevel);

        axios.get(`/admin/sugangGroupDetailRoom`, { params }).then(function (res) {
          if (res.data.success) {
            const data = res.data.data.sugangGroupClassList;
            
            // 최대 TimeInfoList 길이 설정
            regist.maxTimeInfoListLength = Math.max(
              ...data.map((classInfo) => classInfo.timeList.map((timeInfo) => timeInfo.timeInfoList.length)).flat()
            )

            // 그룹 상세 방 정보 매핑
            regist.sugangGroupDetailRoomClassList = data
          }
        });
      },

      // 그룹 수강신청 마감 페이지 이동
      goToEnrolmentClosed(classInfo, startTime, weekInfo) {
          const { idxLeSugangGroupClass, className } = classInfo
          const timeInfo = `${startTime} ~ ${regist.calculateEndTime(startTime)}`
          const { weekTime } = weekInfo
          const { sugangSupType, selectedGroupType, selectedGroupTime, selectedGroupTimeLanguage, selectedGroupTimeLanguageIdxLeSugangGroupLevel, selectedGroupTimeLanguageLevel } = regist

          // 페이지 이동 파라미터 설정
          const params = new URLSearchParams()
          params.append('idxLeSugang', idxLeSugang)
          params.append('idxLeSugangGroupClass', idxLeSugangGroupClass)
          params.append('className', className)
          params.append('startTime', startTime)
          params.append('subType', sugangSupType)
          params.append('groupType', selectedGroupType)
          params.append('lessonTime', selectedGroupTime)
          params.append('language', selectedGroupTimeLanguage)
          params.append('idxLeSugangGroupLevel', selectedGroupTimeLanguageIdxLeSugangGroupLevel)
          params.append('level', selectedGroupTimeLanguageLevel)
          params.append('timeInfo', timeInfo)
          params.append('weekTime', weekTime)

          window.open(
            `${window.location.origin}/enrolmentClosed?${params.toString()}`,
            "_blank"
          )

      },

      handleComon() {
        regist.is_show_common = true;
        regist.is_show_support = false;
        regist.sugangSupType = "일반";
        // regist.registSugangData.sugangOneLangList = registSugang.sugangOneLangListCommon;
        // registSugang.registSugangData.sugangOneTimeInfoList = registSugang.sugangOneTimeInfoListCommon;
        regist.getSugangHomePage();
      },

      handleSupport() {
        regist.is_show_common = false;
        regist.is_show_support = true;
        regist.sugangSupType = "지원";
        // registSugang.registSugangData.sugangOneLangList = registSugang.sugangOneLangListSupport;
        // registSugang.registSugangData.sugangOneTimeInfoList = registSugang.sugangOneTimeInfoListSupport;
        regist.getSugangHomePage();
      },
    });

    onMounted(() => {
      regist.getSugang();
    });

    return {
      regist,
    };
  },
};
</script>
<style>
/** 좌측 첫번째 열 헤더 */
.table-group-class-tbody tbody th.sticky-col {
  position: sticky;
  z-index: 2;
  left: 0;
}
/** 좌측 첫번째 열 헤더 */
.table-group-class-tbody tbody td.sticky-col {
  position: sticky;
  z-index: 2;
  left: 0;
  background-color: #fff;
}
</style>
