<template>
  <div>
    <div class="hidden pc:block bg-wrapper">
      <div class="flex items-center justify-center bg py-[76px] max-w-[1920px] mx-auto">
        <p class="text-[40px] font-bold text-neutral-950">주니어 과정</p>
      </div>
    </div>

    <section class="pt-[55px] pc:pt-[140px]">
      <img
        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/curriculum.jpg"
        alt="icon"
        class="max-w-[1300px] mx-auto mb-[55px] pc:mb-[140px]"
      />

      <div class="max-w-[1300px] mx-auto overflow-x-auto">
        <div class="w-[1300px] flex items-center justify-between">
          <ul class="flex">
            <li
              @click="changeTab('phonics')"
              :class="{
                'text-black font-medium bg-[#F4F7FF] border-[#7664CA] z-[1]': tab === 'phonics',
                ' border-white': tab !== 'phonics',
              }"
              class="px-[46px] py-7 text-xl text-[#888888] font-light border-t-4 border-solid rounded-t-[20px] overflow-hidden cursor-pointer"
            >
              일반회화(파닉스)
            </li>
            <li
              @click="changeTab('itsMe')"
              :class="{
                'text-black font-medium bg-[#F4F7FF] border-[#7664CA] z-[1]': tab === 'itsMe',
                ' border-white': tab !== 'itsMe',
              }"
              class="px-[46px] py-7 text-xl text-[#888888] font-light border-t-4 border-solid rounded-t-[20px] overflow-hidden cursor-pointer"
            >
              일반회화(It's Me)
            </li>
            <li
              @click="changeTab('junior')"
              :class="{
                'text-black font-medium bg-[#F4F7FF] border-[#7664CA] z-[1]': tab === 'junior',
                ' border-white': tab !== 'junior',
              }"
              class="px-[46px] py-7 text-xl text-[#888888] font-light border-t-4 border-solid rounded-t-[20px] overflow-hidden cursor-pointer"
            >
              일반회화(주니어 고급)
            </li>
            <li
              @click="changeTab('debate')"
              :class="{
                'text-black font-medium bg-[#F4F7FF] border-[#7664CA] z-[1]': tab === 'debate',
                ' border-white': tab !== 'debate',
              }"
              class="px-[46px] py-7 text-xl text-[#888888] font-light border-t-4 border-solid rounded-t-[20px] overflow-hidden cursor-pointer"
            >
              스킬심화
            </li>
          </ul>
          <p @click="showModal = true" class="text-lg font-medium pb-1.5 border-b border-solid border-[#D3D3D3] cursor-pointer">
            레벨별 커리큘럼 확인하기 +
          </p>
        </div>
      </div>
      <div class="bg-[#F4F7FF] pt-[66px] pb-[70px] pc:pb-[140px] shadow-[0_0_10px_rgba(0,0,0,0.1)] overflow-x-auto">
        <div class="w-[1300px] mx-auto rounded-[20px] shadow-[0_0_15px_rgba(0,0,0,0.09)] overflow-hidden">
          <div class="h-[600px] overflow-y-auto scroll-bar">
            <table class="relative">
              <thead class="sticky top-0 bg-[#7664CA]">
                <tr>
                  <th class="w-[290px] py-5 text-lg font-medium text-white">제목</th>
                  <th class="w-[260px] py-5 text-lg font-medium text-white">추천 레벨 및 연령</th>
                  <th class="py-5 text-lg font-medium text-white">학습 목표</th>
                  <th class="w-[200px] py-5 text-lg font-medium text-white">교재 미리보기</th>
                </tr>
              </thead>

              <tbody v-show="tab === 'phonics'">
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/phonics/1-1.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">It's Me Phonic 1</p>
                    </div>
                  </td>
                  <td rowspan="4" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•입문단계</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•유치부 - 초등 2학년</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="4" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•영어를 문자로 처음 시작하는 단계입니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •A-Z까지 알파벳, 단모음부터 연속자음까지<br />&nbsp;&nbsp;알파벳의 발음을 배웁니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•입모양과 혀의 위치, 대소문자의 구별 등 소리 구조를 파악합니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/phonics/It%27s%20Me%20Phonic%201.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/phonics/1-2.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">It's Me Phonic 2</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/phonics/It%27s%20Me%20Phonic%202.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/phonics/1-3.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">It's Me Phonic 3</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/phonics/It%27s%20Me%20Phonic%203.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/phonics/1-4.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">It's Me Phonic 4</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/phonics/It%27s%20Me%20Phonic%204.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-show="tab === 'itsMe'">
                <!-- book1 ~ 4 -->
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-1.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 1</p>
                    </div>
                  </td>
                  <td rowspan="4" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•입문단계</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•유치부 - 초등 3학년</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="4" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•새로운 어휘와 간단한 표현을 배웁니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•단어를 활용한 기초 문장 만드는 법을 배웁니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•다양한 인사말 등 기초회화 실력을 향상시킵니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%201.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-2.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 2</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%202.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-3.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 3</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%203.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-4.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 4</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%204.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>

                <!-- book5 ~ 6 -->
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-5.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 5</p>
                    </div>
                  </td>
                  <td rowspan="2" class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•초급단계</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•초등 3학년 - 6학년</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="2" class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•일상 생활에서 유용하게 활용할 수 있는 기초 표현을 배웁니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•레벨에 맞는 단어와 표현을 재미있는 콘텐츠를 통해 학습합니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%205.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-6.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 6</p>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%206.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>

                <!-- book7 ~ 12 -->
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-7.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 7</p>
                    </div>
                  </td>
                  <td rowspan="6" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•중급단계</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•초등 3학년 - 6학년</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="6" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•중급단계의 대화를 통해 다양한 상황에 맞는 긴 문장을 만듭니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •다양한 상황에서 활용할 수 있는 보다 더 심화된 표현을<br />&nbsp;&nbsp;학습합니다.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%207.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-8.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 8</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%208.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-9.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 9</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%209.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-10.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 10</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2010.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-11.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 11</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2011.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-12.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 12</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2012.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>

                <!-- book13 ~ 16 -->
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-13.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 13</p>
                    </div>
                  </td>
                  <td rowspan="4" class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•고급단계</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•초등 6학년 - 중등부</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="4" class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">
                            •실생활에서 활용할 수 있는 문장을 주도적으로 만드는<br />&nbsp;&nbsp;고급단계입니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •다양한 상황별 심화 표현을 익히며 학습자의 의견을 유창하게<br />&nbsp;&nbsp;말할 수 있습니다.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2013.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-14.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 14</p>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2014.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-15.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 15</p>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2015.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/itsMe/2-16.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">It's Me Student</span><br />Book 16</p>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/itsMe/It%27s%20Me%20Student%2016.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-show="tab === 'junior'">
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-1.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">Essential English</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•중등부 고급 - 성인 입문</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="3" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•중등부에서 성인 입문/초급 학습자에 맞춘 레벨입니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•다양한 상황에 맞는 주제별 대화를 학습합니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•적절한 단어 활용법을 습득합니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/Essential%20English.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-2.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">Pre Get up to</span><br />Speed 1</p>
                    </div>
                  </td>
                  <td rowspan="2" class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•중등부 고급 - 성인 초급</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/Pre%20Get%20up%20to%201.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-3.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">Pre Get up to</span><br />Speed 2</p>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/Pre%20Get%20up%20to%202.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-4.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 1</p>
                    </div>
                  </td>
                  <td rowspan="8" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•고등부 고급 - 성인 중고급</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="8" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•고등부 고급부터 성인까지 학습이 가능합니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•보다 심화된 문장을 학습하고 표현을 익힙니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•체계적인 영어 학습을 통한 회화 실력 완성에 목적이 있습니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/New%20Get%20up%20to%201.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-5.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 2</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/New%20Get%20up%20to%202.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-6.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 3</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/New%20Get%20up%20to%203.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-7.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 4</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/junior/New%20Get%20up%20to%204.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-8.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 5</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=237&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-9.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 6</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=293&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-10.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 7</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=317&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/junior/3-11.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left"><span class="text-base">New Get up to</span><br />Speed 8</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=341&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-show="tab === 'debate'">
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-1.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">스킬심화 3</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•입문단계, 중등 1~2학년</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">
                            •중학교 1~2학년이 배워야 하는 주요 기본 내용을 배워<br />
                            &nbsp;&nbsp;학습의 기초를 다지며 기본 영어 학습 능력을 가집니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •다양하고 재밌는 활동을 통해 단답형이 아닌 문장 단위의 발화를<br />
                            &nbsp;&nbsp;연습해 봅니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •'표현' -> '활용 맥락 문장, 단락 단위로 제공' -> ‘실제 적용해<br />
                            &nbsp;&nbsp;내 의견 말하기’ 과정을 통해 Input제공과 Output 기회를<br />
                            &nbsp;&nbsp;반복적으로 가져봅니다.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/%EC%8A%A4%ED%82%AC%EC%8B%AC%ED%99%943.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-2.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">스킬심화 4</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•중급단계, 중등 2~3학년</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">
                            •중학교 2~3학년이 배우는 필수 학습 요소를 모두 습득해 자유롭게<br />
                            &nbsp;&nbsp;대화에서 활용할 수 있습니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •중학교 교과서에 나오는 필수 표현들과 그 외에 실제 상황에서<br />
                            &nbsp;&nbsp;쓰이는 표현들을 학습합니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•헷갈릴 수 있는 중학교 주요 문법을 꼼꼼하게 정리합니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/%EC%8A%A4%ED%82%AC%EC%8B%AC%ED%99%944.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-3.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">Big Ideas</p>
                    </div>
                  </td>
                  <td rowspan="3" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">
                            •고급단계,<br />
                            &nbsp;&nbsp;초등6학년 ~ 중등부
                          </p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="3" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">
                            •Big Ideas: 애니메이션 속 상황을 이해하고 원어민 표현을 직접<br />
                            &nbsp;&nbsp;활용한 storytelling으로 영어 세계관을 확장할 수 있다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •RTG: 당근영어주니어 자체 제작 article로 여러 관점의 의견을<br />
                            &nbsp;&nbsp;논리 정연하게 확장된 문장으로 표현할 수 있다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">
                            •Creative Writing: 단계별 글쓰기 스킬 학습 및 응용으로 체계적인<br />
                            &nbsp;&nbsp;작문을 할 수 있다.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=513&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-4.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left">RTG(Read, Think,Grow!</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=505&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-5.jpg"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">Creative Writing</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://mediabook.carrotenglish.kr/?idxLesson=537&mode=lesson"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-6.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">365 Think 1</p>
                    </div>
                  </td>
                  <td rowspan="2" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•주니어 중고급</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="2" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">
                            •관심사에 맞춘 48개의 다양한 핵심토픽을 통해<br />&nbsp;&nbsp;통합적 사고력을 기릅니다.
                          </p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•관련 주제에 대해 발언할 때, 핵심이 되는 단어들을 익힙니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•다양한 의견을 유창하게 말할 수 있습니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/365%20Think%201.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-7.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg">365 Think 2</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/365%20Think%202.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-8.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left">Active<br />Discussion 1</p>
                    </div>
                  </td>
                  <td rowspan="2" class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•성인 고급</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td rowspan="3" class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•보다 더 심화된 주제를 통해 학습자의 발화 기회를 제공합니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•토론 수업을 통한 논리력을 강화합니다.</p>
                        </li>
                        <li>
                          <p class="text-lg text-left">•실전에서 활용할 수 있는 스피치 훈련이 진행됩니다.</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/Active%20Discussion%201.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-9.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left">Active<br />Discussion 2</p>
                    </div>
                  </td>
                  <td class="bg-[#F6F6F6] border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/Active%20Discussion%202.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center gap-6 px-8 py-6">
                      <img
                        src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/book/debate/4-10.png"
                        alt="icon"
                        class="w-16"
                      />
                      <p class="text-lg text-left">Dynamic<br />Discussion</p>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="px-8 py-6">
                      <ul>
                        <li>
                          <p class="text-lg text-left">•성인 고급·심화</p>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="bg-white border border-solid border-[#ECECEC]">
                    <div class="flex items-center justify-center px-8 py-6">
                      <a
                        href="https://kr.object.ncloudstorage.com/lifeedu/user/junior-book/debate/Dynamic%20Discussion.pdf"
                        target="_blank"
                        class="px-8 py-2.5 flex items-center gap-1.5 bg-[#7664CA] rounded-full shadow-[1px_1px_8px_rgba(0,0,0,0.1)]"
                      >
                        <p class="text-lg font-bold text-white">View</p>
                        <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/preview.png" alt="icon" class="w-3.5" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <div v-if="showModal" class="fixed inset-0 overflow-y-auto size-full z-[2] bg-black/70">
      <div class="relative flex items-center justify-center min-h-full p-4">
        <div class="p-4 bg-white rounded-xl max-w-[1300px]">
          <div class="flex justify-end mb-4">
            <svg
              @click="closeModal"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              class="cursor-pointer size-6 stroke-black"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </div>
          <img src="@/lifeeduWeb/assets/images/courseIntroduce/junior-new/curriculum.jpg" alt="icon" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  setup() {
    const tab = ref("phonics");

    const changeTab = (selectedTab) => {
      tab.value = selectedTab;
    };

    const showModal = ref(false);
    const closeModal = () => {
      showModal.value = false;
    };

    return {
      tab,
      changeTab,
      showModal,
      closeModal,
    };
  },
};
</script>
<style lang="scss" scoped>
.scroll-bar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7664ca;
    border-radius: 10px;
  }
}
</style>
