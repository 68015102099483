import { lecDayList } from "@lifeeduAdmin/components/js/groupSugangMeta.js";

export default class BaseTimeInfoListObject {
  constructor() {
    this.idxLeSugangGroupTimeInfo = 0;
    this.idxLeSugangGroupType = 0;
    this.lessonTime = 0;
    this.numberOfClass = 0;
    this.lecDayList = structuredClone(lecDayList);
    this.sugangGroupLangList = new Array();
    this.staffIdx = 0;
    this.totalNumberOfClass = 0;
  }
}
