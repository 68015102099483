<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">부산광역시교육청 과제제출</p>
      <ul class="space-y-2">
        <li>* 학습자에게 설정된 교재 정보는 CTM의 마지막 렉쳐 정보를 기준으로 합니다.</li>
        <li>
          * 학습자의 수업일과 제출일 구분은 CTM의 마지막 렉쳐 정보가 월,목 으로 설정되어있으면 월,목으로 그것이 아닌경우 화,금으로
          구분됩니다.
        </li>
        <li>
          * 자기주도학습(퀴즈)는 각각의 교재와 대상별로 구성되어있으며, 다음의 교재가 아닌경우 자기주도학습을 할 수 없습니다.<br />
          - 초등(초등학생인 경우) : It's Me! - Student Book 6/8/10/12/14<br />
          - 중등(초등학생이 아닌 경우) : It's Me! - Student Book 12/14/16, 365 Think 1-1/1-2
        </li>
        <li>* 퀴즈에 사용되는 이미지의 크기는 652 x 436 (px) 로 사용해주세요</li>
      </ul>
    </div>
    <ul class="flex flex-wrap items-center gap-4 p-2">
      <li>
        <select v-model="app.params.searchType" class="border border-solid rounded border-slate-800">
          <option value="">선택</option>
          <option value="이름">이름</option>
          <option value="이메일">이메일</option>
          <option value="핸드폰">핸드폰</option>
        </select>
      </li>
      <li>
        <div class="flex items-center gap-4">
          <input v-model="app.params.keyword" type="text" class="border border-solid rounded border-slate-800" />
          <div class="flex items-center">
            <p class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer" @click="app.getQuestionUserList()">
              검색
            </p>
          </div>
        </div>
      </li>
    </ul>

    <div class="flex items-center justify-between p-2">
      <ul class="flex flex-wrap items-center gap-4">
        <li>
          <input
            type="number"
            v-model="app.params.round"
            placeholder="제출여부 횟수 입력"
            class="border border-solid rounded border-slate-800"
          />
        </li>

        <li>
          <select v-model="app.params.schoolLevel" class="border border-solid rounded border-slate-800">
            <option value="">학교레벨 선택</option>
            <option value="초등">초등</option>
            <option value="중등">중등</option>
          </select>
        </li>
        <li>
          <select v-model="app.params.bookName" class="border border-solid rounded border-slate-800">
            <option value="">교재명 선택</option>
            <option v-for="book in app.bookList" :key="book" :value="book">{{ book }}</option>
          </select>
        </li>
        <li>
          <select v-model="app.params.isBook" class="border border-solid rounded border-slate-800">
            <option value="">교재여부 선택</option>
            <option value="Y">교재있음</option>
            <option value="N">교재없음</option>
          </select>
        </li>
        <li>
          <div class="border border-solid rounded border-slate-800 p-2 flex gap-6 items-center">
            <span class="flex items-center gap-2">
              <label class="cursor-pointer" for="신규">신규</label>
              <input v-model="app.params.isNew" true-value="Y" false-value="N" type="checkbox" id="신규" class="mt-0.5" />
            </span>
            <span class="flex items-center gap-2">
              <label class="cursor-pointer" for="수업중"> 수업중</label>
              <input type="checkbox" v-model="app.params.isInClass" true-value="Y" false-value="N" id="수업중" class="mt-0.5" />
            </span>
            <span class="flex items-center gap-2">
              <label for="수업완료" class="cursor-pointer"> 수업완료 </label>
              <input
                type="checkbox"
                v-model="app.params.isEndClass"
                true-value="Y"
                false-value="N"
                id="수업완료"
                class="mt-0.5"
              />
            </span>
          </div>
        </li>
      </ul>

      <ul class="flex gap-4">
        <li
          @click="homework.setIsShowHomeworkModal"
          class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
        >
          과제/해설지 일괄 업로드
        </li>
        <li @click="app.downloadStudentInfo" class="p-2 text-white bg-green-600 border border-solid rounded cursor-pointer">
          학습자 정보 다운로드
        </li>
        <li @click="app.downloadStudyStatus" class="p-2 text-white bg-red-600 border border-solid rounded cursor-pointer">
          학습현황 다운로드
        </li>
      </ul>
    </div>

    <div class="p-2 space-y-5">
      <ul class="flex items-center gap-4">
        <li class="flex items-center">
          <select v-model="app.params.pageSize" class="border border-solid rounded border-slate-800">
            <option :value="20">리스트 갯수 선택</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="500">500</option>
            <option :value="0">전체</option>
          </select>
        </li>
      </ul>
      <ul class="flex justify-end gap-4">
        <li
          @click="sendingMessage.setIsShowMessageModal('student')"
          class="p-2 text-white bg-gray-600 border border-solid rounded cursor-pointer"
        >
          학생 SMS 발송
        </li>
        <li
          @click="sendingMessage.setIsShowMessageModal('parent')"
          class="p-2 text-white bg-gray-600 border border-solid rounded cursor-pointer"
        >
          학부모 SMS 발송
        </li>
        <li
          @click="sendingMessage.setIsShowMessageModal('all')"
          class="p-2 text-white bg-gray-600 border border-solid rounded cursor-pointer"
        >
          학생&학부모 SMS 발송
        </li>
      </ul>

      <div class="relative mb-5 max-h-[700px] overflow-auto">
        <table class="relative">
          <BasicLoading v-if="app.isLoading" />
          <thead class="sticky top-0">
            <tr>
              <td @click="sendingMessage.setAllCheck" class="p-1 font-bold text-center border border-gray-700 border-solid">
                <input :checked="sendingMessage.checkList.length === app.questionUserList.length" type="checkbox" name="" id="" />
              </td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">No</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">이름</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">생성일</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">상태</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">ID</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">학생<br />핸드폰번호</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">학부모<br />핸드폰번호</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">생년월일</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">학교레벨</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">학년</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">교재명</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">제출여부</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">SMS발송</td>
              <td class="p-1 font-bold text-center border border-gray-700 border-solid">발송내역</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="questionUser in app.questionUserList" :key="questionUser.rowNum">
              <td @click="sendingMessage.setCheckList(questionUser)" class="p-1 text-center border border-gray-700 border-solid">
                <input :checked="sendingMessage.checkList.find((item) => item.rowNum === questionUser.rowNum)" type="checkbox" />
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.rowNum }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.kname }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.createDate }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.lectureStatus }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.uid }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.mobile }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.mobileParent }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.birthday }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.schoolLevel }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p>{{ questionUser.schoolGrade }}</p>
              </td>
              <td class="p-1 text-center border border-gray-700 border-solid">
                <p class="truncate" :title="questionUser.bookName">{{ questionUser.bookName }}</p>
              </td>
              <td
                @click="
                  () => {
                    if (questionUser.bookName) {
                      submitModal.setIsShowSubmitStatus();
                      submitModal.getSubmitList(questionUser);
                    }
                  }
                "
                :class="{ 'cursor-pointer': questionUser.bookName }"
                class="p-1 text-center border border-gray-700 border-solid"
              >
                <p :class="{ underline: questionUser.bookName }">{{ questionUser.questionCompleteCnt }}/24</p>
              </td>
              <td class="p-1 border border-gray-700 border-solid">
                <p
                  @click="sendingMessage.setIsShowMessageModal('all', questionUser)"
                  class="inline-block p-2 text-white bg-gray-600 border border-solid rounded cursor-pointer"
                >
                  학생&학부모 SMS 발송
                </p>
              </td>
              <td
                @click="
                  () => {
                    sendedMessage.setIsShowSendedMessage();
                    sendedMessage.getMessageList(questionUser.idxCtmMember);
                  }
                "
                class="p-1 text-center border border-gray-700 border-solid"
              >
                <p class="inline-block underline cursor-pointer">View</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <BagicPaging
        :totalCnt="app.params.totalCount"
        :currentPage="app.params.pageNum"
        :pageSize="app.params.pageSize"
        @paging-click="app.movePage"
      ></BagicPaging>
    </div>

    <!-- 제출여부 클릭 시 팝업, 개발시 class="hidden" 제거 -->
    <div v-if="submitModal.isShowSubmitStatus">
      <div class="fixed inset-0 overflow-y-auto size-full z-[2]">
        <!-- 밑에 div도 클릭 시 팝업 닫기 -->
        <div class="fixed inset-0 bg-black/50"></div>

        <div class="relative flex items-center justify-center min-h-full p-4">
          <div class="p-4 bg-white rounded w-[500px]">
            <div class="flex justify-end mb-4">
              <img
                @click="submitModal.setIsShowSubmitStatus"
                src="@/lifeeduAdmin/assets/images/close.png"
                alt="icon"
                class="w-4 cursor-pointer"
              />
            </div>

            <table>
              <thead>
                <tr>
                  <td class="p-1 font-bold text-center border border-gray-700 border-solid">회차</td>
                  <td class="p-1 font-bold text-center border border-gray-700 border-solid">제출일자/요일</td>
                  <td class="p-1 font-bold text-center border border-gray-700 border-solid">제출여부</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="submit in submitModal.submitList" :key="submit.round">
                  <td class="p-1 text-center border border-gray-700 border-solid">
                    <p>{{ submit.round }}회</p>
                  </td>
                  <td class="p-1 text-center border border-gray-700 border-solid">
                    <p>{{ submit.createDate }}</p>
                  </td>
                  <td class="p-1 text-center border border-gray-700 border-solid">
                    <p
                      :class="{ 'underline cursor-pointer': submit.isComplete !== '-' }"
                      @click="submit.isComplete !== '-' && getCheckTriedQuestion(submit.round)"
                      class="inline-block"
                    >
                      {{ submit.isComplete }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- 발송내역 클릭 시 팝업, 개발시 class="hidden" 제거 -->
    <div v-if="sendedMessage.isShowSendedMessage">
      <div class="fixed inset-0 overflow-y-auto size-full z-[2]">
        <!-- 밑에 div도 클릭 시 팝업 닫기 -->
        <div class="fixed inset-0 bg-black/50"></div>

        <div class="relative flex items-center justify-center min-h-full p-4">
          <div class="p-4 bg-white rounded w-[1200px] max-h-[700px] overflow-y-auto">
            <div class="flex justify-end mb-4">
              <img
                @click="sendedMessage.setIsShowSendedMessage"
                src="@/lifeeduAdmin/assets/images/close.png"
                alt="icon"
                class="w-4 cursor-pointer"
              />
            </div>

            <select
              v-model="sendedMessage.params.searchDate"
              @change="sendedMessage.getMessageList()"
              class="mb-4 border border-solid rounded border-slate-800"
            >
              <option :value="String(Number(sendedMessageMonths[0]) - 1)">{{ Number(sendedMessageMonths[0]) - 1 }} 이전</option>
              <option v-for="sendedMessageMonth in sendedMessageMonths" :key="sendedMessageMonth" :value="sendedMessageMonth">
                {{ sendedMessageMonth }}
              </option>
            </select>

            <div class="flex gap-10">
              <div class="grow">
                <table class="w-full">
                  <thead>
                    <tr>
                      <td class="p-1 font-bold text-center border border-gray-700 border-solid">내용</td>
                      <td class="p-1 font-bold text-center border border-gray-700 border-solid">수신번호</td>
                      <td class="p-1 font-bold text-center border border-gray-700 border-solid">발신자</td>
                      <td class="p-1 font-bold text-center border border-gray-700 border-solid">발송일</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-if="sendedMessage.messageList.length === 0">
                      데이터가 없습니다.
                    </tr>
                    <tr
                      @click="sendedMessage.setSelectedMessage(message)"
                      v-for="message in sendedMessage.messageList"
                      :key="message.rowNum"
                      :class="{ 'bg-slate-200': sendedMessage.selectedMessage.rowNum === message.rowNum }"
                      class="cursor-pointer hover:bg-slate-200"
                    >
                      <td class="p-1 text-center border border-gray-700 border-solid max-w-96">
                        <p :title="message.contents" class="line-clamp-1">
                          {{ message.contents }}
                        </p>
                      </td>
                      <td class="p-1 text-center border border-gray-700 border-solid">{{ message.reciveNumber }}</td>
                      <td class="p-1 text-center border border-gray-700 border-solid">{{ message.staffName }}</td>
                      <td class="p-1 text-center border border-gray-700 border-solid">
                        {{ message.createDate.split(" ")[0] }}
                        <br />
                        {{ message.createDate.split(" ")[1] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="flex-none w-[500px]">
                <p class="p-1 text-white text-center font-bold bg-[#1e293b] border border-gray-700 border-solid">MEMO</p>
                <p class="p-1 border border-[#1e293b] border-solid whitespace-pre-line min-h-[200px]">
                  {{ sendedMessage.selectedMessage.contents ? sendedMessage.selectedMessage.contents : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SMS발송 클릭 시 팝업, 개발시 class="hidden" 제거 -->
    <div v-if="sendingMessage.isShowMessageModal">
      <div class="fixed inset-0 overflow-y-auto size-full z-[2]">
        <!-- 밑에 div도 클릭 시 팝업 닫기 -->
        <div class="fixed inset-0 bg-black/50"></div>

        <div class="relative flex items-center justify-center min-h-full p-4">
          <div class="p-4 bg-white rounded w-[500px]">
            <div class="flex justify-end mb-4">
              <img
                @click="sendingMessage.setIsShowMessageModal"
                src="@/lifeeduAdmin/assets/images/close.png"
                alt="icon"
                class="w-4 cursor-pointer"
              />
            </div>

            <p class="mb-4 text-xl font-bold">
              SMS 발송 ({{
                sendingMessage.postInfo.type === "all"
                  ? "학생&학부모"
                  : sendingMessage.postInfo.type === "student"
                    ? "학생"
                    : "학부모"
              }})
            </p>

            <table class="mb-4">
              <tbody>
                <tr>
                  <td class="p-1 text-white text-center font-bold border border-solid border-gray-700 bg-[#1e293b]">발신번호</td>
                  <td class="p-1 text-center">07074185381</td>
                </tr>
                <tr>
                  <td class="p-1 text-white text-center font-bold border border-solid border-gray-700 bg-[#1e293b]">수신자</td>
                  <td class="p-1 text-center">
                    {{ sendingSetList }}
                  </td>
                </tr>
                <tr>
                  <td class="p-1 text-white text-center font-bold border border-solid border-gray-700 bg-[#1e293b]">내용</td>
                  <td>
                    <textarea
                      v-model="sendingMessage.postInfo.message"
                      name=""
                      id=""
                      class="block w-full border border-gray-700 border-solid resize-none min-h-72"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>

            <ul class="flex justify-between">
              <li
                @click="sendingMessage.setIsShowMessageModal"
                class="p-2 text-white bg-red-600 border border-solid rounded cursor-pointer"
              >
                취소
              </li>
              <li
                @click="sendingMessage.postMessage"
                class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer"
              >
                전송
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 과제, 해설지 일괄 업로드 클릭 시 팝업, 개발시 class="hidden" 제거 -->
    <div v-if="homework.isShowHomeworkModal">
      <div class="fixed inset-0 overflow-y-auto size-full z-[2]">
        <!-- 밑에 div도 클릭 시 팝업 닫기 -->
        <div class="fixed inset-0 bg-black/50"></div>

        <div class="relative flex items-center justify-center min-h-full p-4">
          <div class="p-4 bg-white rounded w-[500px]">
            <div class="flex justify-end mb-4">
              <img
                @click="homework.setIsShowHomeworkModal"
                src="@/lifeeduAdmin/assets/images/close.png"
                alt="icon"
                class="w-4 cursor-pointer"
              />
            </div>

            <p class="mb-4 text-xl font-bold">과제 및 해설지 일괄 업로드</p>

            <div class="mb-4">
              <input @change="homework.setHomeworkFile" type="file" name="" id="" class="mb-2" />
              <p @click="homework.downloadFormat" class="underline cursor-pointer">등록 양식 다운로드</p>
            </div>

            <ul class="flex justify-end">
              <li @click="homework.uploadHomework" class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer">
                등록
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, watch, computed } from "vue";
import { useRouter } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";
import BagicPaging from "@lifeeduAdmin/components/BagicPaging.vue";
import BasicLoading from "@lifeeduAdmin/components/BasicLoading.vue";

// 금일 기준 6개월간 202406 형태로 처리
const sendedMessageMonths = computed(() => {
  let currentDate = new Date();

  let sixMonths = [];

  let currentMonth = currentDate.getMonth() + 1;
  let currentYear = currentDate.getFullYear();

  for (let i = 0; i < 6; i++) {
    if (currentMonth < 1) {
      currentMonth = 12;
      currentYear--;
    }

    let formattedDate = `${currentYear}${currentMonth.toString().padStart(2, "0")}`;
    sixMonths.push(formattedDate);

    if (currentYear === 2024 && currentMonth === 7) {
      break;
    }

    currentMonth--;
  }
  sixMonths.sort();
  return sixMonths;
});

const sendingSetList = computed(() => {
  const parentMobileList = sendingMessage.checkList.filter((item) => item.mobileParent);
  const studentMobileList = sendingMessage.checkList.filter((item) => item.mobile);

  if (sendingMessage.checkList.length === 0) {
    return "전체발송";
  } else if (sendingMessage.checkList.length === 1 && sendingMessage.postInfo.type === "all") {
    return studentMobileList[0].mobile + (parentMobileList[0] ? ` 외 1명` : ``);
  } else if (sendingMessage.checkList.length === 1 && sendingMessage.postInfo.type === "student") {
    return sendingMessage.checkList[0].mobile;
  } else if (sendingMessage.checkList.length === 1 && sendingMessage.postInfo.type === "parent") {
    return sendingMessage.checkList[0].mobileParent;
  } else if (sendingMessage.postInfo.type === "all") {
    return sendingMessage.checkList[0].mobile + ` 외 ${studentMobileList.length + parentMobileList.length - 1}명`;
  } else if (sendingMessage.postInfo.type === "student") {
    return sendingMessage.checkList[0].mobile + ` 외 ${studentMobileList.length - 1}명`;
  } else {
    return sendingMessage.checkList[0].mobileParent + ` 외 ${parentMobileList.length - 1}명`;
  }
});

// 제출여부 모달 관련 reactive
const submitModal = reactive({
  isShowSubmitStatus: false,
  idxCtmMember: 0,
  submitList: [],
  userInfo: {},
  setIsShowSubmitStatus: () => {
    submitModal.isShowSubmitStatus = !submitModal.isShowSubmitStatus;
    submitModal.submitList = [];
  },
  getSubmitList: async (userInfo) => {
    submitModal.idxCtmMember = userInfo.idxCtmMember;
    submitModal.userInfo = userInfo;
    const result = await axios.get(`/admin/question/complete/${userInfo.idxCtmMember}`);

    const { message, success, data } = result.data;

    if (!success) {
      alert(message);
      submitModal.setIsShowSubmitStatus();
      return;
    }

    submitModal.submitList = data;
  },
});

// 메시지 보내기 modal 관련 form
const sendingMessage = reactive({
  isShowMessageModal: false,
  postInfo: {
    message: `[#학습자명]님, 안녕하세요! 
부산교육청 NALDA화상영어 자기주도학습 과제를 미제출 하셨습니다.
미제출 과제를 확인 후 꼭! 제출해주세요.

※ 제출방법 : 부산교육청 NALDA홈페이지(https://www.naldapen.com) 접속 > 로그인 > 자기주도학습 > 문제풀기

※ 채점하기 버튼까지 꼭 눌러주셔야 제출 완료됩니다.

감사합니다.
`,
    type: "all",
  },
  checkList: [],
  postMessage: async () => {
    if (!sendingMessage.postInfo.message) {
      alert("메세지를 입력해주세요.");
      return;
    }
    const params = { ...sendingMessage.postInfo, idxCtmMemberList: sendingMessage.checkList.map((item) => item.idxCtmMember) };

    const result = await axios.post(`/admin/question/sms`, params);

    const { success, message } = result.data;

    alert(message);

    if (!success) return;

    sendingMessage.setIsShowMessageModal("all");
  },
  setIsShowMessageModal: (status, value) => {
    sendingMessage.postInfo.type = status;
    if (value) {
      sendingMessage.checkList = [value];
    }

    if (!sendingMessage.isShowMessageModal && sendingMessage.checkList.length === 0) {
      const isConfirm = confirm("전체를 대상으로 발송됩니다. 발송하시겠습니까?");
      if (!isConfirm) return;
    }

    sendingMessage.isShowMessageModal = !sendingMessage.isShowMessageModal;
  },
  setCheckList: (value) => {
    const findCheckList = sendingMessage.checkList.findIndex((item) => item.rowNum === value.rowNum);
    if (findCheckList === -1) {
      sendingMessage.checkList.push(value);
    } else {
      sendingMessage.checkList.splice(findCheckList, 1);
    }
  },
  setAllCheck: () => {
    if (sendingMessage.checkList.length === app.questionUserList.length) {
      sendingMessage.checkList = [];
    } else {
      sendingMessage.checkList = JSON.parse(JSON.stringify(app.questionUserList));
    }
  },
});

// 발송내역 reactive
const sendedMessage = reactive({
  isShowSendedMessage: false,
  idxCtmMember: -1,
  params: {
    searchDate: new Date().getFullYear() + (new Date().getMonth() + 1).toString().padStart(2, "0"),
  },
  messageList: [],
  selectedMessage: {},
  setSelectedMessage: (selectedMessage) => {
    sendedMessage.selectedMessage = selectedMessage;
  },
  setIsShowSendedMessage: () => {
    sendedMessage.selectedMessage = {
      contents: "",
      createDate: "",
      reciveNumber: "",
      rowNum: 0,
      sendNumber: "",
      staffName: "",
    };
    sendedMessage.isShowSendedMessage = !sendedMessage.isShowSendedMessage;
  },
  getMessageList: async (idxCtmMember) => {
    if (idxCtmMember) {
      sendedMessage.idxCtmMember = idxCtmMember;
    }

    const result = await axios.get(`/admin/question/sms/${sendedMessage.idxCtmMember}`, { params: sendedMessage.params });

    const { message, data, success } = result.data;

    if (!success) {
      alert(message);
    }
    sendedMessage.messageList = data;
  },
});

// 기본 table 및 각종 download 등 form
const app = reactive({
  isLoading: false,
  params: {
    searchType: "",
    keyword: "",
    subject: "",
    schoolLevel: "",
    bookName: "",
    isBook: "",
    isNew: "Y",
    isInClass: "Y",
    isEndClass: "Y",
    round: "",
    pageSize: 20,
    pageNum: 1,
    totalCount: -1,
  },
  questionUserList: [],
  bookList: [],
  movePage(value) {
    app.params.pageNum = value;
    sendingMessage.checkList = [];
    app.getQuestionUserList();
  },
  getBookList: async () => {
    const result = await axios.get("/admin/question/book");
    const { success, data, message } = result.data;
    if (!success) {
      alert(message);
      return;
    }
    app.bookList = data;
  },
  getQuestionUserList: async () => {
    app.isLoading = true;
    const result = await axios.get("/admin/question", {
      params: app.params,
    });

    const { success, data, message } = result.data;
    app.isLoading = false;

    if (!success) {
      alert(message);
      return;
    }

    app.params.totalCount = data.totalCnt;
    app.questionUserList = data.contentList;
  },
  downloadStudentInfo: async () => {
    const result = await axios.get("admin/question/member/excel", { params: app.params, responseType: "blob" });

    const blob = new Blob([result.data]);
    const blobUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "학습자정보.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  },
  downloadStudyStatus: async () => {
    const result = await axios.get("/admin/question/learn/excel", { responseType: "blob" });

    const blob = new Blob([result.data]);
    const blobUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "학습현황.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  },
});

// 기본 과제/해설지 업로드 modal
const homework = reactive({
  file: null,
  isShowHomeworkModal: false,
  setIsShowHomeworkModal: () => {
    homework.isShowHomeworkModal = !homework.isShowHomeworkModal;
  },
  setHomeworkFile: (event) => {
    homework.file = event.target.files[0];
  },
  downloadFormat: async () => {
    window.open(
      "https://kr.object.ncloudstorage.com/lifeedu/busanExcelFile/%ED%8F%89%EC%83%9D%EA%B5%90%EC%9C%A1%ED%94%8C%EB%9E%AB%ED%8F%BC_%EB%B6%80%EC%82%B0%EA%B4%91%EC%97%AD%EC%8B%9C%EA%B5%90%EC%9C%A1%EC%B2%AD_%ED%99%94%EC%83%81%EC%98%81%EC%96%B4_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D.xlsx"
    );
  },
  uploadHomework: async (event) => {
    const formData = new FormData();
    formData.append("file", homework.file);

    const result = await axios.post("/admin/question/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    const { success, message } = result.data;

    alert(message);
    if (!success) return;

    homework.setIsShowHomeworkModal();
  },
});

// 문제 열기
const getCheckTriedQuestion = async (round) => {
  const result = await axios.get(`/admin/question/check/${submitModal.idxCtmMember}/${round}`);
  const { success, message, data } = result.data;

  if (!success) {
    alert(message);
    return;
  }
  window.open(
    `${window.location.origin}/questionPage?round=${round}&check=${data}&idxCtmMember=${submitModal.idxCtmMember}&userName=${submitModal.userInfo.kname}`,
    "_blank",
    "width=800, height=896, resizable=no, menubar=no, toolbar=no, location=no, status=no"
  );
};
const init = () => {
  app.getQuestionUserList();
  app.getBookList();
};
onMounted(() => {
  init();
});
</script>
<style></style>
