<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">회원정보수정</p>
      <div>
        <div class="mb-14 pc:mb-36">
          <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">회원 정보 : 기본정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름<br class="pc:hidden" />(실명)</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-bold pc:text-base">
                  {{ signUser.userSignInfo.userInfoDataList.kname }}
                </p>
              </div>
            </li>

            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">성별<br class="pc:hidden" /></p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <ul class="mb-1 flex gap-2">
                  <li class="flex items-center gap-1">
                    <input
                      v-model="signUser.userSignInfo.gender"
                      type="radio"
                      name="gender"
                      id="male"
                      value="M"
                      class="border border-solid border-neutral-200"
                    />
                    <label for="male" class="block">남</label>
                  </li>
                  <li class="flex items-center gap-1">
                    <input
                      v-model="signUser.userSignInfo.gender"
                      type="radio"
                      name="gender"
                      id="female"
                      value="F"
                      class="border border-solid border-neutral-200"
                    />
                    <label for="female" class="block">여</label>
                  </li>
                </ul>
                <p class="text-xs pc:text-base text-neutral-300">* 실제로 수업 받을 학습자 성별을 선택해 주세요.</p>
              </div>
            </li>

            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">이메일 주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <p class="text-sm font-bold pc:text-base">
                  {{ signUser.userSignInfo.userInfoDataList.email }}
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">비밀번호</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="password"
                  placeholder="비밀번호"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.userInfoDataList.password"
                  @input="signUser.checkPasswordMatch"
                />
                <p class="text-xs pc:text-base text-neutral-300">
                  * 영문 대/소문자, 숫자, 특수문자를 포함한 8자 이상 입력하세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">비밀번호 확인</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex flex-col gap-2.5">
                  <input
                    type="password"
                    placeholder="비밀번호"
                    class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                    v-model="signUser.userSignInfo.userInfoDataList.passwordCheck"
                    @input="signUser.checkPasswordMatch"
                  />
                  <p
                    class="text-xs font-bold pc:text-base"
                    :class="signUser.passwordMatch && signUser.isPasswordCheckValid ? 'text-purple-600' : 'text-rose-600'"
                    v-if="signUser.showPasswordMessage"
                  >
                    {{
                      signUser.passwordMatch
                        ? signUser.isPasswordCheckValid
                          ? "* 두 비밀번호가 동일합니다."
                          : "* 비밀번호가 유효하지 않습니다."
                        : "* 두 비밀번호가 다릅니다."
                    }}
                  </p>
                </div>
                <p class="text-xs pc:text-base text-neutral-300">
                  * 영문 대/소문자, 숫자, 특수문자를 포함한 8자 이상 입력하세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">
                  영어이름<br class="pc:hidden" />(수업 이름)
                </p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="영어이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.userInfoDataList.ename"
                  @input="signUser.convertToLowerCase"
                />
                <p class="text-xs pc:text-base text-neutral-300">* 수업에서 사용되는 이름입니다.</p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="tel"
                  placeholder="010-1234-5678"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.userInfoDataList.mobile"
                  @input="signUser.formatMobile"
                />
                <ul>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내문자와 연락에 사용되는 전화번호입니다. 정확히 기재해 주세요.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내를 학부모님 번호로 받기 희망하시면 학부모님 번호를 기재해 주세요.
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">생년월일</p>
              </div>
              <div>
                <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                  <div class="flex gap-2.5 items-center">
                    <select
                      type="text"
                      class="w-32 p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.year"
                    >
                      <option v-for="year in signUser.userSignInfo.years" :key="year">{{ year }}</option>
                    </select>
                    <p>년</p>
                  </div>
                  <div class="flex gap-2.5 items-center">
                    <select
                      type="text"
                      class="w-32 p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.month"
                    >
                      <option v-for="month in signUser.userSignInfo.months" :key="month">{{ month }}</option>
                    </select>
                    <p>월</p>
                  </div>
                  <div class="flex gap-2.5 items-center">
                    <select
                      type="text"
                      class="w-32 p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.day"
                    >
                      <option v-for="day in signUser.userSignInfo.days" :key="day">{{ day }}</option>
                    </select>
                    <p>일</p>
                  </div>
                </div>
                <p class="pl-2.5 pb-3 pc:pl-9 pc:pb-4 text-xs pc:text-base text-neutral-300">
                  * 학습자의 생년월일을 입력해 주세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">학교 레벨</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <select
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.userInfoDataList.schoolLevel"
                >
                  <option value="">선택해주세요</option>
                  <option value="미취학">미취학</option>
                  <option value="초등학교">초등학교</option>
                  <option value="중학교">중학교</option>
                  <option value="고등학교">고등학교</option>
                  <option value="성인">성인</option>
                </select>
              </div>
            </li>
            <li
              v-if="
                signUser.userSignInfo.userInfoDataList.schoolLevel !== '미취학' &&
                signUser.userSignInfo.userInfoDataList.schoolLevel !== '성인'
              "
              class="flex items-center"
            >
              <div
                v-if="signUser.userSignInfo.userInfoDataList.schoolLevel !== ''"
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">학교/학년/반</p>
              </div>
              <div
                v-if="signUser.userSignInfo.userInfoDataList.schoolLevel === '초등학교'"
                class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center"
              >
                <ul class="flex flex-col pc:flex-row gap-2.5 pc:gap-9">
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="초등학교"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolName"
                      @input="signUser.validateKoreanInput"
                    />
                    <p>초등학교</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="학년"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolGrade"
                    />
                    <p>학년</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="반"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolClass"
                    />
                    <p>반</p>
                  </li>
                </ul>
              </div>
              <div
                v-if="signUser.userSignInfo.userInfoDataList.schoolLevel === '중학교'"
                class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center"
              >
                <ul class="flex flex-col pc:flex-row gap-2.5 pc:gap-9">
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="중학교"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolName"
                      @input="signUser.validateKoreanInput"
                    />
                    <p>중학교</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="학년"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolGrade"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>학년</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="반"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolClass"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>반</p>
                  </li>
                </ul>
              </div>
              <div
                v-if="signUser.userSignInfo.userInfoDataList.schoolLevel === '고등학교'"
                class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center"
              >
                <ul class="flex flex-col pc:flex-row gap-2.5 pc:gap-9">
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="고등학교"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolName"
                      @input="signUser.validateKoreanInput"
                    />
                    <p>고등학교</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="number"
                      placeholder="학년"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolGrade"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>학년</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="number"
                      placeholder="반"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.userInfoDataList.schoolClass"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>반</p>
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <ul class="flex flex-col gap-2">
                  <li class="flex gap-2">
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="signUser.userSignInfo.userInfoDataList.postcode"
                      readonly
                      @click="signUser.execDaumPostcode"
                    />
                    <p
                      class="flex-none p-4 text-xs border border-solid rounded cursor-pointer pc:text-base text-neutral-500 bg-neutral-50 border-neutral-200"
                      @click="signUser.execDaumPostcode"
                    >
                      우편번호 찾기
                    </p>
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="signUser.userSignInfo.userInfoDataList.address"
                      readonly
                      @click="signUser.execDaumPostcode"
                    />
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="signUser.userSignInfo.userInfoDataList.addressSub"
                    />
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">사회적배려<br class="pc:hidden" />대상자</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                  >사회적배려대상자
                  <input
                    type="checkbox"
                    v-model="signUser.userSignInfo.userInfoDataList.socialConsideration"
                    true-value="Y"
                    false-value="N"
                    value=""
                  />
                  <div class="checkmark"></div>
                </label>
                <p class="text-xs pc:text-base text-neutral-500">
                  * 사회적배려대상자인 경우 체크해주시고, 자세한 사항은 시·도 기준을 참고해주세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">
                  영어<br class="pc:hidden" />
                  레벨테스트<br class="pc:hidden" />
                  신청
                </p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex gap-2.5 pc:gap-7">
                  <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                    >신청함
                    <input type="radio" name="levelTest" v-model="signUser.userSignInfo.userInfoDataList.isLevelTest" value="Y" />
                    <div class="checkmark"></div>
                  </label>
                  <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                    >신청 안함
                    <input type="radio" name="levelTest" v-model="signUser.userSignInfo.userInfoDataList.isLevelTest" value="N" />
                    <div class="checkmark"></div>
                  </label>
                </div>
                <ul>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 레벨테스트는 최초 1회 제공되며, 실제 수강신청을 해주시는 학습자분께 제공됩니다.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 학습 시작일 전, 기재해주신 학습자 핸드폰번호로 문자 안내되니 안내를 기다려주세요.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 레벨테스트를 받지 않으셔도, 수강신청 시 레벨 을 기재해주시면 맞춰서 배정해드립니다.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 영어 외 다른 언어는 담당매니저의 개별 안내를 기다려주세요.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="signUser.userSignInfo.userInfoDataList.schoolLevel !== '성인'" class="mb-7 pc:mb-24">
          <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">
            학부모 정보
            <span class="text-orange-600">(※주니어 학습자인 경우에만 입력해주세요.)</span>
          </p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">학부모 이름</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="학부모 이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.userInfoDataList.parentName"
                  @input="signUser.validateKoreanInput"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">이메일 주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex flex-col gap-2.5">
                  <div class="flex flex-col gap-2.5 pc:flex-row">
                    <div class="flex flex-col gap-2.5 pc:flex-row">
                      <div class="flex items-center gap-2">
                        <input
                          type="text"
                          class="p-4 text-xs border border-solid rounded border-neutral-200 placeholder:text-neutral-200 pc:text-base pc:w-80"
                          v-model="signUser.userSignInfo.userInfoDataList.parentId"
                          @change="signUser.updateParentEmail"
                        />
                        <p>@</p>
                      </div>
                      <div class="flex items-center gap-2 pc:w-80">
                        <select
                          class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                          v-model="signUser.userSignInfo.userInfoDataList.parentSelectEmail"
                          @change="signUser.updateParentEmail"
                        >
                          <option v-for="domain in signUser.userSignInfo.validDomains" :key="domain" :value="domain">
                            {{ domain }}
                          </option>
                        </select>
                        <input
                          v-if="signUser.userSignInfo.userInfoDataList.parentSelectEmail === '직접입력'"
                          type="text"
                          placeholder="직접입력"
                          class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                          v-model="signUser.userSignInfo.userInfoDataList.parentCustomDomain"
                          @change="signUser.updateParentEmail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="tel"
                  placeholder="010-1234-5678"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.userInfoDataList.parentMobile"
                  @input="signUser.formatMobile"
                />
                <ul>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내문자와 연락에 사용되는 전화번호입니다. 정확히 기재해 주세요.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내를 학부모님 번호로 받기 희망하시면 학부모님 번호를 기재해 주세요.
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">환불 계좌</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <ul class="flex flex-col gap-2 pc:flex-row pc:items-center">
                  <li class="flex items-center gap-2 pc:w-80">
                    <select
                      class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                      v-model="signUser.userSignInfo.userInfoDataList.parentBank"
                    >
                      <option value="">계좌 선택</option>
                      <option value="KB국민은행">KB국민은행</option>
                      <option value="신한은행">신한은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="하나은행">하나은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="한국씨티은행">한국씨티은행</option>
                      <option value="카카오뱅크">카카오뱅크</option>
                      <option value="대구은행">대구은행</option>
                      <option value="부산은행">부산은행</option>
                      <option value="경남은행">경남은행</option>
                      <option value="광주은행">광주은행</option>
                      <option value="전북은행">전북은행</option>
                      <option value="제주은행">제주은행</option>
                      <option value="SBI저축은행">SBI저축은행</option>
                      <option value="애큐온저축은행">애큐온저축은행</option>
                      <option value="키움YES저축은행">키움YES저축은행</option>
                      <option value="푸른저축은행">푸른저축은행</option>
                      <option value="OK저축은행">OK저축은행</option>
                      <option value="웰컴저축은행">웰컴저축은행</option>
                      <option value="페퍼저축은행">페퍼저축은행</option>
                      <option value="NH저축은행">NH저축은행</option>
                      <option value="KB저축은행">KB저축은행</option>
                      <option value="NH농협은행">NH농협은행</option>
                    </select>

                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="예금주"
                      v-model="signUser.userSignInfo.userInfoDataList.parentBankAccountHolder"
                    />
                  </li>
                  <li class="grow pc:grow-0">
                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="계좌번호"
                      maxlength="100"
                      v-model="signUser.userSignInfo.userInfoDataList.parentBankNumber"
                      @input="signUser.formatParentBankNumber()"
                    />
                  </li>
                </ul>
                <ul>
                  <li class="text-xs pc:text-base text-neutral-500">* '-' 와 띄어쓰기 없이 숫자만 입력해 주세요.</li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 수강료와 교재비 환불이 진행될 계좌이니 정확히 기재해주세요.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <!-- 성인 환불 계좌 -->
        <div v-if="signUser.userSignInfo.userInfoDataList.schoolLevel == '성인'" class="mb-7 pc:mb-24">
          <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">
            환불계좌 정보
            <span class="text-orange-600">(※성인 학습자인 경우에만 입력해주세요.)</span>
          </p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">환불 계좌</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <ul class="flex flex-col gap-2 pc:flex-row pc:items-center">
                  <li class="flex items-center gap-2 pc:w-80">
                    <select
                      class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                      v-model="signUser.userSignInfo.userInfoDataList.parentBank"
                    >
                      <option value="">계좌 선택</option>
                      <option value="KB국민은행">KB국민은행</option>
                      <option value="신한은행">신한은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="하나은행">하나은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="한국씨티은행">한국씨티은행</option>
                      <option value="카카오뱅크">카카오뱅크</option>
                      <option value="대구은행">대구은행</option>
                      <option value="부산은행">부산은행</option>
                      <option value="경남은행">경남은행</option>
                      <option value="광주은행">광주은행</option>
                      <option value="전북은행">전북은행</option>
                      <option value="제주은행">제주은행</option>
                      <option value="SBI저축은행">SBI저축은행</option>
                      <option value="애큐온저축은행">애큐온저축은행</option>
                      <option value="키움YES저축은행">키움YES저축은행</option>
                      <option value="푸른저축은행">푸른저축은행</option>
                      <option value="OK저축은행">OK저축은행</option>
                      <option value="웰컴저축은행">웰컴저축은행</option>
                      <option value="페퍼저축은행">페퍼저축은행</option>
                      <option value="NH저축은행">NH저축은행</option>
                      <option value="KB저축은행">KB저축은행</option>
                      <option value="NH농협은행">NH농협은행</option>
                    </select>
                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="예금주"
                      v-model="signUser.userSignInfo.userInfoDataList.parentBankAccountHolder"
                    />
                  </li>
                  <li class="grow pc:grow-0">
                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="계좌번호"
                      maxlength="100"
                      v-model="signUser.userSignInfo.userInfoDataList.parentBankNumber"
                      @input="signUser.formatParentBankNumber()"
                    />
                  </li>
                </ul>
                <ul>
                  <li class="text-xs pc:text-base text-neutral-500">* '-' 와 띄어쓰기 없이 숫자만 입력해 주세요.</li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 수강료와 교재비 환불이 진행될 계좌이니 정확히 기재해주세요.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <p
          class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
          @click="signUser.getUserChangInfo"
        >
          수정하기
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const signUser = reactive({
      userSignInfo: {
        address: "", // 주소
        addressSub: "", // 상세 주소
        birthday: computed(() => `${signUser.userSignInfo.year}${signUser.userSignInfo.month}${signUser.userSignInfo.day}`),
        ename: "", // 영어 이름
        email: "", // 이메일
        isLevelTest: "", // 레벨 테스트 여부
        kname: "", // 한글 이름
        mobile: "", // 휴대폰 번호
        parentBank: "", // 부모님 환불계좌 은행
        parentBankAccountHolder: "", // 부모님 환불계좌 예금주
        parentBankNumber: "", // 부모님 환불계좌 계좌번호
        parentEmail: "", // 부모님 이메일
        parentSelectEmail: "",
        parentMobile: "", // 부모님 휴대폰 번호
        parentName: "", // 부모님 이름
        password: "", // 비밀번호
        passwordCheck: "", // 비밀번호 확인
        postcode: "", // 우편번호
        schoolLevel: "", // 학교 레벨
        socialConsideration: "N", // 사회적 배려 여부
        userInfoDataList: [],
        year: "",
        month: "",
        day: "",
        schoolName: "",
        schoolGrade: "",
        schoolClass: "",
        years: Array.from({ length: 105 }, (_, i) => 1920 + i),
        months: Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, "0")),
        days: Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, "0")),
        validDomains: ["naver.com", "daum.net", "hanmail.net", "kakao.com", "gmail.com", "직접입력"],
        gender: "",
      },

      passwordMatch: false,
      showPasswordMessage: false,

      emailCheck: null,

      isPasswordCheckValid: false,

      validateKoreanInput(e) {
        console.log(e.target.value);

        var regexp = /[a-zA-Z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;

        if (regexp.test(e.target.value)) {
          alert("한글만 입력해주세요.");
          e.target.value = e.target.value.replace(regexp, "");
        }
      },

      checkPasswordMatch() {
        signUser.passwordMatch =
          signUser.userSignInfo.userInfoDataList.password === signUser.userSignInfo.userInfoDataList.passwordCheck;

        const passwordRegex = /^(?=.*\d)(?=.*[~`!@#$%\^&*()-])(?=.*[a-zA-Z]).{8,15}$/;
        const isPasswordCheckValid = passwordRegex.test(signUser.userSignInfo.userInfoDataList.passwordCheck);

        signUser.showPasswordMessage =
          signUser.userSignInfo.userInfoDataList.password?.length > 0 ||
          signUser.userSignInfo.userInfoDataList.passwordCheck?.length > 0;
        this.isPasswordCheckValid = isPasswordCheckValid;
      },

      convertToLowerCase() {
        const input = signUser.userSignInfo.userInfoDataList.ename;

        if (/[ㄱ-ㅎㅏ-ㅣ가-힣\d]/.test(input)) {
          alert("영어 이름은 영어만 입력 가능합니다.");
          signUser.userSignInfo.userInfoDataList.ename = input.replace(/[^a-zA-Z]/g, "");
        } else {
          signUser.userSignInfo.userInfoDataList.ename = input.toLowerCase();
        }
      },

      formatMobile(e) {
        console.log(e.target.value);
        e.target.value = e.target.value
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
          .slice(0, 13);
      },

      formatParentBankNumber(e) {
        console.log(e.target.value);
        e.target.value = e.target.value.replace(/\D/g, "");
      },

      execDaumPostcode() {
        new window.daum.Postcode({
          oncomplete: (data) => {
            signUser.userSignInfo.userInfoDataList.postcode = data.zonecode;
            let buildingName = data.buildingName ? ` (${data.buildingName})` : "";
            signUser.userSignInfo.userInfoDataList.address = data.roadAddress + buildingName;
            // signUser.userSignInfo.addressSub = data.jibunAddress;
          },
        }).open();
      },

      updateParentEmail() {
        if (signUser.userSignInfo.userInfoDataList.parentSelectEmail === "직접입력") {
          signUser.userSignInfo.userInfoDataList.parentEmail =
            signUser.userSignInfo.userInfoDataList.parentId + "@" + signUser.userSignInfo.userInfoDataList.parentCustomDomain;
        } else {
          signUser.userSignInfo.userInfoDataList.parentEmail =
            signUser.userSignInfo.userInfoDataList.parentId + "@" + signUser.userSignInfo.userInfoDataList.parentSelectEmail;
        }
        signUser.userSignInfo.userInfoDataList.parentEmail = signUser.userSignInfo.userInfoDataList.parentEmail.toLowerCase();
        console.log("updateParentEmail ; ", signUser.userSignInfo.userInfoDataList.parentEmail);
      },
      devideParentEmail(data) {
        //부모 주소를 받고, parentId, parentSelectEmail, parentCustomDomain 값을 업데이트 한다.
        let parentEmailArr = data.split("@");
        if (parentEmailArr[0]) signUser.userSignInfo.userInfoDataList.parentId = parentEmailArr[0];
        else signUser.userSignInfo.userInfoDataList.parentId = "";

        if (parentEmailArr[1] && signUser.userSignInfo.validDomains.includes(parentEmailArr[1])) {
          //도메인 목록에 포함된 값이다.
          signUser.userSignInfo.userInfoDataList.parentSelectEmail = parentEmailArr[1];
        } else if (parentEmailArr[1]) {
          //@ 뒤의 값이 존재하지만 도메인 목록에는 포함되지 않은 경우이다.
          signUser.userSignInfo.userInfoDataList.parentSelectEmail = "직접입력";
          signUser.userSignInfo.userInfoDataList.parentCustomDomain = parentEmailArr[1];
        } else {
          //@ 뒤의 값이 존재하지 않는다.
          signUser.userSignInfo.userInfoDataList.parentSelectEmail = "직접입력";
          signUser.userSignInfo.userInfoDataList.parentCustomDomain = "";
        }
      },

      getMember() {
        axios.get(`/user/sugang/member`).then(function (res) {
          if (res.data.success === true) {
            signUser.userSignInfo.userInfoDataList = res.data.data;
            signUser.userSignInfo.year = res.data.data.birthday.substring(0, 4);
            signUser.userSignInfo.month = res.data.data.birthday.substring(4, 6);
            signUser.userSignInfo.day = res.data.data.birthday.substring(6, 8);
            signUser.userSignInfo.gender = res.data.data.gender;

            //조회해서 받은 학부모 이메일 값을 화면에 바인딩해준다.
            signUser.devideParentEmail(res.data.data.parentEmail);
          } else {
            alert(res.data.message);
          }
        });
      },

      getUserChangInfo() {
        if (!signUser.userSignInfo.userInfoDataList.password) {
          alert("비밀번호를 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.userInfoDataList.passwordCheck) {
          alert("비밀번호를 확인해주세요.");
          return;
        }

        if (!signUser.userSignInfo.userInfoDataList.ename) {
          alert("영어이름을 입력해주세요");
          return;
        }

        if (!signUser.userSignInfo.userInfoDataList.mobile) {
          alert("휴대전화 번호를 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.userInfoDataList.schoolLevel) {
          alert("학교 레벨을 선택해주세요");
          return;
        }

        if (
          !signUser.userSignInfo.userInfoDataList.postcode &&
          !signUser.userSignInfo.userInfoDataList.address &&
          !signUser.userSignInfo.userInfoDataList.addressSub
        ) {
          alert("주소를 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.userInfoDataList.isLevelTest) {
          alert("레벨 테스트 신청 여부를 선택해주세요.");
          return;
        }

        const mobileRegex = /^010-\d{3,4}-\d{4}$/;
        if (!mobileRegex.test(signUser.userSignInfo.userInfoDataList.mobile)) {
          alert("휴대폰 번호를 다시 확인해주세요.");
          return;
        }

        const params = {
          ...signUser.userSignInfo,
          ...signUser.userSignInfo.userInfoDataList,
          birthday: `${signUser.userSignInfo.year}${signUser.userSignInfo.month}${signUser.userSignInfo.day}`,
          gender: signUser.userSignInfo.gender,
          socialConsideration: signUser.userSignInfo.socialConsideration,
        };

        axios.put(`/user/sugang/member/register`, params).then(function (res) {
          if (res.data.success === true) {
            alert("회원정보 수정이 완료 되었습니다.");
            router.push({
              path: "/home",
              query: {
                idxLeSiteMain: idxLeSiteMain,
              },
            });
          } else {
            alert(res.data.message);
          }
        });
      },
    });

    onMounted(() => {
      signUser.getMember();
    });

    return {
      signUser,
    };
  },
};
</script>
<style scoped></style>
